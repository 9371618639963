import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../components/PrimaryButton';
import PrimaryInput from '../components/PrimaryInput';
import {Title, Subtitle, ActionText, ActionLink} from "../components/TextElements";
import Images from '../images/Images'
import PrimaryCheckbox from "../components/PrimaryCheckbox";
import ErrorText from '../components/ErrorText';
import {FullLogo, GrayLogo} from '../components/Logos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons'
import fire from '../config/fire';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import PropTypes from 'prop-types';
import ButtonLoader from '../components/ButtonLoader'
import FormValidator from '../components/FormValidator'

const RegisterContainer = styled.div`
  display: flex;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
`;
const LeftSideImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  width: 65%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RegisterFormContainer = styled.div`
  width: 350px;
  padding: 20px 0;
`;
const CustomLink = styled.a`
  font-size: 11px ;
  font-weight: 600 ;
  font-family: 'NotoSans-Regular',serif ;
  color: ${props => props.theme.secondaryColor};
  height: 20px ;
  line-height: 21px ;
  text-decoration: underline;
`;
const ErrorsContainer = styled.div`
  margin-bottom: 20px;
`;
const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;
class Register extends React.Component {
  constructor(props){
    super(props);
    let  user = fire.auth().currentUser;
    if (user) {
      this.props.history.push('/dashboard')
    }
    this.validator = new FormValidator([
      {
        field: 'firstName',
        method: 'isEmpty',
        validWhen: false,
        message: 'First Name is required.'
      },
      {
        field: 'lastName',
        method: 'isEmpty',
        validWhen: false,
        message: 'Last Name is required.'
      },
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Email is required.'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Please enter a valid email address.'
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password is required.'
      },
      {
        field: 'password',
        method: 'isLength',
        args: [{min:6, max: undefined}],
        validWhen: true,
        message: 'Password must have at least 6 characters.'
      },
      {
        field: 'agreeTerms',
        method: this.agreeTermsState,
        validWhen: true,
        message: 'You must agree with Terms of Service'
      }
    ])
    this.submitted = false
    this.state = {
      firstName: '',
      lastName: '',
      email:'',
      password:'',
      agreeTerms: false,
      serverError: '',
      sendingToServer: false,
      validation: this.validator.valid(),
    }
  }
  agreeTermsState = () => {
    return this.state.agreeTerms;
  }
  handleChange = (e) =>  {
    // if (!e.isTrusted) return;
    this.setState({
      [e.target.name]: e.target.value,
      serverError: ''
    });
  };
  componentDidUpdate(prevProps){
    let _this = this
    if(prevProps.profile !== this.props.profile && !this.props.profile.registered){
      this.setState({
        sendingToServer: true
      });
      // console.log('prev props:', prevProps.profile, this.props.profile);
      fire.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).catch(function(error) {
        // Handle Errors here.
        // var errorCode = error.code;
        let errorMessage = error.message;
        // console.log('error code: ', errorCode, "error message: ", errorMessage)
        _this.setState({
          serverError: errorMessage,
          sendingToServer: false
        });
        // ...
      });
    }
  }
  createAccount = () => {
    const validation = this.validator.validate(this.state)
    this.setState({ validation })
    this.submitted = true
    if (validation.isValid) {
      let profileInfo = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email:this.state.email,
        registered: 0
      };
      this.props.actions.setProfile(profileInfo)
    }
  };
  toggleAgreeStatus = () => {
    this.setState({
      agreeTerms: !this.state.agreeTerms
    },() => {
      if(this.state.agreeTerms){
        this.setState({ error: { ...this.state.error, agreeTerms: ''} });
      }
    })
  };
  render(){
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    return (
        <RegisterContainer>
          <LeftSideImage image={Images.background1}>
            <FullLogo />
          </LeftSideImage>
          <RightSide>
            <div>
              <Title>Create Account</Title>
              <Subtitle>Step 1 of 3</Subtitle>
              <RegisterFormContainer>
                <ErrorsContainer>
                  <ErrorText errorText={validation.firstName.message} />
                  <ErrorText errorText={validation.lastName.message} />
                  <ErrorText errorText={validation.email.message} />
                  <ErrorText errorText={validation.password.message} />
                  <ErrorText errorText={validation.agreeTerms.message} />
                  <ErrorText errorText={this.state.serverError} />
                </ErrorsContainer>
                <form>
                  <PrimaryInput value={this.state.firstName} name="firstName"
                                onChange={(e) =>this.handleChange(e)}
                                label="First name"
                                type="text"
                                validationtype="required"
                                fieldname="First name"
                                haserror={validation.firstName.isInvalid.toString()}
                  />
                  <PrimaryInput value={this.state.lastName} name="lastName"
                                onChange={(e) =>this.handleChange(e)}
                                label="Last name" type="text"
                                validationtype="required"
                                fieldname="Last name"
                                haserror={validation.lastName.isInvalid.toString()}
                  />
                  <PrimaryInput value={this.state.email} name="email"
                                onChange={(e) =>this.handleChange(e)}
                                label="Email" type="email"
                                validationtype="email"
                                fieldname="Email"
                                haserror={validation.email.isInvalid.toString()}
                                autoComplete='none'
                  />
                  <PrimaryInput value={this.state.password} name="password"
                                onChange={(e) =>this.handleChange(e)}
                                label="Create password" type="password"
                                validationtype="required|min6"
                                fieldname="Password"
                                haserror={validation.password.isInvalid.toString()}
                                autoComplete='new-password'
                  />
                </form>
                <div style={{display: 'flex', alignItems: 'center'}}>
                <PrimaryCheckbox value={this.state.agreeTerms} onClick={this.toggleAgreeStatus} label="I have read and agree with "/>&nbsp;<CustomLink target="_blank" href='https://drive.google.com/file/d/1BZqQgGzlEABE41AL97B2gWzDeAK814RX/view?usp=sharing' >Terms of Service</CustomLink>
                </div>
                {this.state.sendingToServer ?
                  <ButtonLoader style={{marginTop: "15px"}} loading={this.state.sendingToServer}/>
                  :
                  <PrimaryButton onClick={this.createAccount} style={{marginTop: "15px"}}>Create Account</PrimaryButton>
                }
                <CenteredContainer>
                  <ActionText style={{textAlign:'center', paddingTop: "15px"}}>Already have an account? </ActionText>&nbsp;
                  <ActionLink style={{paddingTop: "15px"}} to='/login'>Login&nbsp;<FontAwesomeIcon icon={faGreaterThan} style={{fontSize:'9px'}}/></ActionLink>
                </CenteredContainer>
                <CenteredContainer style={{paddingTop:"45px"}}>
                  <GrayLogo/>
                </CenteredContainer>
              </RegisterFormContainer>
            </div>
          </RightSide>
        </RegisterContainer>
    );
  }
}
Register.propTypes = {
  props:PropTypes.object,
  actions:PropTypes.object,
  profile:PropTypes.object,
  history:PropTypes.object
};
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions,dispatch) };
}
function mapStateToProps({account}){
  return { profile : account};
}
export default connect(mapStateToProps,mapDispatchToProps)(Register);


import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  // border: 1px solid ${props => props.theme.primaryColor};
  //border-radius: 25px;
`;
const Tab = styled.div`
  height: 32px;
  width: 50%;
  display: inline-block;
  background-color: ${props => props.isActive ? props.theme.primaryColor : ""};
  color: ${props => props.isActive ? "white" : props.theme.primaryColor};
  font-family: "NotoSans-Regular", serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
`;
const TabLeft = styled(Tab)`
  border: 1px solid #66BB6A;
  border-radius: 25px 0px 0px 25px;
  width: ${props => props.transferTabExists ? '33.33%' : '50%'}
`;
const TabRight = styled(Tab)`
  border: 1px solid #66BB6A;
  border-radius: ${props => props.isMiddle ? '0px' : '0px 25px 25px 0px'};
  width: ${props => props.transferTabExists ? '33.33%' : '50%'}

`;

const LEFT_INDEX = 0;
const RIGHT_INDEX = 1;
const TRANSFER_INDEX = 2;
class PrimaryTabbedSelector extends React.Component {

  constructor(props){
    super(props);
    var selected = this.props.defaultSelection ? this.props.defaultSelection : LEFT_INDEX;
    this.state = {
      selectedIndex: selected
    };
    this.triggerCallback(selected);
  }

  selectTab = (index) => {
    if (index != this.state.selectedIndex) {
      this.setState({
        selectedIndex: index
      });
      this.triggerCallback(index);
    }
  };

  triggerCallback = (selected) => {
    if (selected == LEFT_INDEX) {
      this.props.onLeftSelected()
    } else if (selected == RIGHT_INDEX) {
      this.props.onRightSelected()
    } else if (selected == TRANSFER_INDEX) {
      this.props.onTransferSelected()
    }
  };

  render() {
    return (
      <Container style={this.props.style}>
        <TabLeft onClick={() => this.selectTab(LEFT_INDEX)}
                 isActive={this.state.selectedIndex == LEFT_INDEX}
                 transferTabExists={this.props.hasTransferTab}
        >
          {this.props.leftText}
        </TabLeft>

        <TabRight onClick={() => this.selectTab(RIGHT_INDEX)}
                  isActive={this.state.selectedIndex == RIGHT_INDEX}
                  transferTabExists={this.props.hasTransferTab}
                  isMiddle={this.props.hasTransferTab}
        >
          {this.props.rightText}
        </TabRight>

        {this.props.hasTransferTab &&
        <TabRight onClick={() => this.selectTab(TRANSFER_INDEX)}
                  isActive={this.state.selectedIndex == TRANSFER_INDEX}
                  transferTabExists
        >
          {this.props.transferText}
        </TabRight>
        }
      </Container>
    );
  }
}
PrimaryTabbedSelector.propTypes = {
  style: PropTypes.object,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  defaultSelection: PropTypes.number,
  onLeftSelected: PropTypes.func,
  onRightSelected: PropTypes.func,
  hasTransferTab: PropTypes.bool,
  transferText: PropTypes.string,
  onTransferSelected: PropTypes.func
};
export {PrimaryTabbedSelector, LEFT_INDEX, RIGHT_INDEX};

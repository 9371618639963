import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

import '../css/navbar.css';
import {TopNavContainer, HomeLogoIconContainer, ProfileLogoIconContainer, LinkContainer, IconLinkContainer, MobileAdjustSpacer, DesktopAdjustSpacer, BottomMobileNav, MobileIconContainer, MobileIconText} from '../css/styled_components/NavBarComponents';
import {budgetIcon, bankingIcon, dashboardIcon} from '../images/icons/navIcons/navIcons';
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import logo from '../images/logo.png';
import ProfileIcon from '../components/ProfileIcon';
import ProfileMenu from '../components/ProfileMenu';

class NavBar extends React.Component {
    constructor(props) {
      super(props);
      this.isActiveClass = {
        dashboard: this.props.path === '/dashboard',
        netWorth: this.props.path === '/net-worth',
        banking: this.props.path === '/accounts',
        investments: this.props.path === '/investments',
        budget: this.props.path === '/budget'
      };
      this.state = {
        showProfileMenu: false
      };
      this.profileContainerRef = React.createRef();
    }
    
    componentDidMount() {
      document.addEventListener('click', this.checkProfileMenuClick);
    }
    
    componentWillUnmount() {
      document.removeEventListener('click', this.checkProfileMenuClick);
    }

    onProfileClick = () => {
      var current = this.state.showProfileMenu;
      this.setState({
        showProfileMenu: !current
      });
    };
    
    checkProfileMenuClick = (ev) => {
      if (this.profileContainerRef
          && !this.profileContainerRef.current.contains(ev.target)
          && this.state.showProfileMenu) {
            this.closeProfileMenu();
      }
    };
    
    closeProfileMenu = () => {
      this.setState({
        showProfileMenu: false
      });
    };

    render() {
      return (
        <React.Fragment>
          <TopNavContainer isWithoutBackground={this.props.isWithoutBackground}>
              <MobileAdjustSpacer/>
              <HomeLogoIconContainer>
                <Link to='/'><img src={logo} className='nb-icon' alt="Alto Logo"/></Link>
              </HomeLogoIconContainer>
                <DesktopAdjustSpacer/>
                  <LinkContainer><Link to='/'>Dashboard</Link></LinkContainer>
                  {/*<LinkContainer><Link to='/net-worth'>Net Worth</Link></LinkContainer>*/}
                  <LinkContainer><Link to='/accounts'>Banking</Link></LinkContainer>
                  {/*<LinkContainer><Link to='/'>Investments</Link></LinkContainer>*/}
                  <LinkContainer><Link to='/budget'>Budget</Link></LinkContainer>
                  {/*<LinkContainer><Link to='/'>Calculator</Link></LinkContainer>*/}
                <FlexSpacer/>
              <ProfileLogoIconContainer ref={this.profileContainerRef}>
                <FlexSpacer/>
                <ProfileIcon onClick={this.onProfileClick}/>
                <ProfileMenu onClose={this.closeProfileMenu} isVisible={this.state.showProfileMenu}/>
              </ProfileLogoIconContainer>
          </TopNavContainer>
          <BottomMobileNav>
              {/* FOR MOBILE ICONS */}
              <IconLinkContainer><FlexSpacer/><Link to='/'><MobileIconContainer isActiveClass={this.isActiveClass.dashboard}>{dashboardIcon}<MobileIconText isActiveClass={this.isActiveClass.dashboard}>Dashboard</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>
              {/*<IconLinkContainer><FlexSpacer/><Link to='/net-worth'><MobileIconContainer isActiveClass={this.isActiveClass.netWorth}>{netWorthIcon}<MobileIconText isActiveClass={this.isActiveClass.netWorth}>Net Worth</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>*/}
              <IconLinkContainer><FlexSpacer/><Link to='/accounts'><MobileIconContainer isActiveClass={this.isActiveClass.banking}>{bankingIcon}<MobileIconText isActiveClass={this.isActiveClass.banking}>Banking</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>
              {/*<IconLinkContainer><FlexSpacer/><Link to='/'><MobileIconContainer isActiveClass={this.isActiveClass.investments}>{investmentsIcon}<MobileIconText isActiveClass={this.isActiveClass.investments}>Investments</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>*/}
              <IconLinkContainer><FlexSpacer/><Link to='/budget'><MobileIconContainer isActiveClass={this.isActiveClass.budget}>{budgetIcon}<MobileIconText isActiveClass={this.isActiveClass.budget}>Budget</MobileIconText></MobileIconContainer></Link><FlexSpacer/></IconLinkContainer>
          </BottomMobileNav>
        </React.Fragment>
      );
    }
}

NavBar.propTypes = {
  isWithoutBackground: PropTypes.bool,
  path: PropTypes.string,
};

export default NavBar;

// Set up your root reducer here...
//  import { combineReducers } from 'redux';
//  export default combineReducers;
import { combineReducers } from 'redux';
import { account } from './AccountReducer';
import { netWorth } from './NetWorthReducer';
import { institutions } from './InstitutionsReducer'
import {AccountDetails} from "../constants/AccountDetails";

const rootReducer = combineReducers({
  account,
  netWorth,
  institutions
});

const logoutReducer = (state, action) => {
  if (action.type === AccountDetails.LOGOUT) {
    state = undefined;
  }
  return rootReducer(state, action);
}

export default logoutReducer;

import React from 'react';

import PropTypes from 'prop-types';
import { PopupBoxShortTop, PopupTitle, PopupErrorContainer } from '../../css/styled_components/PopupComponents.js';
import { PrimaryTabbedSelector, LEFT_INDEX, RIGHT_INDEX } from '../PrimaryTabbedSelector';
import PrimaryDropdown from '../PrimaryDropdown';
import PrimaryButton from '../PrimaryButton';
import OptionalDropdown from '../OptionalDropdown';
import TextButton from '../TextButton';
import PrimaryInput from '../PrimaryInput';
import PrimaryAmountInput from '../PrimaryAmountInput';
import PrimaryCheckbox from "../PrimaryCheckbox";
import ErrorText from '../ErrorText';
import FormValidator from '../FormValidator';
import ButtonLoader from  '../ButtonLoader';
import SimpleDrowdown from '../SimpleDropdown';
import moment from 'moment'


class BudgetBill extends React.Component {
  constructor(props){
    super(props)
    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.'
      },
      {
        field: 'amount',
        method: 'isEmpty',
        validWhen: false,
        message: 'Amount is required.'
      },
      {
        field: 'amount',
        method: this.amountIsNotZero,
        validWhen: true,
        message: 'Amount cannot be zero.'
      },
      {
        field: 'due',
        method: 'isEmpty',
        validWhen: false,
        message: 'Due date is required.'
      },
      {
        field: 'selectedAccountName',
        method: 'isEmpty',
        validWhen: false,
        message: 'An account is required.'
      },
    ])
    this.transferValidator = new FormValidator([
      {
        field: 'amount',
        method: 'isEmpty',
        validWhen: false,
        message: 'Amount is required.'
      },
      {
        field: 'amount',
        method: this.amountIsNotZero,
        validWhen: true,
        message: 'Amount cannot be zero.'
      },
      {
        field: 'due',
        method: 'isEmpty',
        validWhen: false,
        message: 'Due date is required.'
      },
      {
        field: 'fromAccount',
        method: 'isEmpty',
        validWhen: false,
        message: "An account is required"
      },
      {
        field: 'toAccount',
        method: 'isEmpty',
        validWhen: false,
        message: "An account is required"
      }
    ])
    this.submitted = false
    this.isEditing = false;
    this.transferFormSubmitted = false
    this.editTransfer = this.props.editTransfer || false;
    if (this.props.editBillId !== null) {
      let selectedBill = this.props.bills[this.props.editBillId];
      let selectedAccountName = selectedBill.institution + ' - (' + selectedBill.account + ')'
      let fromAccount = '';
      let toAccount = '';
      if(this.editTransfer) {
        fromAccount = selectedBill.fromAccount === 'Other' ? 'Other' : selectedBill.fromInstitution + ' - (' + selectedBill.fromAccount + ')';
        toAccount = selectedBill.toAccount === 'Other' ? 'Other' : selectedBill.toInstitution + ' - (' + selectedBill.toAccount + ')';
      }
      this.isEditing = true;
      this.state = {
        isIncome: this.props.bills[this.props.editBillId].amount < 0,
        name: this.props.bills[this.props.editBillId].name,
        amount:this.props.bills[this.props.editBillId].amount < 0 ? -this.props.bills[this.props.editBillId].amount : this.props.bills[this.props.editBillId].amount ,
        due: this.props.bills[this.props.editBillId].next_installment,
        recurrent: this.props.bills[this.props.editBillId].recurring,
        accounts: this.props.accounts,
        selectedAccountName: selectedAccountName,
        sendingToServer: false,
        serverError: '',
        validation: this.validator.valid(),
        isTransfer: false,
        fromAccount: fromAccount,
        toAccount: toAccount,
        transferValidation: this.transferValidator.valid(),
        recurrenceFreq: this.props.bills[this.props.editBillId].recurringFreq ? this.props.bills[this.props.editBillId].recurringFreq.value : 'Monthly',
      }
    } else {
      this.state = {
        isIncome: false,
        name:'',
        amount:'',
        due: '',
        recurrent: false,
        accounts: this.props.accounts,
        selectedAccountName: '',
        sendingToServer: false,
        serverError: '',
        validation: this.validator.valid(),
        isTransfer: false,
        fromAccount: '',
        toAccount: '',
        transferValidation: this.transferValidator.valid(),
        recurrenceFreq: 'Monthly'
      }
    }
  }
  amountIsNotZero = () => {
    return this.state.amount > 0;
  };
  selectExpense = (isEditing) => {
    this.setState({
      isIncome: false,
      isTransfer: false,
      recurrent: isEditing ? this.state.recurrent : false
    })
  };
  selectIncome = (isEditing) => {
    this.setState({
      isIncome: true,
      isTransfer: false,
      recurrent: isEditing ? this.state.recurrent : false
    })
  };
  selectTransfer = (isEditing) => {
    this.setState({
      isTransfer: true,
      recurrent: isEditing ? this.state.recurrent : false
    })
  };
  handleInputChange = (e) =>  {
    let _this = this;
    if(e.target.value === 'Bi-Weekly'){
      if(this.state.isTransfer) {
        _this._dueDateInputTransfer.dateInput.labels[0].classList.add('active');
      } else {
        _this._dueDateInput.dateInput.labels[0].classList.add('active');
      }
      if(moment().format('D') < 15) {
        _this.setState({
          due: moment({d: 15}).format('D MMMM, YYYY')
        });
      } else {
        _this.setState({
          due: moment().add(1,'months').startOf('month').format('D MMMM, YYYY')
        })
      }
    }
    this.setState({
      [e.target.name]: e.target.value,
      serverError: ''
    });
  };
  toggleRecurrentCheckbox = () => {
    let _this = this;
    if(this.state.recurrenceFreq === 'Bi-Weekly'){
      if(this.state.isTransfer) {
        _this._dueDateInputTransfer.dateInput.labels[0].classList.add('active');
      } else {
        _this._dueDateInput.dateInput.labels[0].classList.add('active');
      }
      if(moment().format('D') < 15) {
        _this.setState({
          due: moment({d: 15}).format('D MMMM, YYYY')
        });
      } else {
        _this.setState({
          due: moment().add(1,'months').startOf('month').format('D MMMM, YYYY')
        })
      }

    }
    this.setState({
      recurrent: !this.state.recurrent
    })
  };
  addWithMore = () => {
    let recurringFreq = {};
    switch (this.state.recurrenceFreq) {
      case 'Weekly':
        recurringFreq = {
          add: 1,
          period: 'weeks',
          value: 'Weekly'
        };
        break;
      case 'Every Two Weeks':
        recurringFreq = {
          add: 2,
          period: 'weeks',
          value: 'Every Two Weeks'
        };
        break;
      case 'Monthly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        };
        break;
      case 'Bi-Weekly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Bi-Weekly'
        };
        break;
      default :
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        }
    }
    if(this.validateForm() == true) {
      this.props.addRecurringPayment(this.state.name, this.state.amount, this.state.due, this.state.recurrent, this.state.selectedAccountName, this.state.isIncome, recurringFreq)
      this.submitted = false
      this.setState({
        name:'',
        amount:'',
        due: '',
        recurrent: false,
      })
      //TODO on success clear and add more
    }
  }
  addSingle = () => {
    let recurringFreq = {};
    switch (this.state.recurrenceFreq) {
      case 'Weekly':
        recurringFreq = {
          add: 1,
          period: 'weeks',
          value: 'Weekly'
        };
        break;
      case 'Every Two Weeks':
        recurringFreq = {
          add: 2,
          period: 'weeks',
          value: 'Every Two Weeks'
        };
        break;
      case 'Monthly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        };
        break;
      case 'Bi-Weekly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Bi-Weekly'
        };
        break;
      default :
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        }
    }
    if (this.validateForm() == true) {
      this.props.addRecurringPayment(this.state.name, this.state.amount, this.state.due, this.state.recurrent, this.state.selectedAccountName, this.state.isIncome, recurringFreq)
      this.props.closeModal()
      //TODO on success close
    }
  }
  update = () => {
    let recurringFreq = {};
    switch (this.state.recurrenceFreq) {
      case 'Weekly':
        recurringFreq = {
          add: 1,
          period: 'weeks',
          value: 'Weekly'
        };
        break;
      case 'Every Two Weeks':
        recurringFreq = {
          add: 2,
          period: 'weeks',
          value: 'Every Two Weeks'
        };
        break;
      case 'Monthly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        };
        break;
      default :
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        }
    }
    if (this.validateForm() == true) {
      this.props.updateRecurringPayments(this.props.editBillId, this.state.name, this.state.amount, this.state.recurrent, this.state.due, this.state.selectedAccountName, this.state.isIncome, recurringFreq);
      this.props.closeModal();
      //TODO on success close
    }
  }
  delete = () => {
    this.props.deleteRecurringPayments(this.props.editBillId)
    this.props.closeModal();
  }
  validateForm = () => {
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    return validation.isValid;
  }
  validateTransferForm = () => {
    const transferValidator = this.transferValidator.validate(this.state)
    this.setState({ transferValidator });
    this.transferFormSubmitted = true;
    return transferValidator.isValid;
  }
  showLoader = () => {
    this.setState({
      sendingToServer: true
    })
  }
  callAddBill = () => {
    this.showLoader()
    //TODO send to server
  }
  callUpdateBill = () => {
    this.showLoader()
    //TODO send to server
  }
  callDeleteBill = () => {
    this.showLoader()
    //TODO send to server
  }
  addTransfer = () => {
    let recurringFreq = {};
    switch (this.state.recurrenceFreq) {
      case 'Weekly':
        recurringFreq = {
          add: 1,
          period: 'weeks',
          value: 'Weekly'
        };
        break;
      case 'Every Two Weeks':
        recurringFreq = {
          add: 2,
          period: 'weeks',
          value: 'Every Two Weeks'
        };
        break;
      case 'Monthly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        };
        break;
      case 'Bi-Weekly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Bi-Weekly'
        };
        break;
      default :
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        }
    }
    if (this.validateTransferForm() == true) {
      this.props.addTransferPayment(this.state.amount, this.state.due, this.state.fromAccount, this.state.toAccount, this.state.recurrent, recurringFreq);
      this.props.closeModal()
    }
  };
  updateTransfer = () => {
    let recurringFreq = {};
    switch (this.state.recurrenceFreq) {
      case 'Weekly':
        recurringFreq = {
          add: 1,
          period: 'weeks',
          value: 'Weekly'
        };
        break;
      case 'Every Two Weeks':
        recurringFreq = {
          add: 2,
          period: 'weeks',
          value: 'Every Two Weeks'
        };
        break;
      case 'Monthly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        };
        break;
      case 'Bi-Weekly':
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Bi-Weekly'
        };
        break;
      default :
        recurringFreq = {
          add: 1,
          period: 'months',
          value: 'Monthly'
        }
    }
    if (this.validateTransferForm() == true) {
      this.props.updateTransferPayment(this.props.editBillId, this.state.amount, this.state.due, this.state.fromAccount, this.state.toAccount, this.state.recurrent, recurringFreq);
      this.props.closeModal()
    }
  };
  render(){
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    let transferFormValidation = this.transferFormSubmitted ? this.transferValidator.validate(this.state): this.state.transferValidation
    let isEditing = this.isEditing
    let {isTransfer} = this.state
    let editTransfer = this.editTransfer
    let recurrenceOptions = ['Weekly','Every Two Weeks', 'Bi-Weekly', 'Monthly'];
    return(
      <PopupBoxShortTop>
        <PopupTitle>{isEditing ? "Edit " : "New "} {isTransfer || editTransfer ? 'Transfer' : 'Bill' }</PopupTitle>
        <PopupErrorContainer>
          {isTransfer ?
            <div>
              <ErrorText errorText={transferFormValidation.amount.message}/>
              <ErrorText errorText={transferFormValidation.due.message}/>
              <ErrorText errorText={transferFormValidation.fromAccount.message}/>
              <ErrorText errorText={transferFormValidation.toAccount.message}/>
            </div>
            :
            <div>
              <ErrorText errorText={validation.name.message}/>
              <ErrorText errorText={validation.amount.message}/>
              <ErrorText errorText={validation.due.message}/>
              <ErrorText errorText={validation.selectedAccountName.message}/>
            </div>
          }
          <ErrorText errorText={this.state.serverError} />
        </PopupErrorContainer>
        {!editTransfer &&
        <PrimaryTabbedSelector leftText="Expense"
                               rightText="Income"
                               transferText="Transfer"
                               defaultSelection={this.state.isIncome ? RIGHT_INDEX : LEFT_INDEX}
                               onLeftSelected={() => this.selectExpense(isEditing)}
                               onRightSelected={() => this.selectIncome(isEditing)}
                               onTransferSelected={() => this.selectTransfer(isEditing)}
                               hasTransferTab={!isEditing}
                               style={{marginBottom: '10px'}}/>
        }
        {isTransfer || editTransfer ? <div>
            <PrimaryAmountInput value={this.state.amount}
                                name="amount"
                                onChange={(e) => this.handleInputChange(e)}
                                label="Amount"
                                fieldname="Amount"
                                haserror={transferFormValidation.amount.isInvalid.toString()}
                                style={{marginBottom: '16px'}}/>
            <PrimaryInput value={this.state.due}
                          name="due"
                          onChange={(e) => this.handleInputChange(e)}
                          label="Date"
                          type="date"
                          validationtype="date"
                          fieldname="Due date"
                          haserror={transferFormValidation.due.isInvalid.toString()}
                          style={{marginBottom: '16px'}}
                          disabled={(isEditing && this.state.recurrent) || (this.state.recurrent &&this.state.recurrenceFreq === 'Bi-Weekly')}
                          inputReference={(component) => {this._dueDateInputTransfer = component}}
            />
            <OptionalDropdown name="fromAccount"
                              fieldname="From"
                              haserror={transferFormValidation.fromAccount.isInvalid.toString()}
                              options={this.state.accounts}
                              label="From"
                              selectedOptionValue={this.state.fromAccount}
                              onChange={(e) => this.handleInputChange(e)}/>
            <OptionalDropdown name="toAccount"
                              fieldname="To"
                              haserror={transferFormValidation.toAccount.isInvalid.toString()}
                              options={this.state.accounts}
                              label="To"
                              selectedOptionValue={this.state.toAccount}
                              onChange={(e) => this.handleInputChange(e)}/>
            <PrimaryCheckbox value={this.state.recurrent}
                             onClick={this.toggleRecurrentCheckbox}
                             label="Recurrent Payment"
                             style={this.state.recurrent ? {margin: '10px 0'} :{margin: "30px 0"}}
                             disabled={isEditing}
            />
            {this.state.recurrent &&
            <SimpleDrowdown name="recurrenceFreq"
                            fieldname="recurrenceFreq"
                            haserror='false'
                            options={recurrenceOptions}
                            label="Recurrence"
                            selectedOptionValue={this.state.recurrenceFreq}
                            onChange={(e) => this.handleInputChange(e)}
                            style={{margin: "30px 0"}}
                            disabled={isEditing}
            />
            }
          </div>
          :
          <div>
            <PrimaryInput value={this.state.name} name="name"
                          onChange={(e) => this.handleInputChange(e)}
                          label="Title"
                          type="text"
                          validationtype="text"
                          fieldname="Name"
                          haserror={validation.name.isInvalid.toString()}
                          style={{marginBottom: '16px'}}/>
            <PrimaryAmountInput value={this.state.amount}
                                name="amount"
                                onChange={(e) => this.handleInputChange(e)}
                                label="Amount"
                                fieldname="Amount"
                                haserror={validation.amount.isInvalid.toString()}
                                style={{marginBottom: '16px'}}/>
            <PrimaryInput value={this.state.due}
                          name="due"
                          onChange={(e) => this.handleInputChange(e)}
                          label="Due date"
                          type="date"
                          validationtype="date"
                          fieldname="Due date"
                          haserror={validation.due.isInvalid.toString()}
                          style={{marginBottom: '16px'}}
                          disabled={(isEditing && this.state.recurrent) || (this.state.recurrent &&this.state.recurrenceFreq === 'Bi-Weekly')}
                          inputReference={(component) => {this._dueDateInput = component}}
            />
            <PrimaryDropdown name="selectedAccountName"
                             fieldname="Account"
                             haserror={validation.selectedAccountName.isInvalid.toString()}
                             options={this.state.accounts}
                             label="Account"
                             selectedOptionValue={this.state.selectedAccountName}
                             onChange={(e) => this.handleInputChange(e)}/>
            <PrimaryCheckbox value={this.state.recurrent}
                             onClick={this.toggleRecurrentCheckbox}
                             label="Recurrent Payment"
                             style={this.state.recurrent ? {margin: '10px 0'} :{margin: "30px 0"}}
                             disabled={isEditing}
            />
            {this.state.recurrent &&
            <SimpleDrowdown name="recurrenceFreq"
                            fieldname="recurrenceFreq"
                            haserror='false'
                            options={recurrenceOptions}
                            label="Recurrence"
                            selectedOptionValue={this.state.recurrenceFreq}
                            onChange={(e) => this.handleInputChange(e)}
                            style={{margin: "20px 0 30px 0"}}
                            disabled={isEditing}
            />
            }
          </div>
        }
        {this.state.isTransfer ?
          <div>
            <PrimaryButton onClick={this.addTransfer}
                           style={{marginTop: "10px"}}>
              Save
            </PrimaryButton>
          </div>
          :
          <div>
            {this.state.sendingToServer
              ? <ButtonLoader loading={this.state.sendingToServer}/>
              : (isEditing
                ? <div>
                  <PrimaryButton onClick={editTransfer? this.updateTransfer : this.update}>
                    Save
                  </PrimaryButton>
                  <TextButton onClick={this.delete} errorStyle>
                    Delete
                  </TextButton>
                </div>
                : <div>
                  <PrimaryButton onClick={this.addWithMore}>
                    Save and add more
                  </PrimaryButton>
                  <PrimaryButton onClick={this.addSingle}
                                 style={{marginTop: "10px"}}
                                 outlinedStyle>
                    Save
                  </PrimaryButton>
                </div>)
            }
          </div>
        }
      </PopupBoxShortTop>
    )
  }
}
BudgetBill.propTypes = {
  editBillId: PropTypes.number,
  closeModal: PropTypes.func,
  addRecurringPayment: PropTypes.func,
  bills: PropTypes.array,
  updateRecurringPayments: PropTypes.func,
  deleteRecurringPayments: PropTypes.func,
  accounts: PropTypes.array,
  addTransferPayment: PropTypes.func,
  editTransfer: PropTypes.bool,
  updateTransferPayment: PropTypes.func
};
export default BudgetBill;

/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';
import {StripeProvider} from 'react-stripe-elements';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import {Switch,Route,withRouter} from 'react-router-dom';
import theme from '../theme/theme';
import fire from '../config/fire';
import Register from '../containers/Register';
import TermOfService from '../containers/TermOfService';
import VerifyPhone from "../containers/VerifyPhone";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {accountActions} from "../actions/accountActions";
import {institutionsActions} from "../actions/institutionsActions";
import {getProfilePhotoUrl} from '../data/Storage';
import AuthRoute from './AuthRoute';
import LoadingScreen from "../components/LoadingScreen";
import Dashboard from '../containers/Dashboard';
import Login from '../containers/Login';
import ForgotPassword from '../containers/ForgotPassword';
import SelectPlan from '../containers/SelectPlan';
import CreditCardInput from '../containers/CreditCardInput';
import PaymentButton  from '../containers/PaymentButton';
import AddAccounts from '../containers/AddAccounts'
import Accounts from '../containers/Accounts';
import TopNavbar from '../containers/TopNavbar';
import AccountExpanded from '../containers/AccountExpanded';
import Transactions from '../containers/Transactions';
import Budget from '../containers/Budget';
import NetWorth from '../containers/NetWorth';
import NetWorthAssets from '../containers/NetWorthAssets';
import NetWorthAssetsDetails from '../containers/NetWorthAssetsDetails';
import NetWorthAddAsset from '../containers/NetWorthAddAsset';
import moment from "moment/moment";
import apiConfig from "../config/api";
import axios from "axios/index";
import AcceptAdvisorRequest from '../containers/AcceptAdvisorRequest';
import UpdateAccountLogin from '../containers/UpdateAccountLogin';

// import fire from '../config/fire';
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
  html, body, #app, #app>div {
    height: 100%
  }
  &:hover {
     a{
        cursor:pointer
     }
  }
`;
class App extends React.Component {
  constructor(props){
    super(props);
    this.ref = fire.firestore().collection('users');
    this.state= {
      loading: true,
      accounts: [],
      accountsById: {},
      updating: 0,
      updateData: [],
      lastMailDate: null,
      email: null
    };
    this.institutions = fire.firestore().collection('institutions')
    this.payments = fire.firestore().collection('payments')
    this.netWorthHistory = fire.firestore().collection('netWorthHistory')
  }
  componentDidMount(){
    fire.auth().onAuthStateChanged(this.onAuthStateChanged);
  }
  onAuthStateChanged = (user) => {
    let _this = this;
    if (!user) {
      this.setState({
        loading: false
      })
    } else {
      this.ref.doc(user.uid).get().then(function(doc) {
        if (doc.exists) {
          //user was created
          // console.log("Document data:", doc.data());
          getProfilePhotoUrl().then((url) => {
            return url;
          }, () => {
            return null;
          }).then((profilePhotoUrl) => {
            const data = doc.data();
            let profileInfo = {
              firstName: data.firstName,
              lastName: data.lastName,
              email:data.email,
              registered: 1,
              phoneNumber: data.phoneNumber,
              activeSubscription: data.activeSubscription || null,
              subscription_period_end: data.subscription_period_end || null
            };
            _this.setState({
              lastMailDate: data.lastMailDate || null,
              email: data.email
            });
            _this.props.actions.setProfilePhotoUrl(profilePhotoUrl);
            _this.props.actions.setProfile(profileInfo);
            _this.props.institutionsActions.setInitialData();
            //if user did not finish registration check if phone number and payment method is set
            if(!user.phoneNumber && !user.emailVerified){
              _this.props.history.push('/verify-phone');
              _this.setState({
                loading: false
              });
              return;
            }
            if(!data.activeSubscription){
              if(data.subscription_period_end && moment(data.subscription_period_end).diff(moment()) > 0){
                //subscription still exists
              } else {
                _this.props.history.push('/select-plan');
                _this.setState({
                  loading: false
                });
                return;
              }
            }
            if(_this.props.location.state && _this.props.location.state.from){
              _this.props.history.push(_this.props.location.state.from.pathname);
              _this.setState({
                loading: false
              });
              return
            }
            //redirect him to the previous route or dashboard
            let nextRoute = _this.props.location.pathname;
            if(nextRoute === '/login'){
              nextRoute = '/accounts'
            }
            _this.props.history.push(nextRoute);
            _this.setState({
              loading: false
            });
          });
        } else {
          //create user document
            let profile =  _this.props.profile;
            _this.ref.doc(user.uid).set({
              firstName: profile.firstName,
              lastName: profile.lastName,
              email: user.email,
              id: user.uid
            });
            _this.props.history.push('/verify-phone');
        }
      }).catch(function() {
        // console.log("Error getting document!!!:", error);
      });
    }
  };
  areDatesAmonthApart = (date1, date2) => {
    let timeDifference = moment.duration(moment(date1).diff(date2)).asDays();
    if (timeDifference > 27 && timeDifference < 32) {
      return true
    }
    return false
  };
  getDataFromPlaid = (document, docId) => {
    this.getItemData(document.access_token, docId)
  };
  getDataFromDatabase = (afterUpdate) => {
    this.sendNotificationEmails();
    let _this = this;
    let query = this.institutions.where("user_id", "==", fire.auth().currentUser.uid );
    query.get().then(function(querySnapshot) {
      let accounts = []
      let accountsById = {}
      querySnapshot.forEach(function(doc) {
        accountsById[doc.id] = doc.data();
        let accountsArray =  accountsById[doc.id].accounts ? accountsById[doc.id].accounts : [];
        let accountsObject = {};
        if(accountsById[doc.id].accounts === undefined && accountsById[doc.id].historicalUpdateReady){
          _this.getDataFromPlaid(accountsById[doc.id], doc.id);
        }
        accountsArray.forEach((account) => {
          accountsObject[account.account_id] = account
        });
        accountsById[doc.id].accounts = accountsObject;
        accounts.push(doc.data());
        // doc.data() is never undefined for query doc snapshots
      });
      if (afterUpdate) {
        _this.storeHistoryOfBalance(accounts)
      }
      _this.props.institutionsActions.setData({
        accounts: accounts,
        updating: 0
      });
      _this.setState({
        accounts: accounts,
        accountsById: accountsById,
        updating: 0
      },  () => _this.checkIfUpdateIsNeeded(querySnapshot))
      //
    });
  };
  checkIfUpdateIsDone = () =>{
    this.getDataFromDatabase(true)
  };
  checkIfUpdateIsNeeded = (querySnapshot) => {
    let needsUpdate = false;
    let data = [];
    let _this = this;
    querySnapshot.forEach(function(doc) {
      let startTime = moment(doc.data().lastUpdated, 'YYYY-MM-DD, HH:mm')
      let duration = moment.duration(moment().diff(startTime)).asHours()
      if(doc.data().lastUpdated) {
        data.push({
          access_token: doc.data().access_token,
          last_updated: moment(startTime).format('YYYY-MM-DD'),
          doc_id: doc.id,
          miscAdded: doc.data().miscAdded,
          name: doc.data().name,
          logo: doc.data().logo
        });
      }
      if(duration > 0.5) {
        needsUpdate = true
      }
    });
    if(needsUpdate) {
      this.setState({
        updating: 1,
        updateData: data
      }, () => _this.getUpdatedTransactions(_this.checkIfUpdateIsDone))
    } else {
      this.setState({
        updateData: data
      })
    }
  };
  getUpdatedTransactions = (callback) => {
    this.props.institutionsActions.setUpdateState({
      updating: 1
    });
    let data = this.state.updateData;
    if(data.length === 0) {
      this.getDataFromDatabase(false);
      return
    }
    let _this = this;
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: apiConfig.baseUrl + '/transactions/update',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          accounts: data
        }
      }).then((response) => {
        let data = response.data.data
        let dataLength = data.length;
        let newTransactionsObjectForCategory = [];
        for (let [masterIndex, institution] of data.entries()) {
          if(!institution.error){
            _this.setState({
              updating: 1
            })
            let oldInstitutionData =  Object.assign({}, _this.state.accountsById[institution.doc_id]);
            institution.data.accounts.forEach((account) => {
              //add new transaction in this array for detecting new if recurring payments
              let newTransactionsPerAccount = [];
              //check if account exists, if it does not we add it
              if(!oldInstitutionData.accounts[account.account_id]) {
                oldInstitutionData.accounts[account.account_id] = account
                //add new transactions for misc
                newTransactionsObjectForCategory = newTransactionsObjectForCategory.concat(account.transactions)
                newTransactionsPerAccount = newTransactionsPerAccount.concat(account.transactions)
              }
              //check if new transaction exists
              let newTransactions = false;
              if(account.transactions.length) {
                let lastAccountData = Object.assign({}, oldInstitutionData.accounts[account.account_id])
                let oldTransactions = Object.assign([], lastAccountData.transactions)
                account.transactions.forEach((newTransaction) => {
                  let transactionExists = false;
                  lastAccountData.transactions.forEach((transaction, index) => {
                    if(newTransaction.transaction_id === transaction.transaction_id){
                      //update transactions
                      oldInstitutionData.accounts[account.account_id].transactions[index] = newTransaction;
                      transactionExists = true;
                    }
                  })
                  if(!transactionExists){
                    newTransactions = true;
                    oldTransactions.unshift(newTransaction)
                    //add new transactions for misc
                    newTransactionsObjectForCategory.push(newTransaction)
                    newTransactionsPerAccount.push(newTransaction)
                  }
                })
                if(newTransactions){
                  oldInstitutionData.accounts[account.account_id].transactions = oldTransactions;
                }
                oldInstitutionData.accounts[account.account_id].balances = account.balances;
              }
              if(newTransactionsPerAccount.length) {
                _this.detectNewRecurringBills(account, newTransactionsPerAccount, institution.name, institution.logo)
              }
            });
            //format transactions and write to database
            let obj = oldInstitutionData.accounts
            let result = Object.keys(obj).map(function(key) {
              return obj[key];
            });

            oldInstitutionData.accounts = result;
            _this.institutions.doc(institution.doc_id).set({
              'accounts': result,
              'lastUpdated': moment().format('YYYY-MM-DD, HH:mm')
            }, {merge: true}).then(() => {
              if(++masterIndex === dataLength) {
                callback()
              }
            }).catch(() => {
              // console.log('writing error', error)
            })
            //
          } else {
            if(institution.error.error_code === 'ITEM_LOGIN_REQUIRED') {
              if (window.confirm("The login details for your " + institution.institution.name + ' account have changed, press ok to update them')) {
                _this.props.history.push('/update-account/'+institution.institution.doc_id);
              }
            } else {
              alert('Update error: ' + institution.error.error_message)
            }
            break;
          }
        }

        if(newTransactionsObjectForCategory.length) {
          _this.addNewTransactionsToPayments(newTransactionsObjectForCategory);
        }
      }).catch(() => {
        // console.log(error)
        // console.log('error', error.response)
      })
    })
  };
  addNewTransactionsToPayments = (newTransactions) => {
    let _this = this;
    let query = this.payments.where("user_id", "==", fire.auth().currentUser.uid );
    query.get().then(function(querySnapshot) {
      if(!querySnapshot.empty){
        querySnapshot.forEach(function(doc) {
          if (doc.data().misc) {
            if(doc.data().newTransactions) {
              let oldData = doc.data().newTransactions;
              let newData = oldData.concat(newTransactions)
              _this.payments.doc(doc.id).set({
                'newTransactions': newData
              }, {merge: true}).then(() => {
                // console.log(' update new transaction')
              }).catch(() => {
                // console.log('writing error1', error)
              })
            } else {
              _this.payments.doc(doc.id).set({
                'newTransactions': newTransactions
              }, {merge: true}).then(() => {
                // console.log(' added mis new transaction')
              }).catch(() => {
                // console.log('writing error2', error)
              })
            }
          }
        })
      }
    });
  };
  detectNewRecurringBills = (account, newTransactions, institutionName, institutionLogo) => {
    let recurringPayments = []
    let _this = this;
    let transactions = account.transactions;
    //if transactions have the same name, amount and are in different months we mark them as recurring
    for (let i = 0; i < newTransactions.length; i++) {
      for (let j = i + 1; j < transactions.length; j++) {
        if (transactions[i].name === transactions[j].name && transactions[i].amount === transactions[j].amount && _this.areDatesAmonthApart(transactions[i].date, transactions[j].date)) {
          recurringPayments.push({
            name: transactions[i].name,
            amount: transactions[i].amount,
            next_installment: moment(transactions[i].date).add(1, 'months').format('YYYY-MM-DD'),
            account: account.name,
            institution: institutionName,
            scheduled: true,
            recurring: true,
            institutionLogo: institutionLogo
          })
        }
      }
    }
    this.addRecurringPayments(recurringPayments, null)
  };
  addRecurringPayments = (recurringPayments, callback) => {
    let _this = this;
    let query = this.payments.where("user_id", "==", fire.auth().currentUser.uid);
    query.get().then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(function (doc) {
          let oldPayments = doc.data().recurring;
          //check if recurring payment is not already added
          oldPayments.forEach((oldPaymentObj) => {
            recurringPayments.slice(0).forEach((newPaymentObj, i) => {
              if(oldPaymentObj.name === newPaymentObj.name && oldPaymentObj.next_installment === newPaymentObj.next_installment && oldPaymentObj.account === newPaymentObj.account && oldPaymentObj.institution ===newPaymentObj.institution) {
                recurringPayments.splice(i, 1)
              }
            })
          })
          let newPayments = oldPayments.concat(recurringPayments)
          if(!recurringPayments.length) {
            return;
          }
          let sortedNewPayments = [].concat(newPayments)
            .sort((a, b) => moment(a.next_installment).isBefore(b.next_installment) ? -1 : 1)
          _this.payments.doc(doc.id).set({
            'recurring': sortedNewPayments
          }, {merge: true}).then(() => {
            // console.log('success')
            if(callback) {
              callback(false)
            }
          }).catch(() => {
            // console.log('writing error', error)
          })
        })
      } else {
        let sortedNewPayments = [].concat(recurringPayments)
          .sort((a, b) => moment(a.next_installment).isBefore(b.next_installment) ? -1 : 1)
        _this.payments.add({
          user_id: fire.auth().currentUser.uid,
          recurring: sortedNewPayments
        }).then(() => {
          _this.props.history.push('/accounts')
          if(callback) {
            callback(false)
          }
        });
      }
    });
  };
  calculateAllAccountsSum = (accounts) => {
    let sum = 0;
    accounts.forEach((account) => {
      if(account.type !== 'loan' && account.type !== 'credit') {
        sum += account.balances.current
      }
      if(account.type === 'credit') {
        sum -= account.balances.current
      }
    });

    return sum;
  }
  storeHistoryOfBalance = (accounts) => {
    let date = moment().format('YYYY-MM-DD');
    let accountsFormatted = [];
    accounts.forEach((account) => {
      let sum = this.calculateAllAccountsSum(account.accounts);
      accountsFormatted.push({
        name: account.name,
        balance: sum
      })
    });
    this.netWorthHistory.doc(fire.auth().currentUser.uid).set({
      [date]: accountsFormatted,
    }, {merge: true})
  };
  sendNotificationEmails = () => {
    let lastMailDate = moment(this.state.lastMailDate);
    let today = moment();
    if(!lastMailDate.isSame(today,'d')){
      let query = this.payments.where("user_id", "==", fire.auth().currentUser.uid);
      let _this = this;
      query.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let misc = doc.data().misc[moment().format('YYYY-MM')]
          let billsDueInOneDay = [];
          let billsDueInTwoDays = [];
          let budgetExceeded = [];
          let recurringTransactions = doc.data().recurring;
          let now = moment();
          for(let category in misc) {
            let spent = 0;
            misc[category].transactions.forEach((transaction) => {
              spent+= transaction.amount
            })
            if(misc[category].budget-spent < 0){
              budgetExceeded.push({
                category: category,
                spent: spent.toFixed(2),
                budget: misc[category].budget.toFixed(2)
              })
            }
          }
          recurringTransactions.forEach((transaction) => {
            if (transaction.amount > 0 &&
                moment.duration(now.diff(transaction.next_installment)).asDays() < 0 &&
                moment.duration(now.diff(transaction.next_installment)).asDays() >= -1 ) {
              billsDueInOneDay.push(transaction)
            }
            if (transaction.amount > 0 &&
              moment.duration(now.diff(transaction.next_installment)).asDays() < -1 &&
              moment.duration(now.diff(transaction.next_installment)).asDays() >= -2 ) {
              billsDueInTwoDays.push(transaction)
            }
          })
          if(billsDueInOneDay.length){
            _this.sendBillNotifications(billsDueInOneDay, 1)
          }
          if(billsDueInTwoDays.length){
            _this.sendBillNotifications(billsDueInTwoDays, 2)
          }
          if(budgetExceeded.length) {
            _this.sendBudgetNotifications(budgetExceeded);
          }
        });
      });
      this.setState({
        lastMailDate: moment().format('YYYY-MM-DD')
      })
      this.setLastMailDateOnServer(moment().format('YYYY-MM-DD'))
    }
  };
  setLastMailDateOnServer = (lastMailDate) => {
    this.ref.doc(fire.auth().currentUser.uid ).set({
      'lastMailDate': lastMailDate,
    }, {merge: true}).then(() => {
      // console.log('succes')
    }).catch(() => {
      // console.log('writing error', error)
    })
  };
  sendBudgetNotifications = (categories) => {
    let subject = 'Budget has been exceeded';
    let html = '<h3>The budget has been exceeded for the following categories</h3>';
    categories.forEach((category) => {
      html += '<h4>Category: '+category.category+'</h4>'
      html += '<p>Budget: $'+category.budget+'</p>'
      html += '<p>Spent: $'+category.spent+'</p>'
      html += '<p>Exceeded by: $'+-(category.budget-category.spent).toFixed(2)+'</p>'
    })
    let _this = this;
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/send-notification-email',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          email: _this.state.email,
          subject: subject,
          html: html
        }
      }).then(() => {
        // console.log('response', response)
      }).catch(() => {
        // console.log('error', error.response)
      })
    })
  }
  sendBillNotifications = (bills, days) => {
    let subject = 'Bills due in one day';
    if (days === 2) {
      subject = 'Bills due in two days';
    }
    let html = '<h3>The following bills are due in one day:</h3>';
    if(days === 2) {
      html = '<h3>The following bills are due in two days:</h3>'
    }
    bills.forEach((bill) => {
      html += '<h4>Name: '+bill.name+'</h4>'
      html += '<p>Amount: $'+bill.amount+'</p>'
      html += '<p>Due: '+moment(bill.next_installment).format('MMMM Do')+'</p>'
      html += '<p>Institution: '+bill.institution+'</p>'
      html += '<p>Account: '+bill.account+'</p>'
    })
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/send-notification-email',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          email: fire.auth().currentUser.email,
          subject: subject,
          html: html
        }
      }).then(() => {
        // console.log('response', response)
      }).catch(() => {
        // console.log('error', error.response)
      })
    })
  }
  getItemData = (access_token, docId) => {
    let _this = this
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/item',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          access_token: access_token
        }
      }).then((response) => {
        if(response.data.error) {
          window.alert('Something went wrong, error code: ' + response.data.error.error_code + ', message: ' + response.data.error.error_message)
          _this.setState({
            gettingData: 0
          });
          return;
        }
        // if(_this.state.addedInstitutions.indexOf(response.data.institution.name) > -1){
        //   _this.props.history.push('/accounts')
        //   return;
        // }
        // const {plaidTokensObject} = _this.state
        // let newPlaidTokens = plaidTokensObject
        // newPlaidTokens.push({
        //   name: response.data.institution.name,
        //   access_token: _this.state.access_token
        // })
        // _this.usersCollection.doc(fire.auth().currentUser.uid).update({
        //   plaidTokens: newPlaidTokens
        // });
        _this.getTransactions(response.data.institution.name, response.data.institution.logo, docId, access_token)
        // console.log(response, 'itemm')
      }).catch(() => {
        // console.log(error)
        // console.log('error', error.response)
      })
    })
  }
  getTransactions = (institutionName, institutionLogo, docId, access_token) => {
    let _this = this
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/transactions',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          access_token: access_token
        }
      }).then((response) => {
        if(response.data.error) {
          window.alert('Something went wrong, error code: ' + response.data.error.error_code + ', message: ' + response.data.error.error_message)
          _this.setState({
            gettingData: 0
          });
          return;
        }
        let accounts = response.data.transactions.accounts;
        let transactions = response.data.transactions.transactions;
        this.addNewTransactionsToPayments(transactions);
        accounts.forEach((account) => {
          let accountTransactions = [];
          transactions.forEach((transaction) => {
            if(transaction.account_id === account.account_id){
              accountTransactions.push(transaction)
            }
          });
          account.transactions = accountTransactions;
        });
        let data = {
          user_id: fire.auth().currentUser.uid,
          name: institutionName,
          accounts: accounts,
          lastUpdated: moment().format('YYYY-MM-DD, HH:mm'),
          recurringPaymentsDetected: false,
          miscAdded: false,
          logo: institutionLogo
        };
        _this.institutions.doc(docId).set(data, {merge: true}).then(() => {
          _this.detectRecurringBills({
            institution: data,
            doc_id: docId
          });
        })
      }).catch(() => {
        // console.log(error)
        // console.log('error', error.response)
      })
    })
  }
  detectRecurringBills = (data) => {
    let recurringPayments = []
    let accounts = data.institution.accounts;
    let _this = this;
    accounts.forEach((account) => {
      let transactions = account.transactions;
      //if transactions have the same name, amount and are in different months we mark them as recurring
      for (let i = 0; i < transactions.length; i++) {
        for (let j = i + 1; j < transactions.length; j++) {
          if (transactions[i].name === transactions[j].name && transactions[i].amount === transactions[j].amount && _this.areDatesAmonthApart(transactions[i].date, transactions[j].date)) {
            recurringPayments.push({
              name: transactions[i].name,
              amount: transactions[i].amount,
              next_installment: moment(transactions[i].date).add(1, 'months').format('YYYY-MM-DD'),
              account: account.name,
              institution: data.institution.name,
              scheduled: true,
              recurring: true,
              institutionLogo: data.institution.logo,
              institutionId: data.doc_id
            })
          }
        }
      }
    })
    this.institutions.doc(data.doc_id).set({
      'recurringPaymentsDetected': true,
    }, {merge: true}).then(() => {
      // console.log('success')
    }).catch(() => {
      // console.log('writing error', error)
    })
    this.addRecurringPayments(recurringPayments, this.getDataFromDatabase)
  };

  render() {
    // const activeStyle = { color: 'blue' };
    return (
        <ThemeProvider theme={theme}>
          <StripeProvider apiKey="pk_live_6j3JBYH48hMECGPtixe1jxj5">
          <div style={{height: "100%"}}>
            <GlobalStyle/>
            {this.state.loading ?
              <LoadingScreen loading={this.state.loading}/>
              :
              <div style={{height: '100%'}}>
                <TopNavbar path={this.props.location.pathname}/>
                <Switch>
                  <Route exact path="/" component ={Register} />
                  <AuthRoute path='/accept/advisor/request/:advisorId/:clientId' component={AcceptAdvisorRequest}/>
                  <Route path="/login" component={Login} />
                  <AuthRoute path="/verify-phone" component = {VerifyPhone} />
                  <AuthRoute path="/dashboard" component={Dashboard} getUpdatedTransactions={this.getUpdatedTransactions}/>
                  <AuthRoute path='/select-plan' component={SelectPlan} />
                  <Route path="/terms" component = {TermOfService} />
                  <Route path='/forgot-password' component={ForgotPassword} />
                  <Route path='/store-checkout' component={CreditCardInput} />
                  <Route path='/button-checkout' component={PaymentButton} />
                  <AuthRoute path='/add-accounts' component={AddAccounts} />
                  <AuthRoute path='/accounts' component={Accounts} getDataFromDatabase={this.getDataFromDatabase}/>
                  <AuthRoute path='/account-expanded' component={AccountExpanded}/>
                  <AuthRoute path='/transactions' component={Transactions} />
                  <AuthRoute path='/budget' component={Budget} getUpdatedTransactions={this.getUpdatedTransactions}/>
                  <AuthRoute path='/net-worth' component={NetWorth}/>
                  <AuthRoute path='/assets' component={NetWorthAssets}/>
                  <AuthRoute path='/assets-details/:category' component={NetWorthAssetsDetails}/>
                  <AuthRoute path='/add-assets' component={NetWorthAddAsset}/>
                  <AuthRoute path='/update-account/:docId' component={UpdateAccountLogin}/>
                </Switch>
              </div>
            }
          </div>
          </StripeProvider>
        </ThemeProvider>
    );
  }
}
function mapStateToProps({account}){
  return { profile : account};
}
function mapDispatchToProps(dispatch){
  return {
    actions: bindActionCreators(accountActions,dispatch),
    institutionsActions: bindActionCreators(institutionsActions, dispatch)
  };
}
App.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
  institutionsActions: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps,null,{pure:false})(withRouter(App));

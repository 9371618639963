import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import PlaidLink from 'react-plaid-link'
import {TitleOnGray, BoxTitle} from "../components/TextElements";
import fire from "../config/fire";
import axios from "axios/index";
import Images from '../images/Images'
import {GrayLogo} from "../components/Logos";
import LoadingScreen from '../components/LoadingScreen';
import {MainWidthContainer} from '../css/styled_components/SharedComponents';

const AccountTypeOption = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0 20px 80px 0 rgba(0,0,0,0.2);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
`;
const OptionImage = styled.div`
  background-image: url(${props => props.image});
  background-size: contain;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  width: 150px;
  height: 150px;
`;
const Options = styled.div`
  display: flex;
  padding-top: 40px;
  align-items: center;
  justify-content: center;
`;
const ResponsiveDiv = styled.div`
  width: 50%;
  box-sizing: border-box;
  @media (max-width: 700px) {
    width: 100%;
    padding: 10px 0 !important;
  }
`;
const MainContainer = styled.div`
  background-color: ${props => props.theme.grayBackground};
  height: 100%;
  width: 100%;
`;
class UpdateAccountLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gettingData: 0,
      access_token: '',
      institutionName: '',
      institutionId: '',
      loading: true,
      newPublicToken: ''
    };
    this.usersCollection = fire.firestore().collection('users')
    this.institutionsCollection = fire.firestore().collection('institutions')
  }
  handleOnSuccess = () => {
    this.props.history.push('/accounts')
  };
  handleOnExit() {
    // handle the case when your user exits Link
  }
  componentDidMount() {
    const { docId } = this.props.match.params
    this.getData(docId)
  }
  getData = (docId) => {
    let _this = this;
    this.institutionsCollection.doc(docId).get().then(function (doc) {
      _this.setState({
        institutionName: doc.data().name,
        institutionId: doc.id
      }, () => _this.getNewPublicToken(doc.data().access_token))
    });
  };
  getNewPublicToken = (oldAccessToken) => {
    let _this = this;
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/create_new_access_token_for_update',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          access_token: oldAccessToken
        }
      }).then((response) => {
        if(response.data.error) {
          window.alert('Something went wrong');
          return;
        }
        _this.setState({
          newPublicToken: response.data.new_public_token,
          loading: false
        })
      }).catch(() => {
        // console.log('error', error.response)
      })
    })
  }
  render(){
    return (
      <MainContainer>
        {this.state.gettingData ?
          <LoadingScreen/>
          :
          <MainWidthContainer>
            <TitleOnGray>Update Account</TitleOnGray>
            {this.state.loading ?
              <div style={{flex: 1}} />
              :
              <Options>
                <ResponsiveDiv style={{paddingRight: '10px'}}>
                  <PlaidLink
                    ref={(component) => {
                      this._plaidComponent = component
                    }}
                    style={{width: '100%', padding: 0, border: 'none'}}
                    clientName="Sherpa"
                    env="production"
                    product={["auth", "transactions"]}
                    publicKey="51379b83e0f2627df43dfccd06c6a2"
                    token={this.state.newPublicToken}
                    onEvent={this.handleOnEvent}
                    onExit={this.handleOnExit}
                    onSuccess={this.handleOnSuccess}>
                    <AccountTypeOption>
                      <OptionImage image={Images.grayLogo}/>
                      <BoxTitle>{this.state.institutionName.toUpperCase()}</BoxTitle>
                    </AccountTypeOption>
                  </PlaidLink>
                </ResponsiveDiv>
              </Options>
            }
            <GrayLogo style={{margin: '0 auto', marginTop: '50px'}}/>
          </MainWidthContainer>
        }
      </MainContainer>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions,dispatch) };
}
function mapStateToProps({account}){
  return { profile : account};
}
UpdateAccountLogin.propTypes = {
  profile:PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(UpdateAccountLogin));

import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';

import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import {FlexSpacer} from '../css/styled_components/SharedComponents';

import {DownArrowIcon, PencilIcon, RecurringIcon, HintIcon, RefreshIcon, TimeClockIcon} from '../components/shared_components/Icons';
import BottomLogoSection from '../components/shared_components/ButtomLogoSection';

import Modal from '../components/Modal';
import BudgetMisc from '../components/modal/BudgetMisc';
import BudgetBill from '../components/modal/BudgetBill';

import fire from '../config/fire';
import moment from 'moment'
import {formatAccountTotalFull, formatAccountTotal, formatAccountTotalWithZero} from '../utils/accountsFormatting';
import icons from '../images/icons/icons';
//#region global styles
const BudgetPageOuterContainer = styled.div`
  display: flex;
  .budget-page-inner-container {
      display: ${props => props.display};
      background: ${props => props.color};	
      padding:  ${props => props.padding};
      width: 100%;
      border-radius: ${props => props.radius};	
      box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
    @media (min-width: 839px) {
      width: 1025px;
    }
  }
  .breakdown {
    max-height: 999999px;
    padding: 25px;
    -moz-transition: max-height .5s ease-out;
    -ms-transition: max-height .5s ease-out;
    -o-transition: max-height .5s ease-out;
    -webkit-transition: max-height .5s ease-out;
    transition: max-height .5s ease-out;
  }
  .collapsed {
    max-height: 0;
    overflow: hidden;
    padding: 0px;
    -moz-transition: max-height .5s ease-out, padding 0s .5s;
    -ms-transition: max-height .5s ease-out, padding 0s .5s;
    -o-transition: max-height .5s ease-out, padding 0s .5s;
    -webkit-transition: max-height .5s ease-out, padding 0s .5s;
    transition: max-height .5s ease-out, padding 0s .5s;
  }`;

const ArrowContainer = styled.svg`
    height: 7px;
    width: 30px;
    margin: 0;
    transform: ${props => props.flipVertically ? 'scaleY(-1)' : 'scaleY(1)'};
`;

const OvalDateButton = styled.button`
    display: inline-block;
    border: none;
    padding: 8px 16px;
    font-size: .8rem;
    border-radius: 19px;	
    margin: 0 0 0 10px;
    background-color: ${props => props.color};

    ${({ showProjectedDropdown }) => showProjectedDropdown && `
    border-radius: 19px 19px 0 0;
    `}
    ${({ fullWidth }) => fullWidth && `
        width: 100%;
    `}
`;

const OvalDateLabel = styled.div`
    display: inline-block;
    border: none;
    padding: 8px 16px;
    font-size: .8rem;
    border-radius: 19px;
    margin: 0 0 0 10px;
    background-color: ${props => props.color};
`;

const HeightSpacer = styled.div`
  height: 30px;
`;

//#endregion

//#region TOP Card Section
const TopTitlesContainer = styled.div`
    display: flex;
    margin-top: -180px;
  .account-card-top-spacer-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 10%;
    @media (min-width: 839px) {
      padding: 0;
      width: 1025px;
    }
  }
`;

const AllAccountsTitle = styled.h1`
  color: #FFFFFF;	
  font-family: "NotoSans-Regular", serif;
  font-size: 2.5rem;	
  font-weight: 800;	
  line-height: 49px;
  .a-all-accounts-svg-arrow {
    height: 9px;
    width: 30px;
    margin: 0 0 4px 4px;
}`;

const BalanceCardGrid = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const BalanceCard = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 200px;
  background-color: ${props => props.color};

  /*For mobile*/
  min-width: 55%;
  margin-top: 25px;
  :first-child {
    margin-top: 0;
  }

  /*For web*/
  @media (min-width: 839px) {
    min-width: 35%;
    margin-top: 0;
    :nth-child(odd) {
      max-width: calc(50% - 12.5px);
      margin-right: 12.5px;
    }
    :nth-child(even) {
      margin-left: 12.5px;
    }
    :nth-child(n+3) {
      margin-top: 25px;
    }
    :last-child {
      margin-right: 0;
    }
  }
`;

const BalancedTextRefreshContainer = styled.div`
  display: flex;
  align-items: center;
  svg {margin-left: 10px;}
  //@media (max-width: 839px) {
  //  display: none;
  //}
`;

const BalanceCardAccount = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
`;

const BalanceCardText = styled.div`
  font-family: "NotoSans-Regular", serif;
  font-size: 1rem;	
  margin: 0;
  text-align: center;
  color: ${props => props.color};
  display: flex;
  align-items: center;
`;

const BalanceCardAmount = styled.h2`
  font-family: "NotoSans-Regular", serif;
  text-align: center;
  font-weight: 600;	
  word-break: break-word;
  letter-spacing: 1.5px;	
  ${({ isGreen }) => isGreen && `
      color: #66BB6A;
      font-size: 2.5rem;	
      margin: 12px 0;      
      `}
  ${({ isDark }) => isDark && `
      color: #454B54;
      font-size: 3rem;	
      margin: 14px 0;
      `}
`;

const BreakdownButton = styled.button`
  padding: 15px 0;
  border: none;
  background-color: #595F67;
  margin: 0;
  text-align: center; 
  color: white;
  font-size: .9rem;
  font-weight: 500;
  width: 100%;
  &:focus {
    background-color: #595F67;
  }`;

const TimeClockSvg = styled.span`
  position: absolute;
  left: 0;
  @media (min-width: 839px) {
    left: calc(50% - 12.5px);
  }
`;

const OvalDateDropdownContainer = styled.div`
  display: inline-block;
  position: relative; 
`;

const ProjectedBalanceDropdownList = styled.ul`
  display: none;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 10px;
  background-color: #2ab7a9;
  border-radius: 0 0 4px 4px;
  
  .dropdown-list-item {
    width: 100%;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    button {
      width: 100%;
      border: none;
      background-color: transparent; 
    }

    &:hover {
      background-color: rgba(255,255,255,0.4);
      color: white;
    }

  }

  ${({ showProjectedDropdown }) => showProjectedDropdown && `
  display: block;
  `}
`;

//#endregion

//#region Table Sections
const BudgetPageTable = styled.div`
  display: block;
`;
const TableHeadersFlexRow = styled.div`
      display: flex;
      padding: 0 25px;
      align-items: center;
`;
const TableHeadersMiscFlexRow = styled.div`
      display: flex;
      padding: 0 25px;
    th:nth-child(n+2) {
      color: rgba(69,75,84,0.6);	
      font-family: "NotoSans-Regular";
      font-size: 1rem;	
      }
    >*:nth-child(1) {
      width: 35%;
    }
    >*:nth-child(2) {
      width: 30%;
    }
    >*:nth-child(3) {
      width: 25%;
    }
    >*:nth-child(4) {
      width: 10%;
    }
`;

const TableTitleHeader = styled.div`
    font-family: "NotoSans-Regular", serif;
    font-size: 1.1rem;	
    color: #454B54;
    font-weight: 600;
    display: flex;
    align-items: center;
`;
const TableScheduledHeader = styled.div`
    color: rgba(69,75,84,0.6);
    width: 23%;

    .table-header-scheduled-container {
      display: flex;
      align-items: center;
    }
    .table-header-scheduled-text {
      font-family: "NotoSans-Regular", serif;
      font-size: .9rem;	
      font-weight: 500;	
    }
    .table-header-scheduled-button {
      display: flex;
      border: none;
      background-color: transparent;
    }
`;

const IncomeTableItem = styled.div`
    display: flex;
    min-width: 100px;
    flex-wrap: wrap;
    border-bottom: 1px solid #F2F2F2;
    align-items: center;
    padding: 15px 25px;

    >*:nth-child(1) {
      width: 10%;
    }
    >*:nth-child(2) {
      width: 90%;
    }
    >*:nth-child(3) {
      width: 0%;
    }
    >*:nth-child(4) {
      width: 40%;
    }
    >*:nth-child(5) {
      width: 5%;
    }
    >*:nth-child(6) {
      width: 25%;
    }
    >*:nth-child(7) {
      width: 10%;
    }
    >*:nth-child(8) {
      width: 20%;
    }

    @media (min-width: 839px) {
      >*:nth-child(1) {
        width: 5%;
        padding-right: 25px;
      }
      >*:nth-child(2) {
        width: 25%;
      }
      >*:nth-child(3) {
        width: 5%;
      }
      >*:nth-child(4) {
        width: 25%;
      }
      >*:nth-child(5) {
        width: 5%;
      }
      >*:nth-child(6) {
        width: 15%;
      }
      >*:nth-child(7) {
        width: 10%;
      }
      >*:nth-child(8) {
        width: 10%;
      }
    }
`;

const IncomeTableItemCell = styled.div`

`;

const MiscTableItem = styled.div`
    display: flex;
    min-width: 100px;
    flex-wrap: wrap;
    border-bottom: 1px solid #F2F2F2;
    align-items: center;
    padding: 15px 25px;

    >*:nth-child(1) {
      width: 10%;
    }
    >*:nth-child(2) {
      width: 90%;
      margin-bottom: 10px;
    }
    >*:nth-child(3) {
      width: 0%;
    }
    >*:nth-child(4) {
      width: 20%;
    }
    >*:nth-child(5) {
      width: 15%;
    }
    >*:nth-child(6) {
      width: 25%;
    }
    >*:nth-child(7) {
      width: 10%;
    }
    >*:nth-child(8) {
      width: 30%;
    }

    @media (min-width: 839px) {
      >*:nth-child(1) {
        width: 5%;
      }
      >*:nth-child(2) {
        width: 25%;
        margin-bottom: 0;
      }
      >*:nth-child(3) {
        width: 5%;
      }
      >*:nth-child(4) {
        width: 15%;
      }
      >*:nth-child(5) {
        width: 15%;
      }
      >*:nth-child(6) {
        width: 15%;
      }
      >*:nth-child(7) {
        width: 10%;
      }
      >*:nth-child(8) {
        width: 10%;
      }
    }
`;

const MiscTableItemCell = styled.div``;

const PencilIconSvg = styled.svg`
    width: 24px;
    height: 24px;
`;
const EditItemIconButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0;
  align-items: center;

  &:focus {
    background-color: transparent;
  }
  img {
    width: 24px;
  }
`;

const TableTitleContainer = styled.div`
  display : flex;
  align-items: center;
  svg {margin-left: 10px; min-width: 32px;}
`;

const TableItemTitle = styled.p`
    word-break: break-all;
    font-family: "NotoSans-Regular", serif;
    font-size: 1.1rem;	
    margin: 0;
`;

const TableItemBankAccountSection = styled.div`
  border: none;
  width: 100%;
  text-align: left;
  background-color: transparent;
  display: flex;
  align-items: center;
  img {
    margin-right: 12px;
    width: 24px;
    height: 24px;
  }
`;

const TableItemDueDate = styled.p`
    color: #454B54;	
    font-family: "NotoSans-Regular", serif;
    font-size: 1rem;		
    line-height: 22px;	
    margin: 0;
`;

const TableItemCheckbox = styled.div`
    width: 25px;
    height: 25px;
    background-color: rgba(69,75,84,0.2);
  	border-radius: 2px;	
    background-color: #66BB6A;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
  .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }
  /* When the checkbox is checked, add a green background */
  input:checked ~ .checkmark {
    background-color: #66BB6A;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }
  /* Style the checkmark/indicator */
  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 8px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const TableItemAmount = styled.p`
    color: #454B54;	
    font-family: "Noto Serif";
    font-size: 1rem;	
    font-weight: bold;	
    word-wrap: break-word;
    line-height: 22px;	
    margin: 0;
    text-align: left;
`;

const TableAmountInputContainer = styled.div`
  	border-radius: 2px;	
    background-color: #F9F9FA;
    padding: 0 14px;
    display: flex;
    align-items: center;
    .table-label {
      font-size: 1.2rem;
      font-weight: 900; 
      padding: 0 12px 0 0;
      color: rgba(69,75,84,0.3);	
      font-family: "NotoSans-Regular";
    }
    .table-input {
      color: #454B54;	
      font-family: "NotoSans-Regular";	
      font-size: 1.4rem;
      text-align: right;
      margin: 0 !important;
      border: none !important;
    }
`;

const AddItemSection = styled.div`
    button {
    background-color: transparent;
    border: none;
    padding: 0 30px;
    &:focus{
      background-color: transparent;
    }
  
    p {
      margin: 0;
      color: #7c7c7c;
      font-size: 3rem;
      font-weight: 200;
    }
  }
`;

const MiscMonthDropdownParagraph = styled.p`
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      display: inline;
      padding: 0 10px;
`;

const MiscTableHeaderTexts = styled.p`
  font-family: "NotoSans-Regular", serif;
  font-weight: 500;
  margin: 0;
  display: none;
  @media (min-width: 839px) {
    display: flex;
    align-items: center;
  }
`;

//#endregion
const HintText = styled.div`
  position: absolute;
  width: 250px;
  left: -230px;
  bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  display: none;
  background-color:#222529;
  color:#ffffff;
  font-size: 14px !important;
`;
const HintButton = styled.button`
  &:hover + ${HintText} {
    display: block;
  }
`;
const HintButtonCustom = styled.button`
    background: white;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    &:hover + ${HintText} {
      display: block;
    }
`;
const RefreshIconContainer = styled.div`
  &:hover{
    cursor:pointer;
  }
`;
class Budget extends React.Component {
  toggleTimeoutId = 0;
  constructor(props) {
    super(props);
    this.state = {
      accountsById: {},
      showBillModal: false,
      showProjectedDropdown: false,
      editBillId: null,
      showMiscModal: false,
      showBreakdown: false,
      editMiscId: null,
      accounts: [],
      recurring: [],
      balance: 0,
      balanceByAccount: {},
      projectedBalance: 0,
      projectedBalanceByAccount: {},
      lastUpdated: '',
      recurringChanged: false,
      misc: {},
      allMisc: {},
      dropDownData: [
        {
          label: 'In One Week',
          end: false,
          increment: 'week',
          value: 1
        },
        {
          label: 'In Two Weeks',
          end: false,
          increment: 'week',
          value: 2
        },
        {
          label: 'End of Month',
          end: true,
          increment: 'month',
          value: 1
        }
      ],
      dropDownSelected: {
        label: 'In One Week',
        end: false,
        increment: 'week',
        value: 1
      },
      accountsFormatted: [],
      alreadyAddedCategories: [],
      miscEditKey: '',
      editTransfer: false
    };
    this.institutions = fire.firestore().collection('institutions')
    this.payments = fire.firestore().collection('payments')
    // window.addEventListener('unload', function(event) {
    //   //call function to save you state in API or save in localStore
    //   console.log('unload event:', event)
    // });
  }
  getData = () => {
    let _this = this
    let query = this.payments.where("user_id", "==", fire.auth().currentUser.uid);
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
          _this.checkIfPaymentsDone(doc)
      });
    });
  };
  checkIfPaymentsDone = (doc) => {
    let recurringPayments = doc.data().recurring;
    let now = moment();
    // let _this = this;
    let recurringModified = false;
    recurringPayments.slice(0).forEach((recurringPayment) => {
      if(now.diff(moment(recurringPayment.next_installment)) > 0) {
        if(moment(recurringPayment.next_installment).isBefore(moment().subtract(1,'days'))){
          if(recurringPayment.recurring) {
            if(recurringPayment.recurringFreq) {
              let toAdd = recurringPayment.recurringFreq.add;
              let periodToAdd = recurringPayment.recurringFreq.period;
              if(recurringPayment.recurringFreq.value === 'Weekly'){
                  toAdd = 5;
              }
              if(recurringPayment.recurringFreq.value === 'Every Two Weeks') {
                 toAdd = 6;
              }
              recurringPayment.next_installment = moment(recurringPayment.next_installment).add(toAdd, periodToAdd).format('YYYY-MM-DD')
            } else {
              recurringPayment.next_installment = moment(recurringPayment.next_installment).add(1, 'months').format('YYYY-MM-DD')
            }
          } else {
            recurringPayments.splice(recurringPayments.indexOf(recurringPayment), 1)
          }
        }
      }
    });
    let sortedNewPayments = [].concat(recurringPayments)
      .sort((a, b) => moment(a.next_installment).isBefore(b.next_installment) ? -1 : 1)

    //Check if misc is set for this month
    let allMisc = {};
    let misc = {};
    let desiredCategories = [];
    let miscNeedsUpdate = false;
    if (doc.data().misc) {
      allMisc = doc.data().misc;
      if(doc.data().misc[moment().format('YYYY-MM')]){
        misc = doc.data().misc[moment().format('YYYY-MM')]
        for(let key in misc) {
          desiredCategories.push({
            name: key,
            categories: misc[key].actualCategories
          })
        }
      } else {
        let newMisc = JSON.parse(JSON.stringify(allMisc[moment().subtract(1, 'month').format('YYYY-MM')]));

        for (let key in newMisc) {
          newMisc[key].transactions = [];
          desiredCategories.push({
            name: key,
            categories: newMisc[key].actualCategories
          })
        }
        allMisc[moment().format('YYYY-MM')] = newMisc
        misc = newMisc
      }
    }
    //check if new transactions have desired category
    let newTransactionsForMisc = doc.data().newTransactions || [];
    if(newTransactionsForMisc.length) {
      miscNeedsUpdate = true;
      desiredCategories.forEach((category) => {
        allMisc = this.detectActivitiesForTransaction(allMisc, category.categories, category.name, newTransactionsForMisc)
      });
      misc = allMisc[moment().format('YYYY-MM')]
    }
    if(miscNeedsUpdate) {
      this.updateMiscOnServer(allMisc)
    }

    this.setState({
      recurring: sortedNewPayments,
      paymentsDocId: doc.id,
      misc: misc,
      allMisc: allMisc,
      recurringChanged: recurringModified,
      alreadyAddedCategories: desiredCategories
    }, () => this.calculateProjectedBalance())
  };
  detectActivitiesForTransaction = (allMisc, desiredCategories, genericName, transactions) => {
    let miscPayments = JSON.parse(JSON.stringify(allMisc))
    // let miscPayments = {}
    transactions.forEach((transaction) => {
      let categories = transaction.category;
      loop1: for (let i = 0; i < categories.length; i ++) {
        let category = categories[i];
        //loop2
        for (let j = 0 ; j < desiredCategories.length; j++) {
          let desiredCategory = desiredCategories[j];
          let date = moment(transaction.date).format('YYYY-MM');
          if(category === desiredCategory) {
            if (miscPayments[date] ) {
              if(miscPayments[date][genericName]) {
                let payments = miscPayments[date][genericName].transactions;
                payments.push({
                  account_id: transaction.account_id,
                  amount: transaction.amount,
                  category: transaction.category,
                  name: transaction.name,
                  date: transaction.date,
                  category_id: transaction.category_id,
                  pending: transaction.pending
                })
              }
            }
            //if transaction match to one category we add it and stop verifying the others
            break loop1;
          }
        }
      }
    })
    return miscPayments;
  };
  updateMiscOnServer = (allMisc) => {
    let _this = this;
    let query = this.payments.where("user_id", "==", fire.auth().currentUser.uid);
    query.get().then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(function (doc) {
          _this.payments.doc(doc.id).set({
            'misc': allMisc,
            'newTransactions': []
          }, {merge: true}).then(() => {
            // console.log('am updatat misc')
          }).catch(() => {
            // console.log('writing error', error)
          })
        })
      }
    });
  };

  getSpecificAccount = (specificIntitution, specificAccount) => {
    let allInstitutions = this.state.accounts;
    for (let i = 0; i < allInstitutions.length; i++) {
      let accountsPerInstitution = allInstitutions[i].accounts;
      for (let j = 0; j < accountsPerInstitution.length; j++) {
          if(accountsPerInstitution[j].name === specificAccount && allInstitutions[i].name === specificIntitution){
            return accountsPerInstitution[j];
          }
      }
    }
    return null;
  };
  renderTotalBalance = (accounts) => {
    if (accounts.length === 0) {
      return null;
    }

    let totalSum = 0;
    let byAccountSum = {};
    let accountName;
    accounts.forEach(item => {
      let itemAccounts = item.accounts ? item.accounts : [];
      itemAccounts.forEach((value) => {
        accountName = this.buildFormattedAccountName(item.name, value.name);
        if (!byAccountSum[accountName]) {
          byAccountSum[accountName] = 0;
        }
        if(value.type !== 'loan' && value.type !== 'credit') {
          totalSum += value.balances.current
          byAccountSum[accountName] += value.balances.current
        }
        if(value.type === 'credit') {
          totalSum -= value.balances.current
          byAccountSum[accountName] -= value.balances.current
        }
      });
    });
    return {total: totalSum, byAccounts: byAccountSum};
  };
  renderMiscSpendings = (transactions) => {
    if(transactions.length === 0) {
      return null;
    }
    let totalSum = 0;
    transactions.forEach((transaction) => {
      totalSum += transaction.amount
    })
    return totalSum;
  }
  componentDidMount() {
    let _this = this;
    if(!this.props.profile.activeSubscription){
      if(this.props.profile.subscription_period_end && moment(this.props.profile.subscription_period_end).diff(moment()) > 0){
        //subscription still exists
      } else {
        _this.props.history.push('/verify-phone');
        return;
      }
    }
    this.getInitialData();
    window.addEventListener('beforeunload', this.handleBeforeunload);
  }
  getInitialData = () => {
    let _this = this;
    let query = this.institutions.where("user_id", "==", fire.auth().currentUser.uid);
    query.get().then(function (querySnapshot) {
      let accounts = []
      let lastUpdated = ''
      let accountsById = {}
      querySnapshot.forEach(function (doc) {
        accountsById[doc.id] = doc.data();
        let accountsObject = {};
        let accountsArray =  accountsById[doc.id].accounts ? accountsById[doc.id].accounts : [];
        accountsArray.forEach((account) => {
          accountsObject[account.account_id] = account
        });
        accountsById[doc.id].accounts = accountsObject;
        accounts.push(doc.data());
        if(doc.data().lastUpdated){
          lastUpdated = doc.data().lastUpdated
        }
      });
      let accountsFormatted = [];
      accounts.forEach((institution) => {
        let institutionsAccounts = institution.accounts ? institution.accounts : [];
        institutionsAccounts.forEach((account) => {
          accountsFormatted.push(
            {
              icon: institution.logo,
              name: _this.buildFormattedAccountName(institution.name, account.name),
              type: account.type
            }
          )
        })
      })
      let totalBalance = _this.renderTotalBalance(accounts);
      _this.setState({
        accounts: accounts,
        accountsById: accountsById,
        balance: totalBalance.total,
        balanceByAccount: totalBalance.byAccounts,
        lastUpdated: lastUpdated,
        accountsFormatted: accountsFormatted
      })
      _this.getData();
    });
  };
  componentWillUnmount(){
    if (this.toggleTimeoutId !== 0) {
      clearTimeout(this.toggleTimeoutId)
    }
    this.checkIfSaveIsNeeded()
    window.removeEventListener('beforeunload', this.handleBeforeunload);
  }
  handleBeforeunload = event => {
      if(this.state.recurringChanged) {
        this.checkIfSaveIsNeeded();
        event.returnValue = 'Changed may be lost';
        return event;
      }
  };
  buildFormattedAccountName = (institution, account) => {
    return institution + ' - (' + account + ')';
  }
  checkIfSaveIsNeeded = () => {
    if(this.state.recurringChanged){
      this.saveNewRecurringPayments()
    }
  }
  saveNewRecurringPayments(callBack){
    let newRecurring = this.state.recurring
    let _this = this;
    this.payments.doc(this.state.paymentsDocId).set({
      'recurring': newRecurring
    }, {merge: true}).then(() => {
      // console.log('updatated recurring')
      _this.setState({
        recurringChanged: false
      });
      if(callBack) {
        callBack();
      }
    }).catch(() => {
      // console.log('writing error', error)
    })
  }
  updateRecurringPayments = (i, name, amount, recurring, due, account, isIncome, recurringFreq) => {
    let newLogo = '';
    this.state.accountsFormatted.forEach((accounts) => {
      if (accounts.name === account) {
        newLogo = accounts.icon
      }
    })
    let institution = account.substr(0, account.indexOf('- (')-1)
    let accountNameReggex = new RegExp('\\-.\\((.*)\\)');
    let accountName = account.match(accountNameReggex);
    let recurringPaymentsCopy = Object.assign([], this.state.recurring);

    let toDeletePayment = Object.assign({},recurringPaymentsCopy[i]);
    if (toDeletePayment.recurringFreq && (toDeletePayment.recurringFreq.value === 'Weekly' || toDeletePayment.recurringFreq.value === 'Every Two Weeks' || toDeletePayment.recurringFreq.value === 'Bi-Weekly')) {
      let recurringValue = toDeletePayment.recurringFreq.value;
      let numberOfDaysToDivide = toDeletePayment.recurringFreq.add === 1 ? 7 : 14;
      if(recurringValue === 'Bi-Weekly') {
        numberOfDaysToDivide = 1;
      }
      let fields = ['account', 'amount', 'institution', 'name', 'recurring'];
      if(toDeletePayment.transfer) {
        fields = ['amount', 'fromAccount', 'fromInstitution', 'toAccount', 'toInstitution', 'recurring']
      }
      recurringPaymentsCopy.slice(0).forEach((recurringPayment) => {
        if(!recurringPayment.recurringFreq) {
          return;
        }
        if(recurringPayment.recurringFreq.value !== recurringValue || (moment(recurringPayment.next_installment).diff(moment(toDeletePayment.next_installment), 'days')) % numberOfDaysToDivide !== 0){
          return;
        }
        for (let i = 0; i < fields.length; i++) {
          if(recurringPayment[fields[i]] !== toDeletePayment[fields[i]]) {
            return;
          }
        }
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].name = name;
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].amount = isIncome ? -parseFloat(amount) : parseFloat(amount);
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].account = accountName[1];
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].institution = institution;
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].institutionLogo = newLogo;
      })
    } else {
      recurringPaymentsCopy[i].name = name;
      recurringPaymentsCopy[i].amount = isIncome ? -parseFloat(amount) : parseFloat(amount);
      recurringPaymentsCopy[i].recurring = recurring;
      recurringPaymentsCopy[i].next_installment = moment(due).format('YYYY-MM-DD');
      recurringPaymentsCopy[i].account = accountName[1];
      recurringPaymentsCopy[i].institution = institution;
      recurringPaymentsCopy[i].institutionLogo = newLogo;
      recurringPaymentsCopy[i].recurringFreq = recurringFreq;
    }
    this.setState({
      recurring: recurringPaymentsCopy,
      recurringChanged: true
    }, () => this.saveNewRecurringPayments(this.getInitialData))
  };
  updateTransferPayment = (i, amount, dueDate, fromAccount, toAccount, recurring, recurringFreq) => {
    let fromLogo = '';
    let toLogo = '';
    this.state.accountsFormatted.forEach((accounts) => {
      if (accounts.name === fromAccount) {
        fromLogo = accounts.icon
      }
      if(accounts.name === toAccount) {
        toLogo = accounts.icon
      }
    })

    let accountNameReggex = new RegExp('\\-.\\((.*)\\)');
    let fromInstitution = fromAccount.substr(0, fromAccount.indexOf('- (')-1)
    let toInstitution = toAccount.substr(0, toAccount.indexOf('- (')-1)
    let fromAccountName = fromAccount.match(accountNameReggex);
    let toAccountName = toAccount.match(accountNameReggex);
    let recurringPaymentsCopy = Object.assign([], this.state.recurring);

    let toDeletePayment = Object.assign({},recurringPaymentsCopy[i]);
    if (toDeletePayment.recurringFreq && (toDeletePayment.recurringFreq.value === 'Weekly' || toDeletePayment.recurringFreq.value === 'Every Two Weeks' || toDeletePayment.recurringFreq.value === 'Bi-Weekly')) {
      let recurringValue = toDeletePayment.recurringFreq.value;
      let numberOfDaysToDivide = toDeletePayment.recurringFreq.add === 1 ? 7 : 14;
      if(recurringValue === 'Bi-Weekly') {
        numberOfDaysToDivide = 1;
      }
      let fields = ['account', 'amount', 'institution', 'name', 'recurring'];
      if(toDeletePayment.transfer) {
        fields = ['amount', 'fromAccount', 'fromInstitution', 'toAccount', 'toInstitution', 'recurring']
      }
      recurringPaymentsCopy.slice(0).forEach((recurringPayment) => {
        if(!recurringPayment.recurringFreq) {
          return;
        }
        if(recurringPayment.recurringFreq.value !== recurringValue || (moment(recurringPayment.next_installment).diff(moment(toDeletePayment.next_installment), 'days')) % numberOfDaysToDivide !== 0){
          return;
        }
        for (let i = 0; i < fields.length; i++) {
          if(recurringPayment[fields[i]] !== toDeletePayment[fields[i]]) {
            return;
          }
        }
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].amount = amount;
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].fromAccount = fromAccountName ? fromAccountName[1] : 'Other';
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].toAccount = toAccountName ? toAccountName[1] : 'Other';
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].fromInstitution = fromInstitution;
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].toInstitution = toInstitution;
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].fromLogo = fromLogo;
        recurringPaymentsCopy[recurringPaymentsCopy.indexOf(recurringPayment)].toLogo = toLogo;
      })
    } else {
      recurringPaymentsCopy[i].amount = amount;
      recurringPaymentsCopy[i].recurring = recurring;
      recurringPaymentsCopy[i].next_installment = moment(dueDate).format('YYYY-MM-DD');
      recurringPaymentsCopy[i].fromAccount = fromAccountName ? fromAccountName[1] : 'Other';
      recurringPaymentsCopy[i].toAccount = toAccountName ? toAccountName[1] : 'Other';
      recurringPaymentsCopy[i].fromInstitution = fromInstitution;
      recurringPaymentsCopy[i].toInstitution = toInstitution;
      recurringPaymentsCopy[i].fromLogo = fromLogo;
      recurringPaymentsCopy[i].toLogo = toLogo;
      recurringPaymentsCopy[i].recurringFreq = recurringFreq;
    }
    this.setState({
      recurring: recurringPaymentsCopy,
      recurringChanged: true
    }, () => this.saveNewRecurringPayments(this.getInitialData))
  };
  deleteRecurringPayments = (i) => {
    let recurringPaymentsCopy = Object.assign([], this.state.recurring);
    let toDeletePayment = Object.assign({},recurringPaymentsCopy[i]);
    if (toDeletePayment.recurringFreq && (toDeletePayment.recurringFreq.value === 'Weekly' || toDeletePayment.recurringFreq.value === 'Every Two Weeks' || toDeletePayment.recurringFreq.value === 'Bi-Weekly')) {
      let recurringValue = toDeletePayment.recurringFreq.value;
      let numberOfDaysToDivide = toDeletePayment.recurringFreq.add === 1 ? 7 : 14;
      if(recurringValue === 'Bi-Weekly') {
        numberOfDaysToDivide = 1;
      }
      let fields = ['account', 'amount', 'institution', 'name', 'recurring'];
      if(toDeletePayment.transfer) {
        fields = ['amount', 'fromAccount', 'fromInstitution', 'toAccount', 'toInstitution', 'recurring']
      }
      recurringPaymentsCopy.slice(0).forEach((recurringPayment) => {
        if(!recurringPayment.recurringFreq) {
          return;
        }
        if(recurringPayment.recurringFreq.value !== recurringValue || (moment(recurringPayment.next_installment).diff(moment(toDeletePayment.next_installment), 'days')) % numberOfDaysToDivide !== 0){
          return;
        }
        for (let i = 0; i < fields.length; i++) {
          if(recurringPayment[fields[i]] !== toDeletePayment[fields[i]]) {
            return;
          }
        }
        // console.log('bine bosulica', recurringPayment)
          recurringPaymentsCopy.splice(recurringPaymentsCopy.indexOf(recurringPayment), 1)
      })
    } else {
      recurringPaymentsCopy.splice(i, 1)
    }
    this.setState({
      recurring: recurringPaymentsCopy,
      recurringChanged: true
    }, () => this.saveNewRecurringPayments(this.calculateProjectedBalance))
  }
  addRecurringPayment = (name, amount, dueDate, recurring, account, isIncome, recurringFreq) => {
    let newLogo = '';
    this.state.accountsFormatted.forEach((accounts) => {
      if (accounts.name === account) {
        newLogo = accounts.icon
      }
    })
    let institution = account.substr(0, account.indexOf('- (')-1)
    let accountNameReggex = new RegExp('\\-.\\((.*)\\)');
    let accountName = account.match(accountNameReggex);
    let recurringPaymentsCopy = Object.assign([], this.state.recurring);
    recurringPaymentsCopy.push({
      name: name,
      amount: isIncome ? parseFloat(-amount) : parseFloat(amount),
      next_installment: moment(dueDate).format('YYYY-MM-DD'),
      account: accountName[1],
      institution: institution,
      scheduled: true,
      recurring: recurring,
      institutionLogo: newLogo,
      recurringFreq: recurringFreq
    })
    if(recurringFreq.value === 'Weekly') {
      for (let i = 0; i < 4; i++) {
        recurringPaymentsCopy.push({
          name: name,
          amount: isIncome ? parseFloat(-amount) : parseFloat(amount),
          next_installment: moment(dueDate).add(i+1, 'weeks').format('YYYY-MM-DD'),
          account: accountName[1],
          institution: institution,
          scheduled: true,
          recurring: recurring,
          institutionLogo: newLogo,
          recurringFreq: recurringFreq
        })
      }
    }
    if(recurringFreq.value === 'Every Two Weeks') {
      for (let i = 0; i < 2; i++) {
        recurringPaymentsCopy.push({
          name: name,
          amount: isIncome ? parseFloat(-amount) : parseFloat(amount),
          next_installment: moment(dueDate).add(i*2+2, 'weeks').format('YYYY-MM-DD'),
          account: accountName[1],
          institution: institution,
          scheduled: true,
          recurring: recurring,
          institutionLogo: newLogo,
          recurringFreq: recurringFreq
        })
      }
    }
    if(recurringFreq.value === 'Bi-Weekly') {
      let nextDueDate = '';
      if(parseInt(moment(dueDate).format('D')) === 1) {
        nextDueDate = moment(dueDate).add(14, 'days').format('YYYY-MM-DD')
      } else {
        nextDueDate = moment(dueDate).add(1, 'month').startOf('month').format('YYYY-MM-DD')
      }
      recurringPaymentsCopy.push({
        name: name,
        amount: isIncome ? parseFloat(-amount) : parseFloat(amount),
        next_installment: nextDueDate,
        account: accountName[1],
        institution: institution,
        scheduled: true,
        recurring: recurring,
        institutionLogo: newLogo,
        recurringFreq: recurringFreq
      })

    }
    const sortedRecurring = [].concat(recurringPaymentsCopy)
      .sort((a, b) => moment(a.next_installment).isBefore(b.next_installment) ? -1 : 1)
    this.setState({
      recurring: sortedRecurring,
      recurringChanged: true
    }, () => this.saveNewRecurringPayments(this.calculateProjectedBalance))
  }
  addTransferPayment = (amount, dueDate, fromAccount, toAccount, recurring, recurringFreq) => {
    let fromLogo = '';
    let toLogo = '';
    this.state.accountsFormatted.forEach((accounts) => {
      if (accounts.name === fromAccount) {
        fromLogo = accounts.icon
      }
      if(accounts.name === toAccount) {
        toLogo = accounts.icon
      }
    })

    let accountNameReggex = new RegExp('\\-.\\((.*)\\)');
    let fromInstitution = fromAccount.substr(0, fromAccount.indexOf('- (')-1);
    let toInstitution = toAccount.substr(0, toAccount.indexOf('- (')-1);
    let fromAccountName = fromAccount.match(accountNameReggex);
    let toAccountName = toAccount.match(accountNameReggex);
    let recurringPaymentsCopy = Object.assign([], this.state.recurring);
    recurringPaymentsCopy.push({
      name: 'Transfer',
      amount: amount,
      next_installment: moment(dueDate).format('YYYY-MM-DD'),
      fromAccount: fromAccountName ? fromAccountName[1] : 'Other',
      toAccount: toAccountName ?  toAccountName[1] : 'Other',
      fromInstitution: fromInstitution,
      toInstitution: toInstitution,
      scheduled: true,
      recurring: recurring,
      fromLogo: fromLogo,
      toLogo: toLogo,
      transfer: true,
      recurringFreq: recurringFreq
    });
    if(recurringFreq.value === 'Weekly') {
      for (let i = 0; i < 4; i++) {
        recurringPaymentsCopy.push({
          name: 'Transfer',
          amount: amount,
          next_installment: moment(dueDate).add(i+1, 'weeks').format('YYYY-MM-DD'),
          fromAccount: fromAccountName ? fromAccountName[1] : 'Other',
          toAccount: toAccountName ?  toAccountName[1] : 'Other',
          fromInstitution: fromInstitution,
          toInstitution: toInstitution,
          scheduled: true,
          recurring: recurring,
          fromLogo: fromLogo,
          toLogo: toLogo,
          transfer: true,
          recurringFreq: recurringFreq
        })
      }
    }
    if(recurringFreq.value === 'Every Two Weeks') {
      for (let i = 0; i < 2; i++) {
        recurringPaymentsCopy.push({
          name: 'Transfer',
          amount: amount,
          next_installment: moment(dueDate).add(i*2+2, 'weeks').format('YYYY-MM-DD'),
          fromAccount: fromAccountName ? fromAccountName[1] : 'Other',
          toAccount: toAccountName ?  toAccountName[1] : 'Other',
          fromInstitution: fromInstitution,
          toInstitution: toInstitution,
          scheduled: true,
          recurring: recurring,
          fromLogo: fromLogo,
          toLogo: toLogo,
          transfer: true,
          recurringFreq: recurringFreq
        })
      }
    }
    if(recurringFreq.value === 'Bi-Weekly') {
      let nextDueDate = '';
      if(parseInt(moment(dueDate).format('D')) === 1) {
        nextDueDate = moment(dueDate).add(14, 'days').format('YYYY-MM-DD')
      } else {
        nextDueDate = moment(dueDate).add(1, 'month').startOf('month').format('YYYY-MM-DD')
      }
      recurringPaymentsCopy.push({
        name: 'Transfer',
        amount: amount,
        next_installment: nextDueDate,
        fromAccount: fromAccountName ? fromAccountName[1] : 'Other',
        toAccount: toAccountName ?  toAccountName[1] : 'Other',
        fromInstitution: fromInstitution,
        toInstitution: toInstitution,
        scheduled: true,
        recurring: recurring,
        fromLogo: fromLogo,
        toLogo: toLogo,
        transfer: true,
        recurringFreq: recurringFreq
      })
    }
    const sortedRecurring = [].concat(recurringPaymentsCopy)
      .sort((a, b) => moment(a.next_installment).isBefore(b.next_installment) ? -1 : 1)
    this.setState({
      recurring: sortedRecurring,
      recurringChanged: true
    }, () => this.saveNewRecurringPayments(this.calculateProjectedBalance))
  }
  calculateProjectedBalance = () => {
    let timeLimit = '';
    if(this.state.dropDownSelected.end) {
      timeLimit = moment().endOf(this.state.dropDownSelected.increment)
    } else {
      timeLimit = moment().add(this.state.dropDownSelected.value, this.state.dropDownSelected.increment).endOf('day');
    }
    //get bills that are checked that are due to less than 1 week
    let sum = 0;
    let sumByAccount = {};
    let accountNameList = this.state.accountsFormatted.map(a => a.name);
    let accountName;
    this.state.accountsFormatted.forEach((account) => {
      sumByAccount[account.name] = 0;
    });
    this.state.recurring.forEach((bill) => {
      if(moment(bill.next_installment).isBefore(timeLimit) && moment(bill.next_installment).isAfter(moment().subtract(1,'days')) &&bill.scheduled) {
        accountName = this.buildFormattedAccountName(bill.institution, bill.account);
        if(accountNameList.includes(accountName)) {
          if (!sumByAccount[accountName]) {
            sumByAccount[accountName] = 0;
          }
          sum += parseInt(bill.amount);
          sumByAccount[accountName] += parseInt(bill.amount);
        }
      }
      if(moment(bill.next_installment).isBefore(timeLimit) && moment(bill.next_installment).isAfter(moment().subtract(1,'days')) &&bill.scheduled && bill.transfer){
        let fromAccountName  = this.buildFormattedAccountName(bill.fromInstitution, bill.fromAccount);
        let toAccountName = this.buildFormattedAccountName(bill.toInstitution, bill.toAccount);
        if(accountNameList.includes(fromAccountName)) {
          if (!sumByAccount[fromAccountName]) {
            sumByAccount[fromAccountName] = 0;
          }
          if(toAccountName.includes('Other')){
            sum += parseInt(bill.amount)
          }
          sumByAccount[fromAccountName] += parseInt(bill.amount);
        }
        if(accountNameList.includes(toAccountName)) {
          if (!sumByAccount[toAccountName]) {
            sumByAccount[toAccountName] = 0;
          }
          if(fromAccountName.includes('Other')){
            sum -= parseInt(bill.amount)
          }
          sumByAccount[toAccountName] -= parseInt(bill.amount);
        }
      }
    })
    let projectedBalanceByAccount = Object.assign({},this.state.balanceByAccount);
    for (var key in projectedBalanceByAccount) {
      projectedBalanceByAccount[key] -= sumByAccount[key]
    }
    this.setState({
      projectedBalance: this.state.balance - sum,
      projectedBalanceByAccount: projectedBalanceByAccount
    })

  };

  projectedBalanceDropdownHandler = () => {
    this.setState(prevState => {
      return ({
        showProjectedDropdown: !prevState.showProjectedDropdown
      })
    });
  };

  areDatesAmonthApart = (date1, date2) => {
    let timeDifference = moment.duration(moment(date1).diff(date2)).asDays();
    if (timeDifference > 27 && timeDifference < 32) {
      return true
    }
    return false
  };
  selectBreakdown = () => {
    let current = this.state.showBreakdown
    this.setState({
      showBreakdown: !current
    })
  };
  openAddBill = () => {
    this.setState({
      showBillModal: true,
      editBillId: null,
      editTransfer: false
    });
  };
  openEditBill = (i) => {
    this.setState({
      showBillModal: true,
      editBillId: i,
      editTransfer: false
    });
  };
  openEditTransfer = (i) => {
    this.setState({
      showBillModal: true,
      editBillId: i,
      editTransfer: true
    });
  }
  handleCloseBill = () => {
    this.setState({
      showBillModal: false,
      editBillId: null
    });
  };
  openAddMisc = () => {
    this.setState({
      showMiscModal: true,
      miscEditKey: ''
    });
  };
  openEditMisc = (category) => {
    this.setState({
      showMiscModal: true,
      miscEditKey: category
    });
  };
  handleCloseMisc = () => {
    this.setState({
      showMiscModal: false,
      miscEditKey: ''
    })
  };
  toggleBills = (i, scheduled) => {
    if (this.toggleTimeoutId !== 0) {
      clearTimeout(this.toggleTimeoutId)
    }
    this.toggleTimeoutId = setTimeout(() => this.saveNewRecurringPayments(), 4000)

    let recurringPaymentsCopy = Object.assign([], this.state.recurring);
    recurringPaymentsCopy[i].scheduled = !scheduled;
    this.setState({
      recurring: recurringPaymentsCopy,
      recurringChanged: true
    }, () => this.calculateProjectedBalance());
  };
  changeProjectedDate = (item) => {
    this.setState({
      dropDownSelected: item
    }, () => this.calculateProjectedBalance())
  };
  detectActivities = (desiredCategories, amount, genericName) => {
    let miscPayments = this.state.allMisc;
    let findAtLeastOne = false;
    // let miscPayments = {}
    this.state.accounts.forEach((data) => {
      let accounts = data.accounts ? data.accounts : [];
      accounts.forEach((account) => {
        let transactions = account.transactions;
        transactions.forEach((transaction) => {
          let categories = transaction.category;
          loop1: for (let i = 0; i < categories.length; i ++) {
            let category = categories[i];
            //loop2
            for (let j = 0 ; j < desiredCategories.length; j++) {
              let desiredCategory = desiredCategories[j];
              let date = moment(transaction.date).format('YYYY-MM');
              if(category === desiredCategory && date === moment().format('YYYY-MM')) {
                findAtLeastOne = true;
                  if (miscPayments[date] ) {
                    if(miscPayments[date][genericName]) {
                      let payments = miscPayments[date][genericName].transactions;
                      payments.push({
                        account_id: transaction.account_id,
                        amount: transaction.amount,
                        category: transaction.category,
                        name: transaction.name,
                        date: transaction.date,
                        category_id: transaction.category_id,
                        pending: transaction.pending
                      })
                    } else {
                        miscPayments[date][genericName] = {
                          transactions: [{
                            account_id: transaction.account_id,
                            amount: transaction.amount,
                            category: transaction.category,
                            name: transaction.name,
                            date: transaction.date,
                            category_id: transaction.category_id,
                            pending: transaction.pending
                          }],
                          budget: amount,
                          actualCategories: desiredCategories
                        };
                    }
                  } else {
                    miscPayments[date] = {
                      [genericName] : {
                        transactions: [{
                          account_id: transaction.account_id,
                          amount: transaction.amount,
                          category: transaction.category,
                          name: transaction.name,
                          date: transaction.date,
                          category_id: transaction.category_id,
                          pending: transaction.pending
                        }],
                        budget: amount,
                        actualCategories: desiredCategories
                      }
                    }
                  }
                //if transaction match to one category we add it and stop verifying the others
                break loop1;
              }
            }
          }
        })
      })
    })
    if(findAtLeastOne) {
      return miscPayments;
    }
    return null;
  };
  addActivity = (amount, categories) => {
    let miscPayments =  this.detectActivities(categories.categories, parseFloat(amount), categories.name);
    if(!miscPayments){
      miscPayments = JSON.parse(JSON.stringify(this.state.allMisc))
      if (miscPayments[moment().format('YYYY-MM')]) {
        miscPayments[moment().format('YYYY-MM')][categories.name] = {
          transactions: [],
          budget: parseFloat(amount),
          actualCategories: categories.categories
        };
      } else {
        miscPayments[moment().format('YYYY-MM')] = {
          [categories.name] : {
            transactions: [],
            budget: parseFloat(amount),
            actualCategories: categories.categories
          }
        }
      }
    }
    let alreadyAddedCategories = JSON.parse(JSON.stringify(this.state.alreadyAddedCategories));
    alreadyAddedCategories.push({
      name: categories.name,
      categories: categories.categories
    });
    this.setState({
      alreadyAddedCategories: alreadyAddedCategories,
      misc: miscPayments[moment().format('YYYY-MM')],
      allMisc: miscPayments
    });
    this.addMiscPayments(miscPayments);
  };
  addMiscPayments = (miscPayments) => {
    let _this = this;
    let query = this.payments.where("user_id", "==", fire.auth().currentUser.uid);
    query.get().then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(function (doc) {
          _this.payments.doc(doc.id).set({
            'misc': miscPayments
          }, {merge: true}).then(() => {
            // console.log('am updatat misc')
          }).catch(() => {
            // console.log('writing error', error)
          })
        })
      } else {
        _this.payments.add({
          user_id: fire.auth().currentUser.uid,
          misc: miscPayments
        }).then(() => {

        });
      }
    });
  };
  updateMiscAmount = (newAmount) => {
    let allMisc = JSON.parse(JSON.stringify(this.state.allMisc));
    let dateKey = moment().format('YYYY-MM');
    for (let key in allMisc[dateKey]) {
      if(key === this.state.miscEditKey) {
        allMisc[dateKey][key].budget = newAmount
      }
    }
    let newMisc = allMisc[dateKey];
    this.setState({
      allMisc: allMisc,
      misc: newMisc,
    });
    this.addMiscPayments(allMisc);
  };
  deleteMiscCategory = () => {
    let allMisc = JSON.parse(JSON.stringify(this.state.allMisc));
    let dateKey = moment().format('YYYY-MM');
    delete allMisc[dateKey][this.state.miscEditKey];
    let newMisc = allMisc[dateKey];
    let alreadyAdded = this.state.alreadyAddedCategories;
    alreadyAdded.slice(0).forEach((addedPayment,i) => {
      if(addedPayment.name === this.state.miscEditKey) {
        alreadyAdded.splice(i, 1)
      }
    });
    this.setState({
      allMisc: allMisc,
      misc: newMisc,
      alreadyAddedCategories: alreadyAdded
    });
    this.deleteMiscCategoryOnServer(allMisc);
  };
  deleteMiscCategoryOnServer = (miscPayments) => {
    //delete misc on server
    let _this = this;
    let query = this.payments.where("user_id", "==", fire.auth().currentUser.uid);
    query.get().then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(function (doc) {
          _this.payments.doc(doc.id).update({
            'misc': miscPayments
          }).then(() => {
            // console.log('am updatat misc')
          }).catch(() => {
            // console.log('writing error', error)
          })
        })
      } else {
        _this.payments.add({
          user_id: fire.auth().currentUser.uid,
          misc: miscPayments
        }).then(() => {

        });
      }
    });
  };
  refreshData = () => {
    this.props.getUpdatedTransactions(this.getInitialData)
  };
  render() {
    const {recurring, misc, dropDownSelected, showBreakdown} = this.state
    const projectedAccounts = this.state.accountsFormatted.filter((acc) => { return acc.type !== 'loan' })
    const billModal = this.state.showBillModal
      ? (<Modal onClose={this.handleCloseBill}>
        <BudgetBill editBillId={this.state.editBillId}
                    closeModal={this.handleCloseBill}
                    addRecurringPayment={this.addRecurringPayment}
                    addTransferPayment={this.addTransferPayment}
                    bills={recurring}
                    updateRecurringPayments={this.updateRecurringPayments}
                    deleteRecurringPayments={this.deleteRecurringPayments}
                    accounts={this.state.accountsFormatted}
                    editTransfer={this.state.editTransfer}
                    updateTransferPayment={this.updateTransferPayment}
        />
      </Modal>) : null;

    const miscModal = this.state.showMiscModal
      ? (<Modal onClose={this.handleCloseMisc}>
        <BudgetMisc editItemId={this.state.editMiscId}
                    closeModal={this.handleCloseMisc}
                    addActivity={this.addActivity}
                    alreadyAddedCategories={this.state.alreadyAddedCategories}
                    allMisc={this.state.allMisc}
                    editedKey={this.state.miscEditKey}
                    updateMiscAmount={this.updateMiscAmount}
                    deleteMiscCategory={this.deleteMiscCategory}
        />
      </Modal>) : null;
    return (
      <React.Fragment>
        <TopTitlesContainer>
          <FlexSpacer/>
          <div className="account-card-top-spacer-container">
            <AllAccountsTitle>
              Budget
            </AllAccountsTitle>
          </div>
          <FlexSpacer/>
        </TopTitlesContainer>

        <BudgetPageOuterContainer display="flex" padding={'25px'}
                                  color={'linear-gradient(180deg, #1B1E22 0%, #454B54 100%)'} radius={'2px 2px 0 0'}>
          <FlexSpacer/>
          <div className="budget-page-inner-container">
            <BalanceCardGrid>
              <BalanceCard color={'#FFF'}>
                <FlexSpacer/>
                <div>
                  <BalanceCardText color={'rgba(69,75,84,0.5)'}>Current Balance</BalanceCardText>
                  <BalanceCardAmount isDark={true}>{formatAccountTotal(this.state.balance)} </BalanceCardAmount>
                  {moment(this.state.lastUpdated).fromNow() !== 'Invalid date' &&
                  <BalancedTextRefreshContainer>
                    <BalanceCardText
                      color={'rgba(69,75,84,0.5)'}>Updated {moment(new Date(this.state.lastUpdated)).fromNow()}</BalanceCardText>
                    <RefreshIconContainer onClick={this.refreshData}>{RefreshIcon}</RefreshIconContainer>
                  </BalancedTextRefreshContainer>
                  }
                </div>
                <FlexSpacer/>
              </BalanceCard>
              <BalanceCard>
                <TimeClockSvg>{TimeClockIcon}</TimeClockSvg>
                <FlexSpacer/>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <BalanceCardText color={'#FFF'}>Projected Balance
                    <OvalDateDropdownContainer onClick={this.projectedBalanceDropdownHandler}>
                    <OvalDateButton fullWidth={true} showProjectedDropdown={this.state.showProjectedDropdown} color={'rgba(255,255,255,0.12)'}>
                      {dropDownSelected.label}
                      <ArrowContainer viewBox="0 50 100 60">{DownArrowIcon}</ArrowContainer>
                      </OvalDateButton>
                        <ProjectedBalanceDropdownList showProjectedDropdown={this.state.showProjectedDropdown}>
                          {this.state.dropDownData.map((item,i) => {
                            if(item.label !== dropDownSelected.label)
                              return <li className="dropdown-list-item" key={i} onClick={() => this.changeProjectedDate(item)}><button>{item.label}</button></li>
                          })}

                        </ProjectedBalanceDropdownList>
                    </OvalDateDropdownContainer>
                    <div>
                      <div style={{position: 'relative'}}>
                        <HintButtonCustom style={{marginLeft: '20px'}}>
                          {HintIcon}
                        </HintButtonCustom>
                        <HintText style={{backgroundColor:'#222529', color:'#ffffff'}}>This shows how much money you will have left in all of your accounts after all of your monthly bills and income</HintText>
                      </div>
                    </div>
                  </BalanceCardText>
                  <BalanceCardAmount isGreen={true}>{formatAccountTotal(this.state.projectedBalance)}</BalanceCardAmount>
                  <BalanceCardText color={'rgba(255,255,255,0.4);'}>On {
                    dropDownSelected.end ? moment().endOf(dropDownSelected.increment).format('MMMM D') : moment().add(dropDownSelected.value, dropDownSelected.increment).format('MMMM D')
                  }</BalanceCardText>
                </div>
                <FlexSpacer/>
              </BalanceCard>
            </BalanceCardGrid>
          </div>
          <FlexSpacer/>
        </BudgetPageOuterContainer>

        <BudgetPageOuterContainer display="flex" color={'#454B54'}>
          <FlexSpacer/>
          <div className={showBreakdown ? "budget-page-inner-container breakdown" : "budget-page-inner-container breakdown collapsed"}>
              <BalanceCardGrid>
                {projectedAccounts.map((item, i) => {
                  let balance = this.state.projectedBalanceByAccount[item.name];
                  return (
                    <BalanceCard key={i} color={'#FFF'}>
                      <FlexSpacer/>
                      <div>
                        <BalanceCardAccount>
                          <img src={`data:image/jpeg;base64,${item.icon}`}/>
                          <BalanceCardText>{item.name}</BalanceCardText>
                        </BalanceCardAccount>
                        <BalanceCardAmount isGreen={true}>{formatAccountTotalWithZero(balance)} </BalanceCardAmount>
                        <BalanceCardText color={'rgba(69,75,84,0.5)'}>Projected Balance</BalanceCardText>
                      </div>
                      <FlexSpacer/>
                    </BalanceCard>
                  )
                })}
              </BalanceCardGrid>
          </div>
          <FlexSpacer/>
        </BudgetPageOuterContainer>

        <BudgetPageOuterContainer display="block" radius={'0 0 2px 2px'}>
          <FlexSpacer/>
          <div className="budget-page-inner-container">
            <BreakdownButton onClick={this.selectBreakdown}>
              {showBreakdown ? 'Hide Breakdown' : 'Show Breakdown'}<ArrowContainer flipVertically={showBreakdown} viewBox="0 50 100 60">{DownArrowIcon}</ArrowContainer>
            </BreakdownButton>
          </div>
          <FlexSpacer/>
        </BudgetPageOuterContainer>

        {/* DIVIDES TOP FROM BOTTOM SECTIONS */}
        <HeightSpacer/>

        <BudgetPageOuterContainer display="block" padding={'30px 0 0'} color={'#FFF'} radius={'2px'}>
          <FlexSpacer/>
          <div className="budget-page-inner-container">

            <BudgetPageTable>
              <TableHeadersFlexRow>
                <TableTitleHeader>Income and Bills</TableTitleHeader>
                <div>
                  <div style={{position: 'relative'}}>
                    <HintButtonCustom style={{marginLeft: '10px'}}>
                      {HintIcon}
                    </HintButtonCustom>
                    <HintText style={{left: '50px'}}>These are your upcoming bills, income, or expenses. You can also add items manually by selecting the + button below</HintText>
                  </div>
                </div>
                <div style={{flexGrow: 1}}/>
                <TableScheduledHeader>
                <div className="table-header-scheduled-container" >
                <p className="table-header-scheduled-text">
                  Scheduled?
                </p>
                  <div style={{position: 'relative'}}>
                    <HintButton className="table-header-scheduled-button">
                      {HintIcon}
                    </HintButton>
                    <HintText>Toggling scheduled on or off lets you see how your Projected Balance is affected</HintText>
                  </div>
                </div>
                </TableScheduledHeader>
              </TableHeadersFlexRow>
              {recurring.map((item,i) =>
                <IncomeTableItem key={i}>
                  <IncomeTableItemCell>
                    <EditItemIconButton onClick={() => item.transfer ? this.openEditTransfer(i) : this.openEditBill(i)}>
                      <PencilIconSvg viewBox="0 0 56 56">
                        {PencilIcon}
                      </PencilIconSvg>
                    </EditItemIconButton>
                  </IncomeTableItemCell>
                  <IncomeTableItemCell>
                    <TableTitleContainer>
                      <TableItemTitle>
                        {item.name}
                      </TableItemTitle>
                      {item.recurring &&
                      <div>
                        {RecurringIcon}
                      </div>
                      }
                    </TableTitleContainer>
                  </IncomeTableItemCell>
                  <IncomeTableItemCell className="table-spacer"/>
                  <IncomeTableItemCell>
                    {item.transfer ?
                      <div>
                        <TableItemBankAccountSection>
                          {item.fromLogo &&
                          <img src={`data:image/jpeg;base64,${item.fromLogo}`} alt=""/>} <p>{item.fromAccount}</p>
                          <img src={icons.rightArrow} style={{margin: '0 10px'}}/>
                          {item.toLogo &&
                          <img src={`data:image/jpeg;base64,${item.toLogo}`} alt=""/>}<p>{item.toAccount}</p>
                        </TableItemBankAccountSection>
                      </div>
                      :
                      <div>
                        <TableItemBankAccountSection>
                          <img src={`data:image/jpeg;base64,${item.institutionLogo}`} alt=""/> <p>{item.account}</p>
                          <FlexSpacer/>
                        </TableItemBankAccountSection>
                      </div>
                    }
                  </IncomeTableItemCell>
                  <IncomeTableItemCell className="table-spacer"/>
                  <IncomeTableItemCell>
                    <TableItemDueDate>
                      Due {moment(item.next_installment).format('MMMM Do')}
                    </TableItemDueDate>
                  </IncomeTableItemCell>
                  <IncomeTableItemCell>
                    <TableItemCheckbox
                      onClick={() => this.toggleBills(i, item.scheduled)}
                    >
                      <input
                        checked={item.scheduled}
                        type="checkbox"
                      />
                      <span className="checkmark"></span>
                    </TableItemCheckbox>
                  </IncomeTableItemCell>
                  <IncomeTableItemCell>
                    <TableItemAmount>
                     {formatAccountTotalFull(-item.amount)}
                    </TableItemAmount>
                  </IncomeTableItemCell>
                </IncomeTableItem>
              )}
            </BudgetPageTable>

            <AddItemSection>
              <button onClick={this.openAddBill}>
                <p>+</p>
              </button>
            </AddItemSection>

          </div>
          <FlexSpacer/>
        </BudgetPageOuterContainer>

        {/* DIVIDES BOTTOM SECTIONS */}
        <HeightSpacer/>

        <BudgetPageOuterContainer display="block" padding={'30px 0 0'} color={'#FFF'} radius={'2px'}>
          <FlexSpacer/>
          <div className="budget-page-inner-container">

            <BudgetPageTable>
              <TableHeadersMiscFlexRow>
                <TableTitleHeader>Misc
                  <OvalDateLabel color={'#F2F2F2'}>
                    <MiscMonthDropdownParagraph>
                      {moment().format('MMMM')}
                    </MiscMonthDropdownParagraph>
                    {/*<DownArrowContainer viewBox="0 50 100 60">{GreyDownArrowIcon}</DownArrowContainer>*/}
                  </OvalDateLabel>
                  <div>
                    <div style={{position: 'relative'}}>
                      <HintButtonCustom style={{marginLeft: '10px'}}>
                        {HintIcon}
                      </HintButtonCustom>
                      <HintText style={{left: '50px'}}>These are your monthly budgets for specific categories</HintText>
                    </div>
                  </div>
                </TableTitleHeader>
                <MiscTableHeaderTexts>Budgeted</MiscTableHeaderTexts>
                <MiscTableHeaderTexts>Spent</MiscTableHeaderTexts>
                <MiscTableHeaderTexts>Total</MiscTableHeaderTexts>
              </TableHeadersMiscFlexRow>
              {Object.keys(misc).map((category, index) =>(
                <MiscTableItem key={index}>
                  <MiscTableItemCell>
                    <EditItemIconButton onClick={() => this.openEditMisc(category)}>
                      <PencilIconSvg viewBox="0 0 56 56">
                        {PencilIcon}
                      </PencilIconSvg>
                    </EditItemIconButton>
                  </MiscTableItemCell>
                  <MiscTableItemCell>
                    <TableItemTitle>
                      {category}
                    </TableItemTitle>
                  </MiscTableItemCell>
                  <MiscTableItemCell className="table-spacer"/>
                  <MiscTableItemCell>
                    <TableAmountInputContainer>
                      <label className="table-label">$</label>
                      <input className="table-input" type='number' value={misc[category].budget} disabled/>
                    </TableAmountInputContainer>
                  </MiscTableItemCell>
                  <MiscTableItemCell className="table-spacer"/>
                  <MiscTableItemCell>
                    <TableItemAmount>
                      {formatAccountTotalFull(this.renderMiscSpendings(misc[category].transactions)) || '$0'}
                    </TableItemAmount>
                  </MiscTableItemCell>
                  <MiscTableItemCell className="table-spacer"/>
                  <MiscTableItemCell>
                    <TableItemAmount>
                      {formatAccountTotalFull(misc[category].budget - this.renderMiscSpendings(misc[category].transactions)) || '$0'}
                    </TableItemAmount>
                  </MiscTableItemCell>
                </MiscTableItem>
              ))}
            </BudgetPageTable>

            <AddItemSection>
              <button onClick={this.openAddMisc}>
                <p>+</p>
              </button>
            </AddItemSection>

          </div>
          <FlexSpacer/>
        </BudgetPageOuterContainer>

        <BottomLogoSection/>
        {billModal}
        {miscModal}
      </React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions, dispatch) };
}
function mapStateToProps({account}){
  return { profile : account};
}
Budget.propTypes = {
  profile:PropTypes.object,
  history: PropTypes.object,
  account: PropTypes.object,
  actions: PropTypes.object,
  getUpdatedTransactions: PropTypes.func
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Budget));

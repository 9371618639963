import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../components/PrimaryButton';
import PrimaryInput from '../components/PrimaryInput';
import isValid from '../utils/inputValidator';
import ErrorText from '../components/ErrorText';
import fire from '../config/fire';
import PropTypes from 'prop-types';
import ButtonLoader from  '../components/ButtonLoader';
import Images from '../images/Images'
import {FullLogo} from "../components/Logos";
import {Link, withRouter} from 'react-router-dom';

const ForgotPasswordContainer = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    background-position-x: initial;
  }
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  background-color: white;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RegisterFormContainer = styled.div`
  width: 350px;
  padding: 20px 0;
`;
const ErrorsContainer = styled.div`
  margin-bottom: 20px;
`;
const SuccessMessage = styled.div`
  color: ${props => props.theme.primaryColor};
  padding-top: 10px;
  font-family: 'NotoSans-Bold',serif;
  font-size: 13px;
`;
class ForgotPassword extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      email:'',
      error: {
        email:'',
      },
      liveValidation: {
        email:false,
      },
      hasErrors: 0,
      sendingToServer: false,
      successMessage: ''
    }
  }
  handleChange = (e, validate) =>  {
    this.setState({ [e.target.name]: e.target.value });
    if (validate) {
      let validationTypeString = e.target.getAttribute('validationtype');
      let validationArray = validationTypeString.split('|');
      validationArray.forEach((validationType) =>{
        if(!isValid(e.target.value,validationType)) {
          let errorMessage = '';
          if(validationType === 'email'){
            errorMessage = 'Please enter a valid email address'
          }
          this.setState({ error: { ...this.state.error, [e.target.name]: errorMessage} });
        } else {
          this.setState({ error: { ...this.state.error, [e.target.name]: ''} });
        }
      });
    }
  };
  resetPassword = () => {
    // console.log(this.props,window.location.hostname);
    let _this = this;
    if(this.validateAll()) {
      this.setState({
        sendingToServer: true
      });
      let actionCodeSettings = {
        url: 'http://' + window.location.hostname + '/login',
      };
      fire.auth().sendPasswordResetEmail(this.state.email,actionCodeSettings).then(function() {
        _this.setState({
          sendingToServer: false,
        },() =>{
          _this.setState({
            successMessage: 'We have sent you an email with reset instructions.'
          })
        });
      }).catch(function(error) {
        _this.setState({
          sendingToServer: false
        },() =>_this.alertError(error.message));
      });
    }
  };
  alertError = (error) => {
    setTimeout(() => {
      window.alert(error)
    },1000);
  };
  validateAll = () => {
    const {error} = this.state;
    const {liveValidation} = this.state;
    let errorCopy = error;
    let validateCopy = liveValidation;
    let valid = 1;
    errorCopy.email = '';
    if(!isValid(this.state.email,'email')){
      errorCopy.email = 'Please enter a valid email address';
      validateCopy.email = true;
      valid = 0;
    }
    this.setState({
      error: errorCopy,
      liveValidation: validateCopy
    });
    return valid;
  };
  validateField = (e) => {
    if(e.target.value) {
      let validationTypeString = e.target.getAttribute('validationtype');
      let validationArray = validationTypeString.split('|');
      validationArray.forEach((validationType) =>{
        if(!isValid(e.target.value,validationType)) {
          let errorMessage = '';
          if(validationType === 'email'){
            errorMessage = 'Please enter a valid email address'
          }
          this.setState({ error: { ...this.state.error, [e.target.name]: errorMessage},
            liveValidation: { ...this.state.liveValidation, [e.target.name] : true}
          });
        } else {
          this.setState({ error: { ...this.state.error, [e.target.name]: ''},});
        }
      });
    }
  };
  render(){
    return (
      <ForgotPasswordContainer image={Images.loginBackground}>
        <Link to='/login'>
          <FullLogo style={{marginBottom: '100px'}}/>
        </Link>
        <RightSide>
          <div>
            <RegisterFormContainer>
              <ErrorsContainer>
                <ErrorText errorText={this.state.error.email} />
              </ErrorsContainer>
              <PrimaryInput value={this.state.email} name="email"
                            onChange={(e) =>this.handleChange(e,this.state.liveValidation.email)}
                            label="Enter your email" type="email" haserror={this.state.error.email}
                            validationtype="email" onBlur={(e) => this.validateField(e)}
                            fieldname="Email"
              />
              <SuccessMessage>{this.state.successMessage}</SuccessMessage>
              {this.state.sendingToServer ?
                <ButtonLoader loading={this.state.sendingToServer} style={{marginTop: "35px", marginBottom: '1rem'}}/>
                :
                <PrimaryButton onClick={this.resetPassword} style={{marginTop: "35px", marginBottom: '1rem'}}>Reset Password</PrimaryButton>
              }
            </RegisterFormContainer>
          </div>
        </RightSide>
      </ForgotPasswordContainer>
    );
  }
}
ForgotPassword.propTypes = {
  props:PropTypes.object,
  actions:PropTypes.object,
  profile:PropTypes.object
};
export default withRouter(ForgotPassword);


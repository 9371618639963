import React from 'react';
import PropTypes from 'prop-types';
import styled, {withTheme} from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import {withRouter} from 'react-router-dom';

import { ProfileImage, ProfileImageAdd } from '../css/styled_components/ProfileComponents';
import TextButton from './TextButton';
import Modal from './Modal';
import UploadProfilePhotoModal from './modal/UploadProfilePhotoModal';
import {logout} from '../data/Auth';
import axios from "axios/index";
import fire from "../config/fire";
import moment from 'moment'
import { PropagateLoader } from 'react-spinners';
import firebase from 'firebase'

const Box = styled.div`
  height: 173px;
  position: absolute;
  right: 0;
  bottom: -173px;
  z-index: 5;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
  background-color: ${props => props.theme.defaultBackground};
  display: ${props => props.isVisible ? 'block' : 'none'};
`;

const InnerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-bottom: ${props => '1px solid ' + props.theme.separator}
`;

const ImageBox = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;

const Name = styled.p`
  margin: 0;
  color: ${props => props.theme.secondaryColor};
  font-family: "NotoSans-Bold", serif;
  font-size: 1.2rem;
  line-height: 1.6rem;
`;

const Email = styled.p`
  margin: 5px 0 0;
  color: ${props => props.theme.thirdColor};
  font-family: "NotoSans-Regular", serif;
  font-size: 1rem;
  line-height: 1.2rem;
`;

class ProfileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.account.email,
      name: this.props.account.firstName + ' ' + this.props.account.lastName,
      showPhotoModal: false,
      unSubscribing: false,
      activeSubscription: this.props.account.activeSubscription,
      subscription_period_end: this.props.account.subscription_period_end
    };
    this.users = fire.firestore().collection('users');
  }
  onLogout = () => {
    logout();
    setTimeout(() => this.props.actions.logout(), 2000)

  };
  handleShowModal = () => {
    this.setState({
      showPhotoModal: true
    });
    this.props.onClose();
  };
  handleCloseModal = () => {
    this.setState({
      showPhotoModal: false
    });
  };
  unsubscribe = () => {
    let _this = this;
    this.setState({
      unSubscribing: true
    });
    this.users.doc(fire.auth().currentUser.uid).get().then(function(doc) {
      let subscriptionId = doc.data().subscriptionId;
      if(subscriptionId){
        fire.auth().currentUser.getIdToken().then(function(token) {
          let auth = 'Bearer ' + token;
          axios({
            method: 'post',
            url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/cancelSubscription',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': auth,
            },
            data: {
              subscription_id: subscriptionId
            }
          }).then((response) => {
            if(response.data === 'success'){
              _this.deleteCustomer();
            } else {
              alert('Something went wrong')
            }
          }).catch((error) => {
            if(error.response) {
              _this.setState({
                unSubscribing: false
              }, () => alert(error.response.data.error.message));
            }
          })
        })
      } else {
        alert('You are not subscribed to any plan')
      }
    })

  };
  deleteCustomer = () => {
    let _this = this;
    this.users.doc(fire.auth().currentUser.uid).get().then(function(doc) {
      let stripeCustomerToken = doc.data().stripeCustomerToken;
      let subscriptionEndsAt = doc.data().subscription_period_end;
      if(stripeCustomerToken){
        fire.auth().currentUser.getIdToken().then(function(token) {
          let auth = 'Bearer ' + token;
          axios({
            method: 'post',
            url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/deleteCustomer',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': auth,
            },
            data: {
              stripeCustomerToken: stripeCustomerToken
            }
          }).then((response) => {
            if(response.data === 'success'){
              let reduxData = {
                activeSubscription: false,
                subscription_period_end: subscriptionEndsAt
              }
              _this.props.actions.setSubscription(reduxData);
              _this.setState({
                unSubscribing: false,
                activeSubscription: false
              }, () => alert('You are no longer subscribed to Sherpa, your current subscription period ends ' + moment(subscriptionEndsAt).format('MMMM D')))

            } else {
              _this.setState({
                unSubscribing: false
              }, () => alert('Something went wrong'))

            }
          }).catch((error) => {
            _this.setState({
              unSubscribing: false
            }, () => alert(error.response.data.error.message))
          })
        })
      } else {
        _this.setState({
          unSubscribing: false
        }, () => alert('Stripe customer already deleted'))
      }
    })
  }
  deleteAccount = () => {
    let _this = this;
    let userId = fire.auth().currentUser.uid;
    this.users.doc(userId).get().then(function(doc) {
      let stripeCustomerToken = doc.data().stripeCustomerToken;
      let userData = doc.data();
      if(stripeCustomerToken){
        fire.auth().currentUser.getIdToken().then(function(token) {
          let auth = 'Bearer ' + token;
          axios({
            method: 'post',
            url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/deleteCustomer',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': auth,
            },
            data: {
              stripeCustomerToken: stripeCustomerToken
            }
          }).then((response) => {
            if(response.data === 'success'){
              _this.deleteFromDatabase(userData);
            } else {
              alert('Something went wrong')
            }
          }).catch((error) => {
            if(error.response){
              alert(error.response.data.error.message)
            }
          })
        })
      } else {
        _this.deleteFromDatabase(userData)
      }
    })
  };
  deleteFromDatabase = (user) => {
    let _this = this;
    let advisorId = null;
    if(user.advisors){
      advisorId = user.advisors[0].id;
    }
    if(advisorId){
      this.users.doc(advisorId).get().then(function(doc) {
        let clients = doc.data().clients;
        clients.slice(0).forEach((client,i) => {
          if(client.id === user.id) {
            clients.splice(i, 1)
          }
        });
        _this.users.doc(advisorId).set({
          'clients': clients,
        }, {merge: true}).then(() => {
          _this.users.doc(user.id).delete();
        }).catch(() => {
          // console.log('writing error', error)
        })
      })
    }

    let password = window.prompt('Please enter your password');
    let fireUser = fire.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );
    fireUser.reauthenticateWithCredential(credential).then(function() {
      _this.users.doc(user.id).delete();
      fire.auth().currentUser.delete().then(function() {
        // console.log('deletetd')
      }).catch(function() {
        // An error happened.c
        // alert(error.message)
        // console.log('error', error)
      });
    }).catch(function(error) {
      // console.log('error', error)
      if(error.message){
        alert(error.message)
      }
    });

  };
  render() {
    let profilePhoto = this.props.account.profilePhotoUrl;
    let hasProfilePhoto = !!profilePhoto;
    const {isVisible} = this.props;
    const {email, name, unSubscribing, activeSubscription} = this.state;
    const changePhotoModal = this.state.showPhotoModal
      ? (<Modal onClose={this.handleCloseModal}>
          <UploadProfilePhotoModal isEditing={hasProfilePhoto} onClose={this.handleCloseModal}/>
        </Modal>) : null;
    return (
      <React.Fragment>
        <Box isVisible={isVisible}>
          <InnerHorizontal>
            <ImageBox onClick={this.handleShowModal}>
              {hasProfilePhoto
              ? <ProfileImage src={profilePhoto}/>
              : <ProfileImageAdd>+</ProfileImageAdd>}
            </ImageBox>
            <div>
              <Name>{name}</Name>
              <Email>{email}</Email>
            </div>
          </InnerHorizontal>
          {activeSubscription &&
          <div>
            {unSubscribing ?
              <TextButton className='logout' style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottom: '1px solid',
                borderBottomColor: this.props.theme.separator,
                paddingBottom: '10px'
              }}
              >
                <PropagateLoader
                  sizeUnit={"px"}
                  size={10}
                  loading={unSubscribing}
                  color="#66BB6A"
                />
              </TextButton>
              :
              <TextButton className='logout'
                          style={{
                            border: 'none',
                            color: this.props.theme.orange,
                            borderBottom: '1px solid',
                            borderBottomColor: this.props.theme.separator
                          }}
                          onClick={this.unsubscribe}>
                Unsubscribe
              </TextButton>
            }
          </div>
          }
          <TextButton className='logout'
                      style={{border: 'none', color: this.props.theme.errorColor, borderBottom: '1px solid', borderBottomColor: this.props.theme.separator}}
                      onClick={this.deleteAccount}>
            Delete Account
          </TextButton>
          <TextButton className='logout'
                      style={{border: 'none', color: this.props.theme.thirdColor}}
                      onClick={this.onLogout}>
            Logout
          </TextButton>
        </Box>
        {changePhotoModal}
      </React.Fragment>
    );
  }
}

function mapStateToProps({account}) {
  return { account };
}
function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(accountActions, dispatch) };
}

ProfileMenu.propTypes = {
  isVisible: PropTypes.bool,
  theme: PropTypes.object,
  onClose: PropTypes.func,
  actions: PropTypes.object,
  account: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTheme(ProfileMenu)));

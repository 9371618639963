import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import fire from '../config/fire';
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import {FlexSpacer} from '../css/styled_components/SharedComponents';
import AccountCard from "../components/AccountCard.js";
import {formatAccountTotal} from '../utils/accountsFormatting';
import BottomLogoSection from '../components/shared_components/ButtomLogoSection';
import {DownArrowIcon, HintIcon} from '../components/shared_components/Icons';
import moment from "moment/moment";

const TopTitlesContainer = styled.div`
    display: flex;
    margin-top: -180px;

  .account-card-top-spacer-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0 10%;

    @media (min-width: 839px) {
      padding: 0;
      width: 1025px;
    }
  }
`;

const AllAccountsTitle = styled.h1`
  color: #FFFFFF;	
  font-family: "NotoSans-Regular", serif;
  font-size: 2.5rem;	
  font-weight: 800;	
  line-height: 49px;

  .a-all-accounts-svg-arrow {
    height: 9px;
    width: 30px;
    margin: 0 0 4px 4px;
}
`;

const TopSummaryInfo = styled.div`
    text-align: right;

    p {
        color: rgba(255,255,255,0.8);	
        font-family: "NotoSans-Regular", serif;	
        font-size: 1rem;	
        margin: 8px 0 8px 0;
        line-height: 19px;
    }

    h3 {
        color: #fff;	
        font-family: "NotoSans", serif;	
        font-size: 1.8rem;	
        font-weight: bold;
        margin-top: 0;
        letter-spacing: 0.5px;	
        line-height: 19px;
    }
`;

const AccountCardsGrid = styled.div`
    display: flex;
    flex-wrap: wrap;

    .a-accounts-card-inner-grid {
      display: flex;
      flex-wrap: wrap;
      width: 1050px; 
    }
`;

const AddAccountButton = styled.button`
    padding: 18px;
    margin: 12px;
    width: 100%;
    height: 169px;
    position: relative;
    cursor: pointer;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
    border: none;

    @media (min-width: 768px) {
        width: 325px;	
}

    p {
      color: #7c7c7c;
      font-size: 4rem;
      font-weight: 200;
      margin: 0;
    }
`;
const HintText = styled.div`
  position: absolute;
  width: 250px;
  left: -230px;
  bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  display: none;
  background-color:#222529;
  color:#ffffff;
  font-size: 1rem;
  line-height:1.5;
`;
const HintButtonCustom = styled.button`
    background: white;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    &:hover + ${HintText} {
      display: block;
    }
`;

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      accountsById: {},
      updatingInstitutionsNumber: 0,
      updating: 0
    };
    this.users = fire.firestore().collection('users');
    this.institutions = fire.firestore().collection('institutions');
    this.payments = fire.firestore().collection('payments');
  }
  componentDidMount() {
    let _this = this;
    if(!this.props.profile.activeSubscription){
      if(this.props.profile.subscription_period_end && moment(this.props.profile.subscription_period_end).diff(moment()) > 0){
        //subscription still exists
      } else {
        _this.props.history.push('/verify-phone');
        return;
      }
    }
    this.props.getDataFromDatabase(false)
  }
  calculateAllAccountsSum = (accounts) => {
    let sum = 0;
    accounts.forEach((account) => {
      if(account.type !== 'loan' && account.type !== 'credit') {
        sum += account.balances.current
      }
      if(account.type === 'credit') {
        sum -= account.balances.current
      }
    });

    return sum;
  }

  renderTotalSum = (accounts) => {
    if (accounts && accounts.length === 0) {
      return null;
    }

    let totalSum = 0;
    accounts.forEach(item => {
      let itemAccounts = item.accounts ? item.accounts : [];
      itemAccounts.forEach((value) => {
        if(value.type !== 'loan' && value.type !== 'credit') {
          totalSum += value.balances.current
        }
        if(value.type === 'credit') {
          totalSum -= value.balances.current
        }
      });
    })

    return totalSum;
  };


  onClickSetState = (account) => {
    this.props.actions.setAccount(account);

    this.props.history.push('/account-expanded');
  };
  deleteAccount = (selectedAccount) => {
    let deleteConfirm = window.confirm("Are you sure you want to delete this account?");
    if(deleteConfirm){
      let _this = this;
      let userId = fire.auth().currentUser.uid;
      this.users.doc(userId).get().then(function(doc) {
        let plaidTokens = doc.data().plaidTokens;
        plaidTokens.slice(0).forEach((data,i) => {
          if(data.access_token === selectedAccount.access_token){
            plaidTokens.splice(i, 1)
          }
        })
        _this.users.doc(userId).set({
          'plaidTokens': plaidTokens,
        }, {merge: true}).then(() => {
          _this.deleteFromInstitutions(selectedAccount)
        }).catch(() => {
          // console.log('writing error', error)
        })
      })
    }
  };
  deleteFromInstitutions = (selectedAccount) => {
    let _this = this;
    let query = this.institutions.where("access_token", "==", selectedAccount.access_token);
    query.get().then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(function (doc) {
          _this.deleteFromPayments(doc.data().name);
          _this.institutions.doc(doc.id).delete()
        })
        return;
      }
      location.reload();
    })
  };
  deleteFromPayments = (institutionName) => {
    let _this = this;
    let userId = fire.auth().currentUser.uid;
    let query = this.payments.where("user_id", "==", userId);
    query.get().then(function (querySnapshot) {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(function (doc) {
          let recurringPayments = doc.data().recurring;
          recurringPayments.slice(0).forEach((payment) => {
            if (payment.institution === institutionName) {
              recurringPayments.splice(recurringPayments.indexOf(payment), 1)
            }
          });
          _this.payments.doc(doc.id).set({
            'recurring': recurringPayments,
          }, {merge: true}).then(() => {
            _this.props.history.push('/accounts');
          }).catch(() => {
            // console.log('writing error', error)
          })
        })
        return;
      }
      _this.props.history.push('/accounts');
    })
  };

  render(){
    const {accounts, updating} = this.props.accountsData

    return (
      <React.Fragment>
        <TopTitlesContainer>
        <FlexSpacer/>
          <div className="account-card-top-spacer-container">
            <div>
              <AllAccountsTitle>
                All Accounts {updating? '(updating...)': ''}
                <svg className="a-all-accounts-svg-arrow" viewBox="0 50 100 60">{DownArrowIcon}</svg>
              </AllAccountsTitle>
              <div>
                <div style={{position: 'relative'}}>
                  <HintButtonCustom>
                    {HintIcon}
                  </HintButtonCustom>
                  <HintText style={{left: '50px'}}>On this page you can view balances and transactions by account, or add a new one by clicking the + button</HintText>
                </div>
              </div>
            </div>
            <FlexSpacer/>
            <TopSummaryInfo>
              <p>Total Balance</p>
              <h3>{formatAccountTotal(this.renderTotalSum(accounts))}</h3>
            </TopSummaryInfo>
          </div>
          <FlexSpacer/>
        </TopTitlesContainer>

        <AccountCardsGrid>
        <FlexSpacer/>
            <div className="a-accounts-card-inner-grid">
            {accounts.map((item,i) =>
              <AccountCard key={i}
                onClickSetState={() => item.accounts ? this.onClickSetState(item) : null}
                isMultiple={item.accounts ? item.accounts.length > 1 : false}
                title={item.name}
                isRetrievingData={!item.accounts}
                accountTypes={item.accounts ? item.accounts : []}
                total={this.calculateAllAccountsSum(item.accounts ? item.accounts : [])}
                logo={item.accounts ? item.logo : null}
                deleteAccount={() => this.deleteAccount(item)}
                />
            )}
            <AddAccountButton onClick={() => this.props.history.push('/add-accounts')}>
                 <p>+</p>
            </AddAccountButton>
            </div>
          <FlexSpacer/>
        </AccountCardsGrid>

        <BottomLogoSection/>

      </React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions, dispatch) };
}
function mapStateToProps({account, institutions}){
  return {
    profile : account,
    accountsData: institutions
  };
}
Accounts.propTypes = {
  profile:PropTypes.object,
  history: PropTypes.object,
  account: PropTypes.object,
  actions: PropTypes.object,
  accounts: PropTypes.object,
  getDataFromDatabase: PropTypes.func,
  accountsData: PropTypes.object
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Accounts));


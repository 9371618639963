import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Input} from 'react-materialize'
const Container = styled.div`
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input:-webkit-autofill + label {
    // Insert your active label styles
    -webkit-transform: translateY(-14px) scale(0.8);
    transform: translateY(-14px) scale(0.8);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    top: 0rem !important;
    color: ${props=> props.haserror === 'false' ? props.theme.primaryColor : props.theme.errorColor} !important;
    margin-top: 0.4rem;
    background-color: white;
    padding: 0 4px;
    //background-color: red;
  }
  label{
    color: ${props => props.theme.inputPlaceholder} !important;
    font-family: "NotoSans-Regular", serif !important;
    line-height: initial !important;
    height: initial !important;
    font-size: ${props => props.large ? '1.1rem' : '1rem'} !important;
    left: ${props => props.large ? '0.95rem' : '0.75rem'} !important;
    top: ${props => props.large ? '0.35rem' : '0rem'} !important;
  }
  .active {
    top: 0rem !important;
    color: ${props=> props.haserror === 'false' ? props.theme.primaryColor : props.theme.errorColor} !important;
    margin-top: 0.4rem;
    background-color: white;
    padding: 0 4px;
  }
`;

const StyledInput = styled(Input)`
    width: 100% !important;
    border: ${props => props.haserror === 'false' ? '1px solid ' + props.theme.inputBorder : '1px solid ' + props.theme.errorColor} !important;
    border-radius: 2px !important;
    height: ${props => props.large ? '4rem' : '3rem'} !important;
    color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.42)' : 'black'} !important;
    font-family: "NotoSans-Regular", serif !important;
    padding: ${props => props.large ? '0.95rem' : '0.75rem'} !important;
    font-size: ${props => props.large ? '1.1rem' : '1rem'} !important;
    box-sizing: border-box !important;
    margin: 0 !important;
    box-shadow: none !important;
    &:focus{
      box-shadow: none !important;
      border-color: ${props=>props.haserror === 'false' ? props.theme.primaryColor : props.theme.errorColor} !important;
    }
   @media (max-width: 768px) {
      font-size: 16px !important;
   }
`;

class PrimaryInput extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }
  componentDidMount() {
    if (this.props.type == 'date') {
      $(this.containerRef).ready(() => {
        //fix flickering issue on Chrome 73+
        this.containerRef.current.addEventListener('click', e => e.stopPropagation());
      });
    }
  }
  render(){
    return (
      <Container
        ref={this.containerRef}
        haserror={this.props.haserror.toString()}
        large={this.props.large}
        style={this.props.style}>
        <StyledInput value={this.props.value}
                     label={this.props.label} onChange={this.props.onChange}
                     name={this.props.name} type={this.props.type}
                     haserror={this.props.haserror.toString()} validationtype={this.props.validationtype}
                     onBlur={this.props.onBlur} fieldname={this.props.fieldname}
                     autoComplete={this.props.autoComplete}
                     disabled={this.props.disabled}
                     ref={this.props.inputReference}
                     large={this.props.large}/>
      </Container>
    );
  }
}
PrimaryInput.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  haserror: PropTypes.string,
  validationtype: PropTypes.string,
  onBlur: PropTypes.func,
  fieldname: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.string,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  inputReference: PropTypes.func,
};

export default PrimaryInput;

// import nodemailer from 'nodemailer';
import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {accountActions} from '../actions/accountActions';
import fire from '../config/fire';
import moment from 'moment'
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom'
import {FlexSpacer} from '../css/styled_components/SharedComponents';

import BottomLogoSection from '../components/shared_components/ButtomLogoSection';
import {RefreshIcon, DownArrowIcon, HintIcon} from '../components/shared_components/Icons';
import { VictoryPie } from 'victory';
import placeholderImage from '../images/avatar.png'
import {getAdvisorProfilePhotoUrl} from "../data/Storage";
import axios from "axios/index";


const DashboardFlexContainer = styled.div`
  display: block;
  margin-top: -180px;
  @media (min-width: 839px) {
      display: flex;
    }
`;

const DashboardContainer = styled.div`
  padding: 0;
  width: 100%;
      @media (min-width: 839px) {
      width: 1025px;
    }
`;

const HeightSpacer = styled.div`
    height: 30px;
`;

const FlexCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const BankingCard = styled.div`
  border-radius: 2px;	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
  width: 100%;
  overflow: hidden;
  margin: 0;
  flex: 1;
  flex-basis: 500px;
  @media (min-width: 839px) {
    margin: 0 24px 0 0;
  }
`;

const BankingTopCardAmountContainer = styled.div`
  background-color: #FFFFFF;	
  padding: 24px 0 16px 0;
  .banking-top-card-title {
    color: #454B54;	
    font-family: "NotoSans-Regular";
    font-size: .9rem;
    margin: 0;
    font-weight: bold;
    text-align: center;
  }
  .banking-top-card-amount {
    color: #454B54;	
    font-family: "Noto Serif";	
    font-size: 2rem;	
    margin: 6px 0;
    text-align: center;
    font-weight: bold;	
    letter-spacing: 0.5px;
  }
  .banking-top-card-updated-container {
    text-align: center;
    display: flex;
    align-items: center;
  }
  .banking-top-card-updated-text {
    color: rgba(69,75,84,0.4);
    text-align: center;
    font-family: "NotoSans-Regular";
    margin:0 12px 0 0;
    display: inline;
    font-weight: 500;
    font-size: .9rem;
  }
  .banking-top-card-refresh-button {
    border: none;
    background: transparent;
    position: relative;
    z-index: 1;
  }
`;

const BankingBottomCardConatiner = styled.div`
  background-color: #F9F9FA;
  .banking-bottom-card-pie-container {
    display: flex;
  }
  .banking-bottom-card-pie-chart {
    width: 50%;
    margin-top: -35px;
  }
  .banking-bottom-card-amount {
    text-align: center;
    color: #454B54;	
    font-family: "Noto Serif";	
    font-size: 1.2rem;	
    margin: 0;
    font-weight: bold;
  }
  .banking-bottom-card-account {
    text-align: center;
    font-family: "NotoSans-Regular";
    color: rgba(69,75,84,0.4);	
    margin: 0;
    padding: 3px 0 19px 0;
    font-size: .9rem;
  }
  .banking-bottom-card-amount-spacer {
    height: 100px;
  }
`;

const BudgetCard = styled.div`
  border-radius: 2px;	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
  width: 100%;
  flex: 1;
  flex-basis: 500px;
`;

const BudgetCardConatiner = styled.div`
  background: linear-gradient(180deg, #1B1E22 0%, #454B54 100%);	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.2);
  display: block;
  height: 100%;
  .budget-card-projected-balance-container {
    display: flex;
    align-items: center;
    padding: 28px 0 0;
  }
  .budget-card-title-projected-balance-dropdown {
    border: 1px solid green;
  }
  .budget-card-title-projected-balance {
    color: #FFFFFF;	
    font-family: "NotoSans-Regular";
    margin: 0;
    font-size: .9rem;	
    text-align: center;
  }
  .budget-card-title-amount {
    color: #66BB6A;	
    margin: 18px 0 0;
    font-family: "Noto Serif Display";
    font-size: 2.3rem;	
    font-weight: 600;
    text-align: center;
  }
  .budget-card-pie-container {
    display: flex;
  }
  .budget-card-pie-chart {
    margin-top: -40px;
    width: 50%;
  }
`;

const BudgetBottomAmounts = styled.div`
  display: flex;
`;

const BudgetCardBottomAmountContainer = styled.div`
.budget-card-bottom-title {
  color: ${props => props.color};
  font-size: .9rem;
  margin: 0;
  font-family: "NotoSans-Regular";
  text-align: center;
}
.budget-card-bottom-amount {
  color: ${props => props.color};
  margin: 8px 0 30px 0;
  font-family: "Noto Serif";
  font-size: 1.4rem;	
  font-weight: bold;
  text-align: center;
}`;

const OvalDateDropdownContainer = styled.div`
  display: inline-block;
  position: relative; 
`;

const ProjectedBalanceDropdownList = styled.ul`
  display: none;
  width: 135px;
  position: absolute;
  z-index: 2;
  top: 16px;
  left: 10px;
  background-color: #2ab7a9;
  border-radius: 0 0 4px 4px;
  
  .dropdown-list-item {
    width: 100%;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    button {
      width: 100%;
      border: none;
      background-color: transparent; 
    }
    &:hover {
      background-color: rgba(255,255,255,0.4);
      color: white;
    }
  }
  ${({ showProjectedDropdown }) => showProjectedDropdown && `
  display: block;
  `}
`;

const OvalDateContainer = styled.button`
    display: inline-block;
    border: none;
    padding: 8px 16px;
    font-size: .8rem;
    font-family: "NotoSans-Regular";
    border-radius: 19px;	
    margin: 0 0 0 10px;
    color: white;
    background-color: rgba(255,255,255,0.12);
    width: 135px;
    ${({ showProjectedDropdown }) => showProjectedDropdown && `
    border-radius: 19px 19px 0 0;
    `}
`;

const DownArrowContainer = styled.svg`
    height: 7px;
    width: 30px;
    margin: 0;
`;

const ProfileCard = styled.div`
  border-radius: 2px;	
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.2);
  background: linear-gradient(180deg, #1B1E22 0%, #454B54 100%);
  margin: 30px 0 0;
  @media (min-width: 839px) {
      height: 238px;
      overflow: hidden;
    }
  .mobile-flex-grid {
    display: flex;
    @media (min-width: 839px) {
      display: block;
    }
  }
  .mobile-flex-spacer {
    flex-grow: 1;
    @media (min-width: 839px) {
    flex-grow: 0;
    }
  }
  .profile-card-title {
    font-size: 1rem;
    font-family: "NotoSans-Regular";	
    font-weight: 500;
    margin: 28px 0 0;
    text-align: center;
    padding: 20px 0;
    @media (min-width: 839px) {
      padding: 0;
    }
    color: rgba(255,255,255,0.3);
  }
  .profile-card-icon-flex-container {
    display: block;
    @media (min-width: 839px) {
      display: flex;
      align-items: center;
    }
  }
  .heightSpacer {height: 40px}
  .profile-card-icon-container {
    display: block;
  }
  .profile-card-image {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    margin: 20px 0;
    @media (min-width: 839px) {
      margin: 0 25px 0 50px;
    }
  }
  .profile-card-name {
    color: #FFF;	
    font-size: 1.8rem;	
    margin: 12px 0 6px 0;
    font-family: "NotoSans-Regular";	
    font-weight: 500;	
    text-align: center;
    @media (min-width: 839px) {
      text-align: left;
    }
  }
  .profile-card-name-title {
    color: rgba(255,255,255,0.6);
    margin: 0;
    font-family: "NotoSans-Regular";	
    font-size: 1rem;
    text-align: center;
    @media (min-width: 839px) {
      text-align: left;
    }
  }
  .profile-card-button-container {
    display: flex;
    align-items: center;
    margin-right: 0;
    padding: 0 0 20px;
    @media (min-width: 839px) {
      margin-right: 50px;
      padding: 0;
    }
  }
  .profile-card-button {
    border: 1px solid #66BB6A;	
    border-radius: 2px;
    padding: 12px 20px;
    color: #66BB6A;
    margin: 22px 5px 0;
    font-size: 1rem;	
    letter-spacing: 0.44px;
    width: 164px;
    text-align: center;
    height: 46px;
    background: transparent;
  }
  .profile-card-button-add-advisor {
    border: 1px solid #66BB6A;	
    border-radius: 2px;
    padding: 12px 20px;
    font-family: "NotoSans-Regular";
    font-weight: 600;
    color: #66BB6A;
    margin: 0 36px 0; 
    width: 164px;
    text-align: center;
    height: 50px;
    background: transparent;
  }
  .profile-card-button-add-advisor-error {
    color: red;
    margin: 0;
    padding-left: 36px;
    position: absolute;
    font-weight: 600;
    font-family: "NotoSans-Regular";
    font-size: .8rem;
  }
`;

const ProfileCardInput = styled.div`
  	border-radius: 2px;	
    background-color: #F9F9FA;
    padding: 0 14px;
    display: flex;
    align-items: center;
    .table-label {
      font-size: 1.2rem;
      font-weight: 900; 
      padding: 0 12px 0 0;
      color: rgba(69,75,84,0.3);	
      font-family: "NotoSans-Regular";
    }
    .table-input {
      color: #454B54;	
      font-family: "NotoSans-Regular";
      font-size: 1.4rem;
      text-align: right;
      margin: 0 !important;
      border: none !important;
    }
     @media (max-width: 839px) {
        margin: 10px 0
     }  
`;

const EmailSentMessage = styled.div`
    width: 100%;
    height: 235px;
    display: flex;
    align-items: center;

    .email-sent-display-text {
      color: white;	
      font-family: "NotoSans-Regular";
      font-size: 1rem;

      text-align: center;
    }
`;
const LastUpdateContainer = styled.div`
  //@media (max-width: 839px) {
  //  display: none;
  //}
`;
const HintText = styled.div`
  position: absolute;
  width: 250px;
  left: -230px;
  bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  display: none;
  background-color:#222529;
  color:#ffffff;
  font-size: 1rem;
  line-height:1.5;
`;
const HintButtonCustom = styled.button`
    background: white;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 50%;
    padding: 5px;
    &:hover + ${HintText} {
      display: block;
    }
`;
const ConnectToAdvisorContainer = styled.div`
  display: flex !important;
  align-items: center;
  @media (max-width: 839px) {
      flex-direction: column;
  }
`;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionActive: '',
      subscriptionType: '',
      bankingAccountAmount: 0,
      bankingAccountTitle: '',
      showProjectedDropdown: false,
      profileAdvisorInputError: false,
      showProfileAdvisorEmailSent: false,
      profileAdvisorEmail: '',
      userData: {},
      advisorData: null,
      accounts: [],
      recurring: [],
      lastUpdated: null,
      dropDownData: [
        // {
        //   label: 'Today',
        //   end: true,
        //   increment: 'day',
        //   value: 1
        // },
        {
          label: 'In One Week',
          end: false,
          increment: 'week',
          value: 1,
        },
        {
          label: 'In Two Weeks',
          end: false,
          increment: 'week',
          value: 2,
        },
        {
          label: 'End of Month',
          end: true,
          increment: 'month',
          value: 1
        }
      ],
      dropDownSelected: {
        label: 'In One Week',
        end: false,
        increment: 'week',
        value: 1
      },
      advisorProfilePicture: null

    };
    this.institutions = fire.firestore().collection('institutions')
  }

  componentDidMount() {
    let _this = this;
    if(!this.props.profile.activeSubscription){
      if(this.props.profile.subscription_period_end && moment(this.props.profile.subscription_period_end).diff(moment()) > 0){
        //subscription still exists
      } else {
        _this.props.history.push('/verify-phone');
        return;
      }
    }
    // console.log('this porps', this.props.profile)
    this.getInitialData();
  }

  getInitialData = () => {
    let _this = this;

    fire.firestore().collection('users').doc(fire.auth().currentUser.uid).get().then(function(doc){
      _this.setState({
        userData: doc.data(),
        subscriptionActive: doc.data().activeSubscription,
        subscriptionType:doc.data().activePlan
      })
    });

    fire.firestore().collection('users').get().then(function(querySnapshot) {
      let currentUserAdvisorData = null;
      querySnapshot.forEach(function(doc) {
        const currentUserId = fire.auth().currentUser.uid;
        if (currentUserId === doc.data().id) {
          currentUserAdvisorData = doc.data().advisors ? doc.data().advisors[0] : null
          if(currentUserAdvisorData) {
            getAdvisorProfilePhotoUrl(currentUserAdvisorData.id).then((url) => {
              return url;
            }, () => {
              return null;
            }).then((profilePhotoUrl) => {
              _this.setState({
                  advisorProfilePicture: profilePhotoUrl
              })
            })
          }
        }
      });

      _this.setState({
        advisorData: currentUserAdvisorData ? currentUserAdvisorData : null
      })
    });


    let query = fire.firestore().collection('payments').where("user_id", "==", fire.auth().currentUser.uid);
    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        const data = doc.data();

        _this.setState({
          recurring: data.recurring
        })
      });
    });

    this.institutions.where("user_id", "==", fire.auth().currentUser.uid).get().then(function(querySnapshot) {
      let lastUpdated = ''
      let institutionsData = [];
      querySnapshot.forEach(function (doc) {
        institutionsData.push(doc.data());
        if(doc.data().lastUpdated){
          lastUpdated = doc.data().lastUpdated;
        }
      });


      const getAllAccounts = (institutions) => {
        if (institutions.length >= 1) {

          const newArrays = institutions.map((inst) => {
            let bankName = inst.name;
            if(inst.accounts) {
              return inst.accounts.map((account) => {
                return {...account, bank_name: bankName}
              });
            }
          });
          return [].concat.apply([], newArrays);

        } else {
          return institutions;
        }
      }
      const updatedInstitutionsData = getAllAccounts(institutionsData);

      _this.setState({
        accounts: updatedInstitutionsData,
        lastUpdated: lastUpdated
      })
    });
  }

  onRefreshButton = () => {
    this.props.getUpdatedTransactions(this.getInitialData);
  }

  logout = () => {
    fire.auth().signOut().then(() => {
      this.props.history.push('/login');
    })
  };

  formatAmountToUsd = (amount) => {
    return new Intl.NumberFormat().format(Math.floor(amount));
  }

  profileCardInputHandleChange = (event) => {
    this.setState({profileAdvisorEmail: event.target.value});
  }

  changeProjectedDate = (item) => {

    this.setState({
      dropDownSelected: item
    })
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(String(email).toLowerCase());
  }

  sendAdvisorEmail = () => {
    const {profileAdvisorEmail} = this.state;
    if (this.validateEmail(profileAdvisorEmail)) {
      this.setState({profileAdvisorInputError : false, showProfileAdvisorEmailSent: true});
      this.sendAdvisorEmailFromServer(profileAdvisorEmail);
    }
    else {
      this.setState({profileAdvisorInputError : true});
    }
  }
  sendAdvisorEmailFromServer = (email) => {
    fire.auth().currentUser.getIdToken().then(function(token) {
      let auth = 'Bearer ' + token;
      axios({
        method: 'post',
        url: 'https://us-central1-sherpa-98bf5.cloudfunctions.net/api/send-advisor-email',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': auth,
        },
        data: {
          email: email
        }
      }).then(() => {
        // console.log('response', response)
      }).catch(() => {
        // console.log('error', error.response)
      })
    })
  }
  projectedBalanceDropdownHandler = () => {
    this.setState(prevState => {
      return ({
        showProjectedDropdown: !prevState.showProjectedDropdown
      })
    });
  };

  bankingReduceTotal = (accounts) => {
    return accounts.reduce((accum, account) => {
      if(account && account.type !== 'loan' && account.type !== 'credit') {
        return accum += account.balances.current;
      }
      else if(account && account.type === 'credit') {
        return accum -= account.balances.current;
      } else {
        return accum;
      }
    }, 0);
  }

  get getBankingTotal() {
    const { accounts } = this.state;
    if (!accounts) {
      return 0;
    }

    return this.bankingReduceTotal(accounts);
  }

  get findAccountsData() {
    const { accounts } = this.state;

    if (!accounts) {
      return null;
    }


    const sortAccounts = (a, b) => {
      return b.balances.current - a.balances.current;
    }

    const accountsSorted = accounts.sort(sortAccounts).filter((account) => {
      if(account) {
        return account.type !== "loan" && account.subtype !== "credit card";
      }
    });

    const fourAccounts = accountsSorted.slice(0, 4);
    const totalAmmount = this.bankingReduceTotal(fourAccounts);

    const makeData = fourAccounts.map(account => {

      const bankInfoText = `${account.bank_name} (${account.name} ...${account.mask})`

      return ({x: bankInfoText,
        y: ((account.balances.current + 10 * 140) / totalAmmount),
        label: account.balances.current
      })
    });

    return makeData;
  }


  get transactionTimeLimit() {
    let timeLimit = '';
    if(this.state.dropDownSelected.end) {
      timeLimit = moment().endOf(this.state.dropDownSelected.increment)
    } else {
      timeLimit = moment().add(this.state.dropDownSelected.value, this.state.dropDownSelected.increment).endOf('day');
    }
    return timeLimit
  }

  budgetTransactionReducerFunc = (type) => {
    const { accounts, recurring } = this.state;
    if (!accounts || !recurring || accounts.length === 0) {
      return null;
    }
    const timeLimit = this.transactionTimeLimit;

    const filterLogic = (amount) => {
      if (type === 'spendingReducer') {
        if (amount < 0) {
          return true;
        } else {
          return false;
        }
      }
      else if (type === 'incomeReducer') {
        if (amount > 0) {
          return true;
        } else {
          return false;
        }
      }
      else {
        return false
      }
    }

    // const totalTranscations = Math.abs(accounts.reduce((accum, account) => {
    //   if (account.transactions.length === 0) {
    //     return accum;
    //   } else {
    //     return accum + account.transactions.reduce((accumulator, transaction) => {
    //       if(moment(transaction.date).isAfter(moment().startOf('month')) && moment(transaction.date).isBefore(timeLimit) && !filterLogic(transaction.amount)){
    //         return accumulator + transaction.amount;
    //       } else {
    //         return accumulator;
    //       }
    //     },0);
    //   }
    // }, 0));
    const recurringBillSum = recurring.reduce((accum, bill) => {
      if(moment(bill.next_installment).isBefore(timeLimit) && bill.scheduled && !filterLogic(bill.amount) && !bill.transfer){
        return accum + bill.amount;
      } else {
        return accum;
      }
    }, 0)

    if (type === 'spendingReducer') {
      return recurringBillSum;
    } else if (type === 'incomeReducer') {
      return Math.abs(-recurringBillSum);
    }
  }

  get recurringSumTotal() {
    const { recurring } = this.state;
    if (!recurring) {
      return null;
    }

    const timeLimit = this.transactionTimeLimit;


    return recurring.reduce((accum, bill) => {
      if(moment(bill.next_installment).isBefore(timeLimit) && bill.scheduled && moment(bill.next_installment).isAfter(moment().subtract(1,'days'))){
        if(bill.transfer) {
          if(bill.fromAccount.includes('Other') && !bill.toAccount.includes('Other')){
            return accum - parseInt(bill.amount);
          }
          if(bill.toAccount.includes('Other') && !bill.fromAccount.includes('Other')){
            return accum + parseInt(bill.amount);
          }
          return accum;
        } else{
          return accum + parseInt(bill.amount);
        }
      } else {
        return accum;
      }
    }, 0)
  }

  get getbudgetTotalAmount() {
    return this.getBankingTotal - this.recurringSumTotal;
  }

  get budgetSpendingValue() {
    const { accounts } = this.state;
    if (!accounts || accounts.length === 0) {
      return 0;
    }

    return this.budgetTransactionReducerFunc('spendingReducer');
  }

  get budgetIncomeValue() {
    const { accounts } = this.state;
    if (!accounts || accounts.length === 0) {
      return 0;
    }

    return this.budgetTransactionReducerFunc('incomeReducer');
  }

  get getBudgetChartData() {
    const total = Math.abs(this.budgetSpendingValue) + this.budgetIncomeValue;
    const spending = Math.abs(this.budgetSpendingValue) * 100 / total;
    const income = this.budgetIncomeValue * 100 / total;

    return [{x: "spending", y: spending}, {x: "income", y: income}];
  }

  render(){
    const { advisorData, accounts, advisorProfilePicture } = this.state;

    return (
      <DashboardFlexContainer>
        <FlexSpacer/>
        <DashboardContainer>

          <ProfileCard>
            {advisorData ?
              <React.Fragment>
                <p className="profile-card-title">
                  Your Financial Advisor
                </p>
                <div className="profile-card-icon-flex-container">
                  <div className="mobile-flex-grid">
                    <div className="mobile-flex-spacer"/>
                    <img className="profile-card-image" src={advisorProfilePicture === null ? placeholderImage : advisorProfilePicture}/>
                    <div className="mobile-flex-spacer"/>
                  </div>
                  <div>
                    <p className="profile-card-name">
                      {advisorData.firstName + ' ' + advisorData.lastName}
                    </p>
                    <p className="profile-card-name-title">
                      {advisorData.description}
                    </p>
                  </div>
                  <FlexSpacer/>
                  <div className="profile-card-button-container">
                    {advisorData.phoneNumber ?
                      <a  href={`tel:${advisorData.phoneNumber}`} className="profile-card-button">
                        {advisorData.phoneNumber}
                      </a>
                      : null}
                    {advisorData.email ?
                      <a className="profile-card-button" href={`mailto:${advisorData.email}`}>
                        Message
                      </a>
                      :
                      null
                    }
                  </div>
                </div>
              </React.Fragment>
              :
              <div>
                <React.Fragment>
                  {this.state.showProfileAdvisorEmailSent ?
                    <EmailSentMessage>
                    <FlexSpacer/>
                      <p className="email-sent-display-text">
                        Your Email Was Sent!
                      </p>
                    <FlexSpacer/>
                    </EmailSentMessage>
                  :
                <React.Fragment>
                  <p className="profile-card-title">
                    Connect to your Financial Advisor
                  </p>
                  <div className="heightSpacer"/>
                  <ConnectToAdvisorContainer className="profile-card-icon-flex-container">
                    <FlexSpacer/>
                    <div>
                      <div style={{position: 'relative'}}>
                        <HintButtonCustom style={{margin: '0 30px'}}>
                          {HintIcon}
                        </HintButtonCustom>
                        <HintText style={{left: '50px'}}>Invite your financial advisor to Sherpa by entering their email address</HintText>
                      </div>
                    </div>
                    <ProfileCardInput>
                      <label className="table-label">Email</label>
                      <input className="table-input" type='text' value={this.state.profileAdvisorEmail} onChange={this.profileCardInputHandleChange}/>
                    </ProfileCardInput>
                    <div>
                      <button onClick={this.sendAdvisorEmail} className="profile-card-button-add-advisor">
                        Submit
                      </button>
                      {this.state.profileAdvisorInputError ?
                      <p className="profile-card-button-add-advisor-error">
                      Please choose a valid Email
                      </p> :
                      null
                      }
                    </div>
                    <FlexSpacer/>
                  </ConnectToAdvisorContainer>
                </React.Fragment>
                    }
                </React.Fragment>
              </div>
            }
          </ProfileCard>

          <HeightSpacer/>

          <FlexCardContainer>
            <BankingCard>
              <BankingTopCardAmountContainer>
                <h2 className="banking-top-card-title">
                  Banking
                </h2>
                <h2 className="banking-top-card-amount">
                  ${accounts.length === 0 ? 0 : this.formatAmountToUsd(this.getBankingTotal)}
                </h2>
                <div className="banking-top-card-updated-container">
                    <FlexSpacer/>
                    {moment(this.state.lastUpdated).fromNow() !== 'Invalid date' &&
                      <LastUpdateContainer>
                        {this.state.lastUpdated ?
                          <React.Fragment>
                            <p className="banking-top-card-updated-text">
                              Updated {moment(new Date(this.state.lastUpdated)).fromNow()}
                            </p>
                            <button className="banking-top-card-refresh-button" onClick={this.onRefreshButton}>
                              {RefreshIcon}
                            </button>
                          </React.Fragment>
                          : null}
                      </LastUpdateContainer>
                    }
                    <FlexSpacer/>
                </div>
              </BankingTopCardAmountContainer>

              <BankingBottomCardConatiner>


                <div className="banking-bottom-card-pie-container">
                  <FlexSpacer/>
                  <div className="banking-bottom-card-pie-chart">

                    <svg style={{ height: 0, width: 0 }}>
                      <defs>
                        <linearGradient id="greenBackgroundGradientXL" gradientTransform="rotate(180)">
                          <stop offset="0%" stopColor="#9FDFA3"/>
                          <stop offset="100%" stopColor="#66BB6A"/>
                        </linearGradient>
                        <filter id="dropshadow" height="130%">
                          <feGaussianBlur in="SourceAlpha" stdDeviation="5"/>
                          <feOffset dx="2" dy="2" result="offsetblur"/>
                          <feComponentTransfer>
                            <feFuncA type="linear" slope="2"/>
                          </feComponentTransfer>
                          <feMerge>
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/>
                          </feMerge>
                        </filter>
                      </defs>
                    </svg>
                    <VictoryPie
                      style={{
                        labels: {
                          display: 'none'
                        },
                        data: {
                          filter: 'none',
                          // fill: ({ y }) =>
                          //   y > 30 ? 'url(#greenBackgroundGradientXL)'
                          //   : y > 20 ? '#6DC871'
                          //   : y > 10 ? '#58A15C'
                          //   : '#437B46'
                        }
                      }}
                      events={[{
                        target: "data",
                        eventHandlers: {
                          onMouseOver: () => {

                            return {
                              target: "data",
                              mutation: (props) => {
                                this.setState({bankingAccountAmount: props.slice.data.label, bankingAccountTitle: props.slice.data.x});

                                return ({ style: {...props.style, filter: "url(#dropshadow)" } } )
                              }
                            };
                          },
                          onMouseOut: () => {
                            this.setState({bankingAccountAmount: null, bankingAccountTitle: null});

                            return {
                              target: "data",
                              mutation: (props) => ({ style: {...props.style, filter: "none" } } )
                            };
                          }
                        }
                      }]}
                      data={this.findAccountsData}
                      colorScale='green'
                    />
                  </div>
                  <FlexSpacer/>
                </div>

                {this.state.bankingAccountTitle ?
                  <React.Fragment>
                    <p className="banking-bottom-card-amount">
                      ${accounts.length === 0 ? 0 : this.formatAmountToUsd(this.state.bankingAccountAmount)}
                    </p>
                    <p className="banking-bottom-card-account">
                      {this.state.bankingAccountTitle}
                    </p>
                  </React.Fragment>
                  :
                  <div className="banking-bottom-card-amount-spacer"/>
                }

              </BankingBottomCardConatiner>

            </BankingCard>

            <BudgetCard>
              <BudgetCardConatiner>
                <div className="budget-card-projected-balance-container">
                  <FlexSpacer/>
                  <p className="budget-card-title-projected-balance">
                    Projected Balance
                  </p>
                  <OvalDateDropdownContainer onClick={this.projectedBalanceDropdownHandler}>
                    <OvalDateContainer showProjectedDropdown={this.state.showProjectedDropdown}>
                      {this.state.dropDownSelected.label}
                      <DownArrowContainer viewBox="0 50 100 60">{DownArrowIcon}</DownArrowContainer>
                    </OvalDateContainer>
                    <ProjectedBalanceDropdownList showProjectedDropdown={this.state.showProjectedDropdown}>
                      {this.state.dropDownData.map((item,i) => {
                        if(item.label !== this.state.dropDownSelected.label)
                          return <li className="dropdown-list-item" key={i} onClick={() => this.changeProjectedDate(item)}><button>{item.label}</button></li>
                      })}
                    </ProjectedBalanceDropdownList>
                  </OvalDateDropdownContainer>
                  <div>
                    <div style={{position: 'relative'}}>
                      <HintButtonCustom style={{margin: '0 10px'}}>
                        {HintIcon}
                      </HintButtonCustom>
                      <HintText>This shows how much money you will have left in all of your accounts after all of your monthly bills and income, as well as a breakdown of how much money you have earned and spent in the selected time period.</HintText>
                    </div>
                  </div>
                  <FlexSpacer/>
                </div>

                <h2 className="budget-card-title-amount">
                  ${accounts.length === 0 ? 0 : this.formatAmountToUsd(this.getbudgetTotalAmount)}
                </h2>

                <div className="budget-card-pie-container">
                  <FlexSpacer/>
                  <div className="budget-card-pie-chart">
                    <svg style={{ height: 0, width: 0 }}>
                      <defs>
                        <linearGradient id="blueBackgroundGradient" gradientTransform="rotate(135)">
                          <stop offset="0%" stopColor="#8739E5"/>
                          <stop offset="100%" stopColor="#5496FF"/>
                        </linearGradient>
                        <linearGradient id="redBackgroundGradient" gradientTransform="rotate(135)">
                          <stop offset="0%" stopColor="#F5317F"/>
                          <stop offset="100%" stopColor="#FF7C6E"/>
                        </linearGradient>
                      </defs>
                    </svg>
                    <VictoryPie
                      style={{
                        labels: {
                          display: 'none'
                        },
                        data: {
                          filter: 'none',
                          fill: ({ x }) =>
                            x === "income" ? 'url(#blueBackgroundGradient)'
                              : 'url(#redBackgroundGradient)'
                        }
                      }}
                      events={[{
                        target: "data",
                      }]}
                      data={this.getBudgetChartData}
                    />
                  </div>
                  <FlexSpacer/>
                </div>
                <BudgetBottomAmounts>
                  <FlexSpacer/>
                  <BudgetCardBottomAmountContainer color={'#FF7C6E'}>
                    <p className="budget-card-bottom-title">
                      Spent
                    </p>
                    <p className="budget-card-bottom-amount">
                      ${accounts.length === 0 ? 0 : this.formatAmountToUsd(Math.abs(this.budgetSpendingValue))}
                    </p>
                  </BudgetCardBottomAmountContainer>
                  <FlexSpacer/>
                  <BudgetCardBottomAmountContainer color={'#5496FF'}>
                    <p className="budget-card-bottom-title">
                      Income
                    </p>
                    <p className="budget-card-bottom-amount">
                      ${this.formatAmountToUsd(this.budgetIncomeValue)}
                    </p>
                  </BudgetCardBottomAmountContainer>
                  <FlexSpacer/>
                </BudgetBottomAmounts>
              </BudgetCardConatiner>
            </BudgetCard>
          </FlexCardContainer>
          {/* User Info
        <div>
          First Name: {this.props.profile.firstName}
        </div>
        <div>
          Last Name: {this.props.profile.lastName}
        </div>
        <div>
          Email: {this.props.profile.email}
        </div>
        <div>
          Subscription active: {this.state.subscriptionActive ? 'true' : 'false'}
        </div>
        <div>
          Subscription type: {this.state.subscriptionType}
        </div>
        <button onClick={this.logout}>Log Out</button> */}
          <BottomLogoSection/>

        </DashboardContainer>
        <FlexSpacer/>
      </DashboardFlexContainer>
    );
  }
}
function mapDispatchToProps(dispatch){
  return { actions: bindActionCreators(accountActions,dispatch) };
}
function mapStateToProps({account}){
  return { profile : account};
}
Dashboard.propTypes = {
  profile:PropTypes.object,
  history: PropTypes.object,
  getUpdatedTransactions: PropTypes.func
};
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Dashboard));

import firebase from 'firebase';
import fire from '../config/fire';

export { getProfilePhotoUrl, uploadProfilePhoto, getAdvisorProfilePhotoUrl }

const getProfilePhotoUrl = () => {
  let storage = fire.storage();
  let userId = fire.auth().currentUser.uid;
  return new Promise((success, error) => {
    storage.ref('user/' + userId).child('/profilePhoto.jpg').getDownloadURL().then((url) => {
      success(url);
    }).catch((err) => {
      error(err);
    });
  });
}

const uploadProfilePhoto = (file, progressHandler, errorHandler, successHandler) => {
  let storage = fire.storage();
  let userId = fire.auth().currentUser.uid;
  let uploadTask = storage.ref('user/' + userId).child('/profilePhoto.jpg').put(file, { contentType: 'image/jpeg' });
  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot) => {
      progressHandler((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    }, (error) => {
      errorHandler(error);
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((url) => {
        successHandler(url);
      });
    }
  );
}
const getAdvisorProfilePhotoUrl = (id) => {
  let storage = fire.storage();
  let userId = id;
  return new Promise((success, error) => {
    storage.ref('user/' + userId).child('/profilePhoto.jpg').getDownloadURL().then((url) => {
      success(url);
    }).catch((err) => {
      error(err);
    });
  });
}

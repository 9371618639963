import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {DownArrowIcon} from '../components/shared_components/Icons';
import {NavProfileButton, NavProfileImage, NavProfileImageAdd, ArrowButton} from '../css/styled_components/NavBarComponents';
import '../css/navbar.css';

const ProfileIcon = (props) => {
  const { onClick } = props;
  const hasProfileImage = !!props.account.profilePhotoUrl;
  const profilePhotoUrl = props.account.profilePhotoUrl;
  return (
    <NavProfileButton onClick={onClick}>
      {hasProfileImage 
        ? <NavProfileImage src={profilePhotoUrl}/>
        : <NavProfileImageAdd>+</NavProfileImageAdd>
      }
      <ArrowButton>
        <svg className="nb-profile-svg" viewBox="0 50 100 60">{DownArrowIcon}</svg>
      </ArrowButton>
    </NavProfileButton>
  );
}

function mapStateToProps({account}) {
  return { account };
}

ProfileIcon.propTypes = {
  onClick: PropTypes.func,
  account: PropTypes.object,
};

export default connect(mapStateToProps)(withRouter(ProfileIcon));

import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'react-materialize';
import styled from 'styled-components';
const Container = styled.div`
  margin: 1rem 0;
  label{
    font-size: 11px !important;
    font-weight: 600 !important;
    padding-left: 25px !important;
    font-family: 'NotoSans-Regular',serif !important;
    color: ${props => props.theme.secondaryColor} !important;
    height: 20px !important;
    line-height: 20px !important;
  }
  .filled-in:checked + label:after {
    background-color: ${props=>props.disabled ? '#949494' : props.theme.primaryColor} !important;
    border-color: ${props=>props.disabled ? '#949494' : props.theme.primaryColor} !important;
  }
  .filled-in + label:after {
    border-color: ${props=>props.disabled ? '#949494' : props.theme.secondaryColor} !important;
  }
`;
class PrimaryCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      checked: 0
    }
  }
  handleCheck = () => {
    this.setState({
      checked: !this.state.checked
    })
  };
  render(){
    return (
      <Container style={this.props.style} disabled={this.props.disabled}>
        <Input type='checkbox' checked={this.props.value} label={this.props.label} className='filled-in' onClick={this.props.onClick} disabled={this.props.disabled}/>
      </Container>
    );
  }
}
PrimaryCheckbox.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool
};

export default PrimaryCheckbox;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Input} from 'react-materialize'
import usdImg from '../images/currency/usd.svg'
const Container = styled.div`
  position: relative;
  input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  label{
    color: ${props => props.theme.inputPlaceholder} !important;
    font-family: "NotoSans-Regular", serif !important;
    line-height: initial !important;
    height: initial !important;
    font-size: ${props => props.large ? '1.1rem' : '1rem'} !important;
    left: ${props => props.large ? '0.95rem' : '0.75rem'} !important;
    top: ${props => props.large ? '0.35rem' : '0rem'} !important;
  }
  .active {
    top: 0rem !important;
    color: ${props=> props.haserror === 'false' ? props.theme.primaryColor : props.theme.errorColor} !important;
    margin-top: 0.4rem;
    background-color: white;
    padding: 0 4px;
  }
`;

const StyledInput = styled(Input)`
    width: 100% !important;
    border: ${props => props.haserror === 'false' ? '1px solid ' + props.theme.inputBorder : '1px solid ' + props.theme.errorColor} !important;
    border-radius: 2px !important;
    height: ${props => props.large ? '4rem' : '3rem'} !important;
    color: black !important;
    font-family: "NotoSans-Regular", serif !important;
    padding: ${props => props.large ? '0.95rem' : '0.75rem'} !important;
    font-size: ${props => props.large ? '1.1rem' : '1rem'} !important;
    box-sizing: border-box !important;
    margin: 0 !important;
    box-shadow: none !important;
    text-align: right;
    &:focus{
      box-shadow: none !important;
      border-color: ${props=>props.haserror === 'false' ? props.theme.primaryColor : props.theme.errorColor} !important;
    }
   @media (max-width: 768px) {
      font-size: 16px !important;
   }
`;

const CurrencyContainer = styled.div`
  display: ${props => props.hidden ? 'none' : 'block'}
  height: 1rem;
  position: absolute !important;
  left: ${props => props.large ? '0.95rem' : '0.75rem'} !important;
  bottom: ${props => props.large ? '1.5rem' : '1rem'} !important;
`;

class PrimaryAmountInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : '0.00'
    }
  }
  handleInputChange = (e) => {
    if (this.isValidAmount(e.target.value)) {
      this.setState({
        value: e.target.value
      });
      this.props.onChange(e);
    }
  }
  isValidAmount = (s) => {
    var rgx = /^-?(?:[0-9]*(?:\.[0-9]*)?|\.[0-9]*)$/;
    return s.match(rgx);
  }
  render(){
    let amountIsEmpty = !this.state.value;
    return (
      <Container
        haserror={this.props.haserror.toString()}
        large={this.props.large}
        style={this.props.style}>
        <StyledInput value={this.state.value}
                     label={this.props.label} onChange={this.handleInputChange}
                     name={this.props.name}
                     haserror={this.props.haserror.toString()} validationtype='number'
                     onBlur={this.props.onBlur} fieldname={this.props.fieldname}
                     autoComplete={this.props.autoComplete}
                     large={this.props.large}/>
        <CurrencyContainer hidden={amountIsEmpty}><img src={usdImg}/></CurrencyContainer>
      </Container>
    );
  }
}
PrimaryAmountInput.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node,
  label: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  haserror: PropTypes.string,
  onBlur: PropTypes.func,
  fieldname: PropTypes.string,
  autoComplete: PropTypes.string,
  value: PropTypes.number,
  large: PropTypes.bool
};

export default PrimaryAmountInput;

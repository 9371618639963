import { InstitutionsDetails } from '../constants/InstitutionsDetails';

export const institutionsActions = {
  setInitialData,
  setData,
  setUpdateState
};
function setInitialData() {
  return {type: InstitutionsDetails.SET_INITIAL_DATA}
}
function setData(data){
  return {type : InstitutionsDetails.SET_DATA, data};
}
function setUpdateState(data) {
  return {type : InstitutionsDetails.SET_UPDATE_STATE, data};
}

import {InstitutionsDetails} from "../constants/InstitutionsDetails";


export function institutions(state = {}, action){
  switch (action.type){
    case InstitutionsDetails.SET_INITIAL_DATA:
      return Object.assign({}, state, {
        accounts: [],
        updating: 0
      });
    case InstitutionsDetails.SET_DATA:
      return Object.assign({}, state, {
        accounts: action.data.accounts,
        updating:action.data.updating,
      });
    case InstitutionsDetails.SET_UPDATE_STATE:
      return {
        ...state,
        updating: action.data.updating
      };
    default :
      return state;
  }
}


import React from 'react';
import PropTypes from 'prop-types';
import { PopupBoxShortTop, PopupTitle, PopupErrorContainer } from '../../css/styled_components/PopupComponents.js';
import PrimaryButton from '../PrimaryButton';
import TextButton from '../TextButton';
import PrimaryAmountInput from '../PrimaryAmountInput';
import PrimaryInput from '../PrimaryInput';
import CategoriesDropdown from '../CategoriesDropwdown';
import ErrorText from '../ErrorText';
import FormValidator from '../FormValidator';
import ButtonLoader from  '../ButtonLoader';
import moment from 'moment';
class BudgetMisc extends React.Component {
  constructor(props){
    super(props)
    let allCategories = [
      {value: '1', icon: null , name: 'Cafe, Bars and Restaurants', categories: ['Cafe', 'Bar', 'Restaurants', 'Coffee Shop']},
      {value: '2', icon: null, name: 'Food & Drink', categories: ['Food and Drink']},
      {value: '3', icon: null, name: 'Healthcare', categories: ['Healthcare']},
      {value: '4', icon: null, name: 'Entertainment', categories: ['Entertainment']},
      {value: '5', icon: null, name: 'Golf', categories: ['Golf']},
      {value: '6', icon: null, name: 'Automotive', categories: ['Automotive']},
      {value: '7', icon: null, name: 'Media', categories: ['Media']},
      {value: '8', icon: null, name: 'Financial', categories: ['Financial']},
      {value: '9', icon: null, name: 'Clothing & Accessories', categories: ['Clothing and Accessories']},
      {value: '10', icon: null, name: 'Computers & Electronics', categories: ['Computers and Electronics']},
      {value: '11', icon: null, name: 'Video Games', categories: ['Video Games']},
      {value: '12', icon: null, name: 'Convenience Stores', categories: ['Convenience Stores']},
      {value: '13', icon: null, name: 'Digital Purchase', categories: ['Digital Purchase']},
      {value: '14', icon: null, name: 'Food & Beverage Store', categories: ['Food and Beverage Store']},
      {value: '14', icon: null, name: 'Beer Wine & Spirits', categories: ['Beer Wine and Spirits']},
      {value: '15', icon: null, name: 'Supermarkets & Groceries', categories: ['Supermarkets and Groceries']},
      {value: '16', icon: null, name: 'Gas Stations', categories: ['Gas Stations']},
      {value: '17', icon: null, name: 'Tolls & Fees', categories: ['Tolls and Fees']},
      {value: '18', icon: null, name: 'Ride Share', categories: ['Ride Share']},
      {value: '19', icon: null, name: 'Car Service', categories: ['Car Service']},
      {value: '20', icon: null, name: 'Taxi', categories: ['Taxi']},
    ];
    let categories = [];
    allCategories.forEach((potentialCategory) => {
      let added = false;
      this.props.alreadyAddedCategories.forEach((addedCategory) => {
        if(addedCategory.name === potentialCategory.name){
          added = true;
        }
      })
      if(!added){
        categories.push(potentialCategory)
      }
    });
    this.isEditing = false;
    let currentMisc = this.props.allMisc[moment().format('YYYY-MM')]
    if (this.props.editedKey !== '') {
      let selectedCategoryId = ''
      let amount = 0
      allCategories.forEach((category) => {
        if(category.name === this.props.editedKey) {
          selectedCategoryId = category.value;
        }
      })
      for (let key in currentMisc) {
        if(key === this.props.editedKey) {
          amount = currentMisc[key].budget
        }
      }
      this.isEditing = true;
      this.state = {
        amount: amount,
        categories: allCategories,
        selectedCategoryId: selectedCategoryId,
        changed: false
      }
    } else {
      this.state = {
        amount:'',
        categories: categories,
        selectedCategoryId: '',
        changed: false
      }
    }
    this.validator = new FormValidator([
      {
        field: 'amount',
        method: 'isEmpty',
        validWhen: false,
        message: 'Amount is required.'
      },
      {
        field: 'amount',
        method: this.amountIsNotZero,
        validWhen: true,
        message: 'Amount cannot be zero.'
      },
      {
        field: 'selectedCategoryId',
        method: 'isEmpty',
        validWhen: false,
        message: 'A category is required.'
      }
    ])
    this.submitted = false
    this.state.sendingToServer = false
    this.state.serverError = ''
    this.state.validation = this.validator.valid()
  }
  amountIsNotZero = () => {
    return this.state.amount > 0;
  };
  handleInputChange = (e) =>  {
    this.setState({
      [e.target.name]: e.target.value,
      serverError: '',
      changed: true
    });
  };
  toggleRecurrentCheckbox = () => {
    this.setState({
      recurrent: !this.state.recurrent
    })
  };
  addWithMore = () => {
    if(this.validateForm() == true) {
      let category = this.findOptionByValue(this.state.categories, this.state.selectedCategoryId);
      this.props.addActivity(this.state.amount, category)
      this.submitted = false
      this.setState({
        amount:'',
        selectedCategoryId: '',
      })
      //TODO on success clear and add more
    }
  }
  addSingle = () => {
    if (this.validateForm() == true) {
      let category = this.findOptionByValue(this.state.categories, this.state.selectedCategoryId);
      this.props.addActivity(this.state.amount, category)
      this.props.closeModal()
      //TODO on success close
    }
  }
  findOptionByValue = (options, value) => {
    return options.find(obj => {return obj.value == value});
  }
  update = () => {
    if (this.validateForm() == true) {
      if(this.state.changed) {
        this.props.updateMiscAmount(this.state.amount)
        this.props.closeModal()
      } else {
        this.props.closeModal()
      }
      // this.callUpdateActivity()
      //TODO on success close
    }
  }
  delete = () => {
    this.props.deleteMiscCategory();
    this.props.closeModal();
  }
  validateForm = () => {
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;
    return validation.isValid;
  }
  showLoader = () => {
    this.setState({
      sendingToServer: true
    })
  }
  callAddActivity = () => {
    this.showLoader()
    //TODO send to server
  }
  callUpdateActivity = () => {
    this.showLoader()
    //TODO send to server
  }
  callDeleteActivity = () => {
    this.showLoader()
    //TODO send to server
  }
  render(){
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    let isEditing = this.isEditing
    return(
      <PopupBoxShortTop>
        <PopupTitle>{isEditing ? "Edit" : "Add"} Budget</PopupTitle>
        <PopupErrorContainer>
          <ErrorText errorText={validation.amount.message} />
          <ErrorText errorText={validation.selectedCategoryId.message} />
          <ErrorText errorText={this.state.serverError} />
        </PopupErrorContainer>
        <PrimaryAmountInput value={this.state.amount}
                      name="amount"
                      onChange={(e) =>this.handleInputChange(e)}
                      label="Amount"
                      fieldname="Amount"
                      haserror={validation.amount.isInvalid.toString()}
                      style={{marginBottom: '16px'}} />
        {this.state.categories.length || isEditing ?
          <CategoriesDropdown name="selectedCategoryId"
                              fieldname="Category"
                              haserror={validation.selectedCategoryId.isInvalid.toString()}
                              options={this.state.categories}
                              label="Category"
                              selectedOptionValue={this.state.selectedCategoryId}
                              onChange={(e) => this.handleInputChange(e)}
                              style={{marginBottom: '16px'}}
                              disabled={isEditing}/>
          :
          <PrimaryInput value={'All categories added'}
                              label="Category"
                              fieldname="Category"
                              haserror={validation.amount.isInvalid.toString()}
                              style={{marginBottom: '16px'}}
                              disabled
          />
        }
        {this.state.sendingToServer
          ? <ButtonLoader loading={this.state.sendingToServer} />
          : (isEditing
              ?<div>
                <PrimaryButton onClick={this.update}>
                  Save
                </PrimaryButton>
                <TextButton onClick={this.delete} errorStyle>
                  Delete
                </TextButton>
              </div>
              :<div>
                  {/*<PrimaryButton onClick={this.addWithMore}>*/}
                    {/*Save and add more*/}
                  {/*</PrimaryButton>*/}
                  <PrimaryButton onClick={this.state.categories.length ? this.addSingle : this.props.closeModal}
                                 style={{marginTop: "40px"}}
                                 >
                    {this.state.categories.length? 'Save' : 'Close'}
                  </PrimaryButton>
              </div>)
        }
      </PopupBoxShortTop>
    )
  }
}
BudgetMisc.propTypes = {
  editItemId: PropTypes.number,
  closeModal: PropTypes.func,
  addActivity: PropTypes.func,
  alreadyAddedCategories: PropTypes.array,
  editedKey: PropTypes.string,
  allMisc: PropTypes.object,
  updateMiscAmount: PropTypes.func,
  deleteMiscCategory: PropTypes.func
}
export default BudgetMisc;

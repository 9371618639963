const Images = {
  background1: require('./background1.jpg'),
  background2: require('./background2.jpg'),
  background3: require('./background3.jpg'),
  background4: require('./background4.jpg'),
  fullLogo: require('./logo.png'),
  grayLogo: require('./logo_gray.png'),
  loginBackground: require('./loginBackground.jpg'),
  demoBackground:require('./demoImage.jpg'),
  profileImage:require('./placeholder-profile-image.gif'),
};
export default Images;

import {AccountDetails} from '../constants/AccountDetails';

export const accountActions = {
  setProfile,
  setProfilePhotoUrl,
  setAccount,
  setTransactions,
  logout,
  setSubscription
};
function setSubscription (data) {
  return {type : AccountDetails.SET_SUBSCRIPTION, data};

}
function setProfile(profile){
  return {type : AccountDetails.SET_PROFILE, profile};
}
function setProfilePhotoUrl(url) {
  return {type : AccountDetails.SET_PROFILE_PHOTO_URL, url};
}
function setAccount(account){
  return {type : AccountDetails.SET_SELECTED_ACCOUNT, account};
}
function setTransactions(data) {
  return {type : AccountDetails.SET_SELECTED_TRANSACTIONS, data};
}
function logout() {
  return {type : AccountDetails.LOGOUT};
}

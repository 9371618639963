import React from 'react';
import styled from 'styled-components';
import PrimaryButton from '../components/PrimaryButton';
import PrimaryInput from '../components/PrimaryInput';
import PrimaryCheckbox from "../components/PrimaryCheckbox";
import ErrorText from '../components/ErrorText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons'
import fire from '../config/fire';
import PropTypes from 'prop-types';
import {ActionLink} from '../components/TextElements';
import firebase from 'firebase'
import ButtonLoader from  '../components/ButtonLoader';
import Images from '../images/Images';
import {FullLogo} from '../components/Logos';
import {Link} from 'react-router-dom';
import FormValidator from '../components/FormValidator'


const LoginContainer = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    background-position-x: initial;
  }
`;
const RightSide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-color: white;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const RegisterFormContainer = styled.div`
  width: 350px;
  padding: 20px 0;
`;
const ErrorsContainer = styled.div`
  margin-bottom: 20px;
`;
class Login extends React.Component {
  constructor(props){
    super(props);
    let  user = fire.auth().currentUser;
    if (user) {
      this.props.history.push('/dashboard')
    }
    this.validator = new FormValidator([
      {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Email is required.'
      },
      {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'Please enter a valid email address.'
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password is required.'
      },
      {
        field: 'password',
        method: 'isLength',
        args: [{min:6, max: undefined}],
        validWhen: true,
        message: 'Password must have at least 6 characters.'
      }
    ])
    this.submitted = false
    this.state = {
      email:'',
      password:'',
      remember: false,
      sendingToServer: false,
      serverError: '',
      validation: this.validator.valid(),
      emailInputActive: true,
      passwordInputActive: true
    }
  }
  handleChange = (e) =>  {
    this.setState({
      [e.target.name]: e.target.value,
      serverError: ''
    });
  };
  signInAttempt = () => {
    let _this = this;
    const validation = this.validator.validate(this.state)
    this.setState({ validation })
    this.submitted = true
    if (validation.isValid) {
      let persistenceLevel = firebase.auth.Auth.Persistence.SESSION;
      if(this.state.remember){
        persistenceLevel = firebase.auth.Auth.Persistence.LOCAL;
      }
      this.setState({
        sendingToServer: true
      });
      fire.auth().setPersistence(persistenceLevel).then(() => {
        _this.signIn();
      })
    }
  };
  signIn = () => {
    let _this = this;
    fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(function(){
      // console.log('response',response)
    }).catch(function(error) {
      // Handle Errors here.
      // var errorCode = error.code;
      // console.log('intra la error')
      let errorMessage = error.message;
      // console.log('error message', errorMessage)
      _this.setState({
        serverError: errorMessage,
        sendingToServer: false
      });
      // ...
    });
  };
  toggleRememberStatus = () => {
    this.setState({
      remember: !this.state.remember
    })
  };
  render(){
    let validation = this.submitted ? this.validator.validate(this.state) : this.state.validation
    return (
      <LoginContainer image={Images.loginBackground}>
        <Link to='/'>
        <FullLogo style={{marginBottom: '100px'}}/>
        </Link>
        <RightSide ref={(component) => {this.formContainerElement = component}}>
          <div>
            <RegisterFormContainer>
              <ErrorsContainer>
                <ErrorText errorText={validation.email.message} />
                <ErrorText errorText={validation.password.message} />
                <ErrorText errorText={this.state.serverError} />
              </ErrorsContainer>
              <form>
                <PrimaryInput value={this.state.email} name="email"
                              onChange={(e) =>this.handleChange(e)}
                              label="Login" type="email"
                              validationtype="email"
                              fieldname="Email"
                              haserror={validation.email.isInvalid.toString()}
                              autoComplete="username"
                />
                <PrimaryInput value={this.state.password} name="password"
                              onChange={(e) =>this.handleChange(e)}
                              label="Password" type="password"
                              validationtype="required|min6"
                              fieldname="Password"
                              haserror={validation.password.isInvalid.toString()}
                              autoComplete='current-password'
                />
              </form>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <PrimaryCheckbox value={this.state.remember} onClick={this.toggleRememberStatus} label="Remember me"/>
              </div>
              {this.state.sendingToServer ?
                <ButtonLoader loading={this.state.sendingToServer} style={{marginTop: "15px"}}/>
                :
                <PrimaryButton onClick={this.signInAttempt} style={{marginTop: "15px"}}>Sign In</PrimaryButton>
              }
              <div style={{marginTop:'25px'}}>
                <ActionLink to='/forgot-password'>Forgot password&nbsp;<FontAwesomeIcon icon={faGreaterThan} style={{fontSize:'9px'}}/></ActionLink>
              </div>
            </RegisterFormContainer>
          </div>
        </RightSide>
      </LoginContainer>
    );
  }
}
Login.propTypes = {
  props:PropTypes.object,
  actions:PropTypes.object,
  profile:PropTypes.object,
  history: PropTypes.object
};
export default Login;


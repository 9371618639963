const icons = {
    accountsReceivable: require('./accountsReceivable.svg'),
    auto: require('./auto.svg'),
    buildings: require('./buildings.svg'),
    checking: require('./checking.svg'),
    commercialFeed: require('./commercialFeed.svg'),
    crops: require('./crops.svg'),
    farmland: require('./farmland.svg'),
    farmSecurities: require('./farmSecurities.svg'),
    householdGoods: require('./householdGoods.svg'),
    inpaid: require('./inpaid.svg'),
    investmentGrowing: require('./investmentGrowing.svg'),
    machinery: require('./machinery.svg'),
    other: require('./other.svg'),
    realEstate: require('./realEstate.svg'),
    stocks: require('./stocks.svg'),
    rightArrow: require('./rightArrow.png')
};


export default icons;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {BoxTitle,BoxDescription,PriceElement,NormalText} from "./TextElements";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
const Container = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
  background:${props=>props.disabled? '#8080802b' : 'white'};
  border: ${props=>props.selected? '2px solid #66BB6A' : '2px solid transparent'};
  &:hover{
    cursor: pointer;
  }
  @media (max-width: 900px) {
    width: 45%;
    margin: 2.5%
  }
  @media (max-width: 600px) {
    width: 95%;
  }
`;
const Rectangle = styled.div`
  height: 4px;	
  width: 60px;	
  background-color: #F2F2F2;
  margin: 20px 0;
`;
const BoxButton = styled.button`
  background-color: ${props => props.selected ? props.theme.primaryColor : 'transparent'};
  border-radius: 2px;
  width: 100%;
  height: 40px;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${props => props.selected ? '#FFFFFF' : props.theme.primaryColor};	
  font-family: "NotoSans-Regular", serif;
  font-weight: 500;
  font-size: 12px;
  border:${props => props.selected ? 'none' : '1px solid #66BB6A'};
  &:hover{
    cursor: pointer;
  }
  &:focus{
    background-color: ${props => props.selected ? props.theme.primaryColor : 'transparent'};
  }
  &:active{
    border: none;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
class SelectPlanBox extends React.Component {
  render(){
    return (
      <Container onClick={this.props.disabled? null : this.props.onClick} selected={this.props.selected} disabled={this.props.disabled}>
        <BoxTitle>{this.props.title}</BoxTitle>
        <BoxDescription style={{paddingTop:'15px'}}>{this.props.description}</BoxDescription>
        <Rectangle />
        <div style={{display:'flex', alignItems: 'baseline', paddingBottom:'25px'}}>
          <PriceElement>${this.props.price}</PriceElement>
          <NormalText>/month</NormalText>
        </div>
        {this.props.disabled ?
          <NormalText>Coming soon</NormalText>
          :
          <BoxButton selected={this.props.selected} onClick={this.props.goToPayment}>Continue with free trial</BoxButton>
        }
        <div style={{width:'100%',paddingTop:'25px'}}>
        {this.props.benefits.map((item,i) =>
          <NormalText key={i} style={{textAlign:'left',padding:'7px 0'}}>
            <FontAwesomeIcon icon={faCheck} style={{fontSize:'8px'}}/>&nbsp;{item}
          </NormalText>
        )}
        </div>
      </Container>
    );
  }
}
SelectPlanBox.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  description: PropTypes.string,
  price: PropTypes.string,
  benefits: PropTypes.array,
  selected: PropTypes.bool,
  goToPayment: PropTypes.func,
  disabled: PropTypes.bool
};

export default SelectPlanBox;

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isLoggedIn } from "../utils/loggedIn";
// console.log('next route', nextRoute())
const AuthRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isLoggedIn() ? (<Component {...props} {...rest}/>) : (
      <Redirect to={{
        pathname: '/login',
        state: {from: props.location}
      }}/>
    )
    )}/>
);

AuthRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.func
};

export default AuthRoute;

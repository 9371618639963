import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router-dom';
import {connect} from "react-redux";
import fire from '../config/fire';
import LoadingScreen from "../components/LoadingScreen";
import Images from '../images/Images';
import {FullLogo} from '../components/Logos';
const MessageScreen = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  display: flex;
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    background-position-x: initial;
  }
`;
const MessageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MessageBackground = styled.div`
   background-color: white;
   padding: 20px;
   margin-top: -100px;
   border-radius: 5px;
`;
const MessageText = styled.div`
  font-family: 'NotoSans-Bold',serif ;
  font-size: 1.1rem;
`;
class AcceptAdvisorRequest extends React.Component {
  constructor(props){
    super(props);
    this.state={
      loading: true,
      message: '',
      matchingItems: [],
      searchedItem:''
    }
    this.users = fire.firestore().collection('users')
  }
  componentDidMount () {
    const { advisorId, clientId } = this.props.match.params
    let _this = this;
    this.users.doc(advisorId).get().then(function (doc) {
      if(doc.exists){
        let clientAdded = false;
        let clientInvitedOnce = false;
        let clients = doc.data().clients || [];
        clients.forEach((client) => {
          if(client.id === clientId){
            clientAdded = true;
          }
          if(client.id === clientId && client.status === 'accepted'){
            clientInvitedOnce = true
          }
        });
        if(clientInvitedOnce){
          _this.setState({
            message: "You already accepted this advisor's invitation",
            loading: false
          })
          return;
        }
        if(clientAdded){
          //pair client with advisor
          let newClients = clients;
          newClients.forEach((newClient) => {
            if(newClient.id === clientId){
              newClient.status = 'accepted'
            }
          });
          _this.checkIfClientExist(clientId, doc.data(), newClients)

        } else {
          _this.setState({
            message: 'This advisor did not send you an invitation',
            loading: false
          })
        }
      } else {
        _this.setState({
          message: 'Wrong link',
          loading: false
        })
      }
    });
  }
  pairAdvisorWithClient = (newClients, advisorData) => {
    let _this = this;
    let message = advisorData.firstName + ' ' + advisorData.lastName + ' is now your advisor';
    this.users.doc(advisorData.id).set({
      'clients': newClients,
    }, {merge: true}).then(() => {
      _this.setState({
        message: message,
        loading: false
      })
    }).catch(() => {
      // console.log('writing error')
    })
  };
  checkIfClientExist = (clientId, advisorData, newClients) =>{
    let _this = this;
    this.users.doc(clientId).get().then(function (doc) {
      if(doc.exists) {
        let advisors = doc.data().advisors || []
        _this.pairClientWithAdvisor(clientId, advisors, advisorData, newClients)
      } else {
        _this.setState({
          message: 'Wrong link',
          loading: false
        })
      }
    });
  };
  pairClientWithAdvisor = (clientId, clientAdvisors, advisorData, newClients) => {
    let _this = this;
    let newClientAdvisors = clientAdvisors;
    newClientAdvisors.push({
      firstName: advisorData.firstName,
      lastName: advisorData.lastName,
      id: advisorData.id,
      email: advisorData.email
    });
    this.users.doc(clientId).set({
      'advisors': newClientAdvisors,
    }, {merge: true}).then(() => {
      _this.pairAdvisorWithClient(newClients, advisorData)
    }).catch(() => {
      // console.log('writing error')
    })
  };
  render(){
    return (
      <div style={{height: "100%"}}>
        {this.state.loading ?
          <LoadingScreen/>
          :
          <MessageScreen image={Images.loginBackground}>
            <Link to='/'>
              <FullLogo style={{marginTop: '100px'}}/>
            </Link>
            <MessageContainer>
              <MessageBackground>
                <MessageText>{this.state.message}</MessageText>
              </MessageBackground>
            </MessageContainer>
          </MessageScreen>
        }
      </div>
    );
  }
}
AcceptAdvisorRequest.propTypes = {
  props:PropTypes.object,
  match: PropTypes.object
};
function mapStateToProps({account}){
  return {
    currentAccount : account.currentTransactions,
    selectedAccount : account.selectedAccount,
    currentLogo: account.currentLogo
  };
}

export default connect(mapStateToProps)(withRouter(AcceptAdvisorRequest));

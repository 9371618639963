import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Input} from 'react-materialize';

const Container = styled.div`
  label{
    color: ${props => props.theme.inputPlaceholder} !important;
    font-family: "NotoSans-Regular", serif !important;
    line-height: initial !important;
    height: initial !important;
  }
  .active{
    z-index: 5;
    color: ${props => props.haserror === 'false' ? props.theme.primaryColor : props.theme.errorColor} !important;
    margin-top: 0.4rem;
    background-color: white;
    padding: 0 4px;
  }
`;

const StyledInput = styled(Input)`
  input {
    margin: 0 !important;
    border: 0 !important;
    box-sizing: border-box;
    padding: ${props => props.isselected === 'true' ? '0 30px' : '0' } !important;
    color: ${props => props.isselected === 'true' ? 'black' : props.theme.inputPlaceholder};
    background: ${props => props.selectedicon ? 'url(data:image/jpeg;base64,' + props.selectedicon + ') no-repeat scroll' : ''};
    background-size: ${props => props.selectedicon ? '24px' : ''}
    background-position: ${props => props.selectedicon ? 'left center' : ''}
  }
  width: 100% !important;
  margin: 0 !important;
  padding: 0 .75rem !important;
  border: ${props => props.haserror === 'false' ? '1px solid ' + props.theme.inputBorder : '1px solid ' + props.theme.errorColor} !important;
  border-radius: 2px !important;
  color: black !important;
  font-family: "NotoSans-Regular", serif !important;
  font-size: 1rem !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  text-decoration: none !important;
  @media (max-width: 768px) {
    font-size: 16px !important;
  }
  &:focus{
    box-shadow: none !important;
    border-color: ${props => props.haserror === 'false' ? props.theme.primaryColor : props.theme.errorColor} !important;
  }
  span.caret {
    background-image: url(${props => props.caretimage});
    background-repeat: no-repeat;
    background-position: center;
    right: 5px !important;
    width: 30px !important;
    height: 20px !important;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  ul {
    z-index: 10 !important;
  }
  .dropdown-content li {
    display: flex;
    align-items: center;
  }
  .dropdown-content li.disabled {
    display: none;
  }
  .select-dropdown li img {
    height: 24px;
    width: 24px;
    margin: 8px 0px 8px 8px;
  }
  .select-dropdown li span {
    color: black;
  }
`;

class OptionalDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.dropdownRef = React.createRef();
    this.state = {
      options: this.props.options,
      isSelected: this.props.selectedOptionValue,
      selectedItem: this.props.selectedOptionValue ? this.findOptionByName(this.props.options, this.props.selectedOptionValue) : null,
      defaultValue: this.props.selectedOptionValue ? this.props.selectedOptionValue : "_none"
    };
  }
  componentDidMount() {
    $(this.containerRef).ready(() => {
      //fix flickering issue on Chrome 73+
      this.containerRef.current.addEventListener('click', e => e.stopPropagation());
    });
    $(this.dropdownRef).ready(function() {
      $('select').material_select();
    });
  }
  changeSelection = (e) => {
    var selectedItem = this.findOptionByName(this.state.options, e.target.value);
    this.setState({
      isSelected: true,
      selectedItem: selectedItem,
    })
    this.props.onChange(e);
  };
  findOptionByName = (options, name) => {
    return options.find(obj => {return obj.name == name});
  }
  render() {
    return (
      <Container ref={this.containerRef} haserror={this.props.haserror.toString()} style={this.props.style}>
        <StyledInput ref={this.dropdownRef}
                     name={this.props.name}
                     fieldname={this.props.fieldname}
                     haserror={this.props.haserror.toString()}
                     onChange={this.changeSelection}
                     caretimage={require('../images/dropdown.svg')}
                     label={this.state.isSelected ? this.props.label : ' '}
                     isselected={this.state.isSelected ? 'true' : 'false'}
                     selectedicon={this.state.selectedItem ? this.state.selectedItem.icon : null}
                     type="select"
                     defaultValue={this.state.defaultValue}>
          <option value="_none" disabled>{this.props.label}</option>
          {this.state.options.map((item, i) =>
            <option key={i} value={item.name} data-icon={`data:image/jpeg;base64,${item.icon}`}>{item.name}</option>
          )}
          <option key={this.state.options.length}>Other</option>
        </StyledInput>
      </Container>
    );
  }
}
OptionalDropdown.propTypes = {
  style: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  fieldname: PropTypes.string,
  haserror: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  selectedOptionValue: PropTypes.string
};
export default OptionalDropdown;

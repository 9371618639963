import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {formatAccountTotal, formatDecimalAmount} from '../utils/accountsFormatting';

const CardContainer = styled.a`
    text-align: left;
    border: none;
    padding: 18px;
    margin: 12px;
    width: 100%;
    height: 169px;	
    position: relative;
    cursor: pointer;
    border-radius: 2px;	
    background-color: #FFFFFF;
    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
    
    @media (min-width: 768px) {
        width: 325px;	
}
    ${({ isMultiple }) => isMultiple && `
        background-color: #fff;
        position: relative;

    &:before {
        box-shadow: 0px 0px 10px rgba(0,0,0,0.15);
    }

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 2px;	
        background-color: #fff;
    }

    &:before {
        left: 8px;
        top: 8px;
        z-index: -1;
    }`}
`;

const CardTitle = styled.h3`
    color: #454B54;	
    font-family: "NotoSans-Regular", serif;		
    font-size: 1rem;	
    font-weight: bold;
    margin: 0 0 6px 0;	
    line-height: 14px;
`;

const CardAccountType = styled.p`
    color: rgba(69,75,84,0.4);	
    font-family: "NotoSans-Regular", serif;	
    font-size: 1rem;	
    margin: 0 0 6px 0;
    line-height: 19px;
`;

const CardBankIcon = styled.img`
    width: 28px;
    height: 28px;
    position:absolute;
    top: 18px;
    right: 18px;
`;

const TotalAmount = styled.h3`
    color: #454B54;	
    font-family: "NotoSans-Bold", serif;	
    font-size: 1.4rem;	
    font-weight: bold;	
    margin: 0;
    position: absolute;
    bottom: 18px;
    letter-spacing: 0.5px;	
    line-height: 19px;

    .a-card-item-total-decimals {
        font-size: .9rem;
    }
`;


const AccountCard = props => {
    const { isMultiple, title, accountTypes, total, onClickSetState, logo, deleteAccount, isRetrievingData } = props;

    return (
        <CardContainer onClick={onClickSetState} isMultiple={isMultiple}>
            <CardTitle>{title}</CardTitle>
            {isRetrievingData &&
              <div>
                <CardTitle style={{marginTop: '20px', marginBottom: 0}}>We are retrieving your data</CardTitle>
                <CardTitle>It may take a few minutes to fetch transactions</CardTitle>
              </div>
            }
            {accountTypes.map((account, index, array) => {
                if (index < 2) {
                    return (
                           <CardAccountType key={index}>{account.name}</CardAccountType>
                    );
                }
                if (index+1 === array.length) {
                    return <CardAccountType key={index}>and {array.length - 2} more</CardAccountType>
                }
                else {
                    return;
                }
            })}
            {logo &&
            <CardBankIcon alt="Bank Icon" src={`data:image/jpeg;base64,${logo}`}/>
            }
            <TotalAmount>
              {logo ?
                <div>
                  <span>{formatAccountTotal(total)}</span><span
                  className="a-card-item-total-decimals">{formatDecimalAmount(total)}</span>
                </div>
                :
                <div onClick={deleteAccount} style={{color:'#D7463C', fontSize: '1rem'}}>Delete Account</div>
              }
            </TotalAmount>
        </CardContainer>
    );
};

AccountCard.propTypes = {
    isMultiple: PropTypes.bool,
    title: PropTypes.string,
    accountTypes: PropTypes.array,
    total: PropTypes.number,
    onClickSetState:  PropTypes.func,
    logo: PropTypes.string,
    deleteAccount: PropTypes.func,
    isRetrievingData: PropTypes.bool
};

export default AccountCard;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css'
import 'react-responsive-ui/style.css'
import PhoneInput from 'react-phone-number-input/react-responsive-ui'
import axios from 'axios'
const StyledInput = styled(PhoneInput)`
    width: 100% !important;
    border: ${props=>props.haserror === '' ? '1px solid rgba(69,75,84,0.2) ' : '1px solid #D7463C'} !important;	
    border-radius: 2px !important;  
    padding: 0 .75rem !important;
    input {
      color: black !important;
      font-family: "NotoSans-Regular", serif !important;
      font-size: 1rem !important;
      box-sizing: border-box !important;
      margin: 0 !important;
      box-shadow: none !important;
      border: none !important;
      @media (max-width: 768px) {
        font-size: 16px !important;
      }
    }
    &:focus-within {
      box-shadow: none !important;
      color: RED !important;
      border-color: ${props=>props.haserror === '' ? props.theme.primaryColor : props.theme.errorColor} !important;	
    }
    .react-phone-number-input__icon{
      border: none;
    }
    .rrui__select__arrow{
      color:${props=>props.theme.primaryColor};
    }
    .rrui__list__item--button:focus{
      background-color: #66BB6A !important;
    }
`;
class PhoneNumberInput extends React.Component {
  constructor(props){
    super(props)
    this.state={
      country: ''
    }
  }
  componentDidMount(){
    axios.get('https://api.ipdata.co?api-key=32d3ce291e52a27a6386509ed8f74ab962b62da9051a40332bf47bfe')
    .then((data) => {
      if(data.data) {
        this.setState({
          country: data.data.country_code
        })
      }
    })
  }
  render(){
    return (
        <StyledInput placeholder={this.props.placeholder}
           country={this.state.country}
           value={this.props.value}
           onChange={this.props.onChange}
           haserror={this.props.haserror}
        />
    );
  }
}
PhoneNumberInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  haserror: PropTypes.string
};

export default PhoneNumberInput;
